import get from 'lodash/get';
import { connect } from 'react-redux';
import { withGetTieredBonus } from 'src/_shared/api/components/tiered-bonuses';
import { compose } from '../../_shared/services/utils.js';
import MyReferralCardComponent from './MyReferralCardComponent.jsx';

const mapStateToProperties = (state, ownProperties) => {
	const { currentUser } = state.user;
	const { allMultiLingualData } = state.dashboard;
	const campaignId = get(ownProperties, 'referral.campaignId', null);
	const campaign = get(ownProperties, 'referral.campaign', null);
	let id = '';
	id = campaignId
		? get(campaign, 'tieredBonusId', 'skip')
		: get(ownProperties, 'referral.job.tieredBonusId', 'skip');

	return {
		allMultiLingualData,
		companyId: currentUser.companyId,
		tieredBonusQuery: {
			companyId: currentUser.companyId,
			id,
		},
		companyId: currentUser.companyId,
	};
};

const mapDispatchToProperties = () => {
	return {};
};

export const MyReferralCardWithApi = compose(withGetTieredBonus)(
	MyReferralCardComponent
);

export default connect(
	mapStateToProperties,
	mapDispatchToProperties
)(MyReferralCardWithApi);

import { Table, Tooltip } from 'antd';
import dayjs from 'dayjs';
import get from 'lodash/get';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { mapReferralStatus, ml } from 'src/_shared/services/utils.js';
import JobTitleComponent from '../../_shared/components/JobTitleComponent.jsx';
import { SocialMedia } from '../../_shared/components/social-media';
import { BonusValue } from '../../referrals-admin-dashboard/referrals-admin-dashboard-components';

const requestIdHover = (jobTitleInfo, externalJobId) => {
	externalJobId &&= 'Req Id: ' + externalJobId;
	return (
		<Tooltip placement="top" title={externalJobId} style={{ fontWeight: 1000 }}>
			{jobTitleInfo}
		</Tooltip>
	);
};

class ReferralsTable extends Component {
	getBonusAmount = (value) => {
		let parsedBonus;
		parsedBonus =
			typeof value?.job?.referralBonus === 'string'
				? JSON.parse(value.job.referralBonus)
				: value.job.referralBonus;
		if (value?.referralType === 'self') {
			return -2;
		}

		if (parsedBonus?.tieredBonusId) {
			return -1;
		}

		if (!parsedBonus?.amount) {
			return 0;
		}

		return parsedBonus?.amount;
	};

	renderStatus(record, referral) {
		const customStatus = get(referral, 'customStatus');
		const status = get(referral, 'status');
		if (status === 'hired') {
			return (
				<span className="text-green">
					{mapReferralStatus(status, get(this.props, 'currentUser.company'))}
				</span>
			);
		}

		if (status === 'notHired') {
			return (
				<span className="text-italic">
					{mapReferralStatus(status, get(this.props, 'currentUser.company'))}
				</span>
			);
		}

		if (status === 'referred') {
			return (
				<span>
					{mapReferralStatus(status, get(this.props, 'currentUser.company'))}
				</span>
			);
		}

		if (status === 'accepted') {
			return (
				<span>
					{mapReferralStatus(status, get(this.props, 'currentUser.company'))}
				</span>
			);
		}

		if (status === 'interviewing') {
			return customStatus ? (
				<span>
					{mapReferralStatus(
						customStatus,
						get(this.props, 'currentUser.company')
					)}
				</span>
			) : (
				<span>
					{mapReferralStatus(status, get(this.props, 'currentUser.company'))}
				</span>
			);
		}

		if (status === 'noresponse') {
			return (
				<span>
					{mapReferralStatus(status, get(this.props, 'currentUser.company'))}
				</span>
			);
		}

		if (status === 'inactive') {
			return (
				<span>
					{mapReferralStatus(status, get(this.props, 'currentUser.company'))}
				</span>
			);
		}

		if (status === 'ineligible') {
			return (
				<span>
					{mapReferralStatus(status, get(this.props, 'currentUser.company'))}
				</span>
			);
		}
	}

	render() {
		const {
			filteredData,
			sortByAlph,
			currentCurrencyRate,
			currentCurrencySymbol,
			currentUser,
			allMultiLingualData,
		} = this.props;

		let REFERRAL_STATUS = {
			referred: 'Referred',
			accepted: 'Accepted',
			interviewing: 'Interviewing',
		};
		const customStages = get(this.props.currentUser, 'company.stages');
		if (customStages) {
			const stages = JSON.parse(customStages);
			if (stages.length > 0) {
				REFERRAL_STATUS = Object.assign(REFERRAL_STATUS, ...stages);
			}
		}

		const remainingStatuses = {
			hired: 'Hired',
			ineligible: 'Ineligible',
			notHired: 'Not Hired',
			declined: 'Declined',
			transferred: 'Transferred',
			inactive: 'Inactive',
			noresponse: 'No Response',
		};
		REFERRAL_STATUS = { ...REFERRAL_STATUS, ...remainingStatuses };
		const columns = [
			{
				title: 'Name',
				dataIndex: 'contact',

				render(record, data) {
					let result = null;
					if (get(data, 'type') === 'gdprReferralCreated') {
						result = (
							<div className="table-status">
								{ml('Pending Acceptance', currentUser, allMultiLingualData)}
							</div>
						);
					} else {
						result = (
							<Link to={`/referrals/${data.id}`} className="table-link">
								{get(record, 'firstName')} {get(record, 'lastName')}
							</Link>
						);
					}

					return result;
				},
				showSorterTooltip: false,
				sorter: (a, b) =>
					sortByAlph(
						get(a, 'contact.firstName', '') + get(a, 'contact.lastName', ''),
						get(b, 'contact.firstName', '') + get(b, 'contact.lastName', '')
					),
			},
			{
				title: 'Info',
				render: (record) => (
					<SocialMedia
						email={get(record, 'contact.emailAddress', '')}
						socialMedia={get(record, 'contact.socialMediaAccounts', '')}
						contactResume={get(record, 'contact.contactResume', '')}
					/>
				),
			},
			{
				title: 'Job',
				dataIndex: 'job',
				key: () => Math.floor(Math.random() * 100_000 + 1),
				showSorterTooltip: false,
				sorter: (a, b) => sortByAlph(a.job.title, b.job.title),
				render: (job) =>
					job === null
						? null
						: requestIdHover(
								<Link className="table-link" to={`/jobs/${job.id}`}>
									<JobTitleComponent
										jobTitle={job.title}
										currentUser={get(this.props, 'currentUser')}
									/>
								</Link>,
								job.externalJobId
							),
			},
			{
				title: `Date ${mapReferralStatus(
					'referred',
					get(this.props, 'currentUser.company')
				)}`,
				dataIndex: 'referralDate',
				showSorterTooltip: false,
				sorter: (a, b) => dayjs(b.referralDate) - dayjs(a.referralDate),
				defaultSortOrder: 'ascend',
				render: (referralDate) =>
					referralDate === null ? null : dayjs(referralDate).format('M/D/YYYY'),
			},
			{
				title: 'Potential Bonus',
				dataIndex: 'job',
				showSorterTooltip: false,
				sorter: (a, b) => this.getBonusAmount(a) - this.getBonusAmount(b),
				render: (job, referral) => {
					if (!job) return null;
					let parsedReferralBonus = null;
					let hasBonusTier = null;
					let hasBonusAmount = null;

					if (job?.referralBonus && job?.referralBonus !== 'undefined') {
						try {
							parsedReferralBonus = JSON.parse(job.referralBonus);
							hasBonusTier = parsedReferralBonus?.tieredBonusId ?? null;
							hasBonusAmount = parsedReferralBonus?.amount ?? null;
						} catch (error) {
							console.error('Error parsing JSON', error);
						}
					}

					const hasBonus = hasBonusTier || hasBonusAmount;
					const bonus =
						get(referral, 'status') === 'hired' && hasBonus ? (
							<Link
								className="table-link"
								to={{
									pathname: '/bonuses',
									state: {
										targetReferral: get(referral, 'id'),
									},
								}}
							>
								<BonusValue
									overrideSelfReferrals
									referral={referral}
									contactIncentiveBonus={
										this.props.currentUser.company.contactIncentiveBonus
									}
									currentCurrencyRate={currentCurrencyRate}
									currentCurrencySymbol={currentCurrencySymbol}
								/>
							</Link>
						) : (
							<BonusValue
								overrideSelfReferrals
								referral={referral}
								contactIncentiveBonus={
									this.props.currentUser.company.contactIncentiveBonus
								}
								currentCurrencyRate={currentCurrencyRate}
								currentCurrencySymbol={currentCurrencySymbol}
								companyId={get(job, 'companyId')}
							/>
						);
					return bonus;
				},
			},
			{
				title: 'Status',
				dataIndex: 'referrals',
				showSorterTooltip: false,
				sorter: (a, b) => sortByAlph(a.status, b.status),
				render: (record, referral) => this.renderStatus(record, referral),
			},
		];

		return (
			<Table
				rowKey={(record) => record.id}
				dataSource={filteredData}
				columns={columns}
				scroll={{ x: 656 }}
			/>
		);
	}
}

export default ReferralsTable;

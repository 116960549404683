import { useState } from 'react';
import store from 'src/state.js';
import {
	downloadFromS3Signed,
	searchOpenSearchNetwork,
} from 'src/_shared/services/utils.js';
import { Avatar, Popover, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

let timeout;
let currentValue;

const seperateBySubCompany = (employees) => {
	const subCompanyMap = {};
	const noSubCompany = [];

	for (const employee of employees) {
		if (employee.subCompany && employee.subCompany.id) {
			const subCompanyId = employee.subCompany.id;
			subCompanyMap[subCompanyId] ||= [];
			subCompanyMap[subCompanyId].push(employee);
		} else {
			noSubCompany.push(employee);
		}
	}

	return { subCompanyMap, noSubCompany };
};

const getFullName = (employee) => {
	const firstName = employee?.firstName
		? (employee.firstName[0].toUpperCase() + employee.firstName.slice(1)).trim()
		: '';
	const lastName = employee?.lastName
		? (employee.lastName[0].toUpperCase() + employee.lastName.slice(1)).trim()
		: '';
	return `${firstName} ${lastName}`.trim();
};

const getInitials = (employee) => {
	const firstNameInitial = employee?.firstName
		? employee.firstName.charAt(0).toUpperCase()
		: '';
	const lastNameInitial = employee?.lastName
		? employee.lastName.charAt(0).toUpperCase()
		: '';
	return `${firstNameInitial}${lastNameInitial}`;
};

const getTitle = (employee) => {
	return employee?.title
		? employee.title[0].toUpperCase() + employee.title.slice(1)
		: '';
};

const renderEmployee = (employee) => {
	const fullName = getFullName(employee);
	const initials = getInitials(employee);
	const title = getTitle(employee);
	return (
		<>
			{employee?.avatar ? (
				<Popover content={<Avatar size={72} src={employee.avatar} />}>
					<Avatar size="small" src={employee.avatar} />
				</Popover>
			) : (
				<Avatar size="small">{initials}</Avatar>
			)}
			<span className="employee-option-text-rr">
				{fullName +
					(employee?.emailAddress ? ' - ' + employee?.emailAddress : '') +
					(employee?.title ? ' - ' + title : '')}
			</span>
		</>
	);
};

const createEmployeeOptionsArray = (subCompanyMap, noSubCompany) => {
	const employeeOptions = [];

	if (Object.keys(subCompanyMap).length === 0) {
		if (noSubCompany?.length > 0) {
			for (const employee of noSubCompany) {
				employeeOptions.push({
					className: 'employee-option-rr',
					label: renderEmployee(employee),
					value: employee.id,
				});
			}
		}

		return employeeOptions;
	}

	for (const subCompanyId of Object.keys(subCompanyMap)) {
		if (subCompanyMap[subCompanyId].length > 0) {
			employeeOptions.push({
				label: (
					<span className="employee-group-label-rr">
						{subCompanyMap[subCompanyId][0].subCompany?.name || subCompanyId}
					</span>
				),
				options: subCompanyMap[subCompanyId].map((employee) => ({
					className: 'employee-option-rr',
					label: renderEmployee(employee),
					value: employee.id,
				})),
			});
		}
	}

	if (noSubCompany?.length > 0) {
		employeeOptions.push({
			label: <span className="employee-group-label-rr">Other</span>,
			options: noSubCompany.map((employee) => ({
				className: 'employee-option-rr',
				label: renderEmployee(employee),
				value: employee.id,
			})),
		});
	}

	return employeeOptions;
};

const onSearch = async (
	value,
	callback,
	currentUser,
	setSearchedEmployeeData,
	setIsLoading
) => {
	if (timeout) {
		clearTimeout(timeout);
		timeout = null;
	}

	currentValue = value;
	const searchWithTimeout = async () => {
		const parameters = {
			fields: [
				'first_name^10',
				'last_name^10',
				'email_address^10',
				'title^2',
				'location_text^4',
			],
			query: value,
			size: 100,
			role: currentUser?.role,
			currentUser: currentUser?.id,
			filters: {
				active: 'true',
				companies: currentUser?.companyId,
				departments: [],
				fieldsToReturn: [
					'id',
					'avatar',
					'emailAddress',
					'firstName',
					'lastName',
					'subCompany',
					'title',
					'metaScore',
				],
				roles: [],
				userGroups: [],
			},
		};
		const response = await searchOpenSearchNetwork(parameters, 'erin-users');
		if (currentValue === value) {
			const searchedEmployeeResults = await Promise.all(
				response.data.map(async (record) => {
					record.avatar &&= await downloadFromS3Signed(record.avatar.key);
					return record;
				})
			);
			setSearchedEmployeeData(searchedEmployeeResults);
			const { subCompanyMap, noSubCompany } = seperateBySubCompany(
				searchedEmployeeResults
			);
			const employeeOptions = createEmployeeOptionsArray(
				subCompanyMap,
				noSubCompany
			);
			setIsLoading(false);
			callback(employeeOptions);
		}
	};

	timeout = setTimeout(searchWithTimeout, 300);
};

const EmployeePicker = ({
	setSearchedEmployeeData,
	selectedEmployee,
	setSelectedEmployee,
	placeholder = 'Search',
	style = { height: 34, width: 150 },
	dropdownStyle = { width: 'auto' },
	notFoundContent = <>Try searching by Name, Job Title, or Location</>,
}) => {
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const state = store.getState();
	const currentUser = state?.user?.currentUser;

	const handleSearch = async (newValue) => {
		if (!currentUser?.role || !currentUser?.id || !currentUser?.companyId)
			setData([]);
		if (newValue) {
			setIsLoading(true);
			await onSearch(
				newValue,
				setData,
				currentUser,
				setSearchedEmployeeData,
				setIsLoading
			);
		} else {
			setData([]);
		}
	};

	const handleChange = (newValue) => {
		setSelectedEmployee(newValue);
	};

	return (
		<Select
			showSearch
			value={selectedEmployee}
			placeholder={placeholder}
			style={{ ...style, height: 34 }}
			loading={isLoading}
			suffixIcon={
				<LoadingOutlined style={isLoading ? {} : { display: 'none' }} />
			}
			defaultActiveFirstOption={false}
			dropdownMatchSelectWidth={false}
			dropdownStyle={dropdownStyle}
			filterOption={false}
			notFoundContent={notFoundContent}
			spellCheck={false}
			options={data}
			onSearch={handleSearch}
			onChange={handleChange}
		/>
	);
};

export default EmployeePicker;

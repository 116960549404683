import { useState, useMemo, useEffect } from 'react';
import { AutoComplete, Button, Input, Spin, Form } from 'antd';
import { placesAutocomplete } from 'src/_shared/services/utils';

function debounce(func, delay) {
	let timeoutId;
	return (...args) => {
		if (timeoutId) {
			clearTimeout(timeoutId);
		}
		timeoutId = setTimeout(() => {
			func(...args);
		}, delay);
	};
}

const ManageAddressPresentationModal = ({
	onSubmit,
	employeeAddress,
	addressLoader,
	manageAddressLoader,
}) => {
	const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
	const [form] = Form.useForm();

	const handleSearch = useMemo(
		() =>
			debounce(async (value) => {
				if (value) {
					const suggestions = await placesAutocomplete(value);
					if (suggestions && suggestions.Results) {
						setAutoCompleteOptions(
							suggestions.Results.map((suggestion) => ({
								value: suggestion.Text,
								label: suggestion.Text,
							}))
						);
					}
				} else {
					setAutoCompleteOptions([]);
				}
			}, 300),
		[]
	);

	useEffect(() => {
		if (employeeAddress) {
			form.setFieldsValue(employeeAddress);
		}
	}, [addressLoader]);

	const handleSelect = async (_value, option) => {
		const addressParts = option.label.split(',').map((part) => part.trim());
		const [
			parsedAddress,
			parsedCity,
			parsedState,
			parsedZip,
			parsedCountry,
		] = addressParts;

		form.setFieldsValue({
			addressLine1: parsedAddress || '',
			city: parsedCity || '',
			state: parsedState || '',
			country: parsedZip || '',
			pinCode: parsedCountry || '',
		});
	};

	const onFinish = (values) => {
		if (values.pinCode) {
			values.pinCode = parseInt(values.pinCode);
		}
		onSubmit(values);
	};

	return (
		<Form
			form={form}
			size={'large'}
			layout="horizontal"
			onFinish={onFinish}
			requiredMark={false}
		>
			{manageAddressLoader || addressLoader ? (
				<div className="d-flex align-items-center justify-content-center w-100 h-100 edit-modal-loader">
					<Spin />
				</div>
			) : null}

			{/* Address Line 1 */}
			<div>
				<Form.Item
					style={{ marginTop: 12, marginBottom: 12 }}
					name="addressLine1"
					labelCol={{ span: 6, offset: 2 }}
					wrapperCol={{ span: 12 }}
					label="Address Line 1"
					rules={[
						{
							required: true,
							message: 'Please enter your address',
						},
					]}
				>
					<AutoComplete
						children={
							<Input
								style={{ lineHeight: '24px', borderRadius: 8 }}
							/>
						}
						options={autoCompleteOptions}
						onSearch={handleSearch}
						onSelect={handleSelect}
						placeholder={
							<span style={{ lineHeight: '40px' }}>
								Enter your address
							</span>
						}
					/>
				</Form.Item>
			</div>

			{/* Address Line 2 */}
			<div>
				<Form.Item
					style={{ marginBottom: 12 }}
					name="addressLine2"
					labelCol={{ span: 6, offset: 2 }}
					wrapperCol={{ span: 12 }}
					label="Address Line 2"
					rules={[{ required: false }]}
				>
					<Input
						style={{ fontSize: 16, borderRadius: 8 }}
						placeholder="Optional"
					/>
				</Form.Item>
			</div>

			{/* City */}
			<div>
				<div>
					<Form.Item
						style={{ marginBottom: 12 }}
						labelCol={{ span: 6, offset: 2 }}
						wrapperCol={{ span: 8 }}
						name="city"
						label="City"
						rules={[
							{
								required: true,
								message: 'Please enter your city',
							},
						]}
					>
						<Input style={{ fontSize: 16, borderRadius: 8 }} />
					</Form.Item>
				</div>
			</div>

			{/* State */}
			<div>
				<div>
					<Form.Item
						style={{ marginBottom: 12 }}
						labelCol={{ span: 6, offset: 2 }}
						wrapperCol={{ span: 3 }}
						name="state"
						label="State"
						rules={[
							{
								required: true,
								message: 'Please enter your state',
							},
						]}
					>
						<Input style={{ fontSize: 16, borderRadius: 8 }} />
					</Form.Item>
				</div>
			</div>

			{/* Zip Code */}
			<div>
				<div>
					<Form.Item
						style={{ marginBottom: 12 }}
						labelCol={{ span: 6, offset: 2 }}
						wrapperCol={{ span: 4 }}
						name="pinCode"
						label="Zip Code"
						rules={[
							{
								required: true,
								message: 'Please enter your zip code',
							},
						]}
					>
						<Input style={{ fontSize: 16, borderRadius: 8 }} />
					</Form.Item>
				</div>
			</div>

			{/* Country */}
			<div>
				<div>
					<Form.Item
						style={{ marginBottom: 12 }}
						labelCol={{ span: 6, offset: 2 }}
						wrapperCol={{ span: 6 }}
						name="country"
						label="Country"
						rules={[
							{
								required: true,
								message: 'Please enter your country',
							},
						]}
					>
						<Input style={{ fontSize: 16, borderRadius: 8 }} />
					</Form.Item>
				</div>
			</div>

			{/* Landmark */}
			<div>
				<div>
					<Form.Item
						style={{ marginBottom: 12 }}
						labelCol={{ span: 6, offset: 2 }}
						wrapperCol={{ span: 12 }}
						name="landmark"
						label="Landmark"
						rules={[{ required: false }]}
					>
						<Input
							style={{ fontSize: 16, borderRadius: 8 }}
							placeholder="Optional"
						/>
					</Form.Item>
				</div>
			</div>

			{/* Submit */}
			<div className="row submit-button">
				<Form.Item>
					<Button
						style={{ fontSize: 16, fontWeight: 900 }}
						type="primary"
						htmlType="submit"
					>
						Submit Address
					</Button>
				</Form.Item>
			</div>
		</Form>
	);
};

export default ManageAddressPresentationModal;

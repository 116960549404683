import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
	DeleteOutlined,
	InfoCircleOutlined,
	LoadingOutlined,
	PlusOutlined,
	QuestionCircleOutlined,
} from '@ant-design/icons';
import { Editor } from '@tinymce/tinymce-react';
import {
	Alert,
	AutoComplete,
	Button,
	Checkbox,
	Collapse,
	Input,
	InputNumber,
	Popconfirm,
	Radio,
	Select,
	Table,
	Tooltip,
	Upload,
	message,
} from 'antd';
import dompurify from 'dompurify';
import _, { get } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { Component, createRef } from 'react';
import RichTextEditor from 'react-rte';
import { configMode } from 'src/_shared/api/';
import { colorRegex } from '../_shared/constants/regex';
import { CountrySelect } from '../_shared/index.js';
import {
	capitalizeFirstLetter,
	downloadFromS3Signed,
	ml,
	parse,
	uploadToS3Multipart,
} from '../_shared/services/utils.js';
import { COLORS } from '../_shared/styles/colors';
import { PageItemSetting } from '../manageJobs/addJobModal/pages';
import { notificationTypes } from '../manageJobs/addJobModal/pages/copy';
import { ListItems } from './branding';
import { CustomPickerList } from './custom-picker-items';
import { DepartmentItem } from './department-items';
import {
	FormItemContainer,
	FormStyles,
	filterGroupSpace,
	greenLabel,
	groupRow,
	inputContainerSetting,
	regularButton,
} from './settingsStyles.js';
import { UserGroupItem } from './userGroup-items';

const sanitizer = dompurify.sanitize;

function beforeUpload(file) {
	const correctType = file.type === 'image/png' || file.type === 'image/jpeg';
	if (!correctType) {
		message.error('You can only upload png or jpeg files!');
	}

	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error('Image must smaller than 2MB!');
	}

	return correctType && isLt2M;
}

function beforeUploadFavicon(file) {
	const correctType =
		file.type === 'image/png' ||
		file.type === 'image/jpeg' ||
		file.type === 'image/vnd.microsoft.icon';
	if (!correctType) {
		message.error('You can only upload ico, png or jpeg files!');
	}

	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error('Image must smaller than 2MB!');
	}

	return correctType && isLt2M;
}

class SettingsForm extends Component {
	constructor(props) {
		super(props);
		let theme = get(props, 'company.theme');
		const automationSettings = parse(get(props, 'company.automationSettings'));
		let emailSettings = parse(get(props, 'company.emailSettings'));
		emailSettings = emailSettings ? emailSettings : { onboarding: {} };
		const allowUserLoginWhenClaimInactive = get(
			automationSettings,
			'allowUserLoginWhenClaimInactive',
			false
		);
		if (typeof theme === 'string') {
			theme = JSON.parse(theme);
		} else {
			theme = {
				enabled: false,
				menuColor: '#242e3f',
				menuHighlightColor: '#ef3c3f',
				buttonColor: '#038dd3',
				addButtonColor: '#ef3c3f',
				jobCardTitleColor: '#038dd3',
			};
		}

		this.countryCodeRef = createRef();
		this.state = {
			automationSettings,
			colorError: false,
			emailSettings,
			themeError: {
				menuColor: false,
				menuHighlightColor: false,
				buttonColor: false,
				addButtonColor: false,
				jobCardTitleColor: false,
			},
			brandColor: this.props.company.brandColor,
			file: undefined,
			symbolFile: undefined,
			backgroundFile: undefined,
			errorImageFile: undefined,
			currentSymbol: get(props, 'company.symbol'),
			currentBackground: get(props, 'company.background'),
			currentErrorImage: get(props, 'company.errorImage'),
			currentSocial: get(props, 'company.socialImage'),
			allowUserLoginWhenClaimInactive,
			sendAdminNotificationsOnReferral: get(
				props,
				'company.sendAdminNotificationsOnReferral',
				false
			),
			sendAdminNotificationsOnInternalCandidatesFound: get(
				props,
				'company.sendAdminNotificationsOnInternalCandidatesFound',
				false
			),
			daysLookupData: ['0', '30', '60', '90', '120'],
			daysLookupChildren: [],

			referralBonusWaitingPeriod: props.company.referralBonusWaitingPeriod
				? props.company.referralBonusWaitingPeriod.toString()
				: '90',
			dashboardReferralPolicyText: props.company.dashboardReferralPolicyText
				? RichTextEditor.createValueFromString(
						props.company.dashboardReferralPolicyText,
						'html'
					)
				: RichTextEditor.createEmptyValue(),
			bonusEarnedNote: get(props, 'company.bonusEarnedNote', ' ')
				? props.company.bonusEarnedNote
				: ' ',
			allowSelfReferrals: get(props, 'company.allowSelfReferrals'),
			allowInternalMobility: get(props, 'company.allowInternalMobility'),
			disableSmartReferrals: get(props, 'company.disableSmartReferrals'),
			enableRecognition: get(props, 'company.enableRecognition'),
			enableExtendedNetwork: get(props, 'company.enableExtendedNetwork'),
			enableJobMatching: get(props, 'company.enableJobMatching'),
			enableCareerProfile: get(props, 'company.enableCareerProfile'),
			disableExtendedUserJobsView: get(
				props,
				'company.disableExtendedUserJobsView'
			),
			disableClaimYourAccountLogin: get(
				props,
				'company.disableClaimYourAccountLogin'
			),
			disableSAMLLogin: get(props, 'company.disableSAMLLogin'),
			confirmCompliance: get(props, 'company.confirmCompliance', false),
			theme,
			whiteLabel: get(props, 'company.whiteLabel', false),
			enableGeneralReferrals: get(props, 'company.enableGeneralReferrals'),
			enableCandidateBonus: get(props, 'company.enableCandidateBonus', false),
			enableRetroactiveBonuses: get(
				props,
				'company.enableRetroactiveBonuses',
				false
			),
			requireBonusChangeReason: get(
				props,
				'company.requireBonusChangeReason',
				false
			),
			disableManagerPermissions: get(
				props,
				'company.disableManagerPermissions',
				false
			),
			enableProspectCreation: get(
				props,
				'company.enableProspectCreation',
				false
			),
			hideJobsPage: get(props, 'company.hideJobsPage', false),
			enableDashboardSearch:
				!get(props, 'company.hideJobsPage', false) &&
				get(props, 'company.enableDashboardSearch', false),
			disableShareLink: get(props, 'company.disableShareLink', false),
			hideShareLinkNoPublicUrl: get(
				props,
				'company.hideShareLinkNoPublicUrl',
				false
			),
			allowSelfReferralsInternalLink: get(
				props,
				'company.allowSelfReferralsInternalLink'
			),
			enableAcceptedReferralReminder: get(
				props,
				'company.enableAcceptedReferralReminder',
				false
			),
			externalUserSignUp: get(props, 'company.externalUserSignUp', false),
			enableAppReview: get(props, 'company.enableAppReview', false),
			hideLoginForm: get(props, 'company.hideLoginForm', false),
			disableReferrals: get(props, 'company.disableReferrals', false),
			senderEmailAddress: get(props, 'company.senderEmailAddress'),
			whiteLabelServiceName: get(props, 'company.whiteLabelServiceName'),
			hideBonus: get(props, 'company.hideBonus', false),
			includeAdminInRanking: get(props, 'company.includeAdminInRanking', false),
			disableSite: get(props, 'company.disableSite', false),
			filteredUserGroups:
				get(props, 'company.hideInterestedForGroup') !== null &&
				get(props, 'company.hideInterestedForGroup') !== undefined
					? JSON.parse(get(props, 'company.hideInterestedForGroup'))
					: [],
			hideInterestedForGroup: get(props, 'company.hideInterestedForGroup', []),
			filteredDepartments:
				get(props, 'company.hideShareLinkForDepartment') !== null &&
				get(props, 'company.hideShareLinkForDepartment') !== undefined
					? JSON.parse(get(props, 'company.hideShareLinkForDepartment'))
					: [],
			hideShareLinkForDepartment: get(
				props,
				'company.hideShareLinkForDepartment',
				[]
			),
			defaultLocation:
				get(props, 'company.defaultLocation') !== null &&
				get(props, 'company.defaultLocation') !== undefined
					? get(props, 'company.defaultLocation')
					: '0',
			labelEmployeeID: get(props, 'company.labelEmployeeID'),
			senderAdmins: get(props, 'company.selectedAdmins')
				? JSON.parse(get(props, 'company.selectedAdmins'))
				: [],
			selectedReferredReportTo: get(props, 'company.referredReportTo')
				? JSON.parse(get(props, 'company.referredReportTo'))
				: [],
			publicLeaderboard: get(props, 'company.publicLeaderboard'),
			hideReferralsRanking: get(props, 'company.hideReferralsRanking'),
			enableCustomPage: get(props, 'company.enableCustomPage'),
			enableCustomPage2: get(props, 'company.enableCustomPage2'),
			enableCustomPage3: get(props, 'company.enableCustomPage3'),
			hideWhatsApp: get(props, 'company.hideWhatsApp'),
			resetPopup: get(props, 'company.resetPopup'),
			hideRecruiterInfo: get(props, 'company.hideRecruiterInfo'),
			hideMessageRecruiter: get(props, 'company.hideMessageRecruiter'),
			disableNewReferralSMSNotification: get(
				props,
				'company.disableNewReferralSMSNotification'
			),
			hideRecruiterInfoJobDetails: get(
				props,
				'company.hideRecruiterInfoJobDetails'
			),
			ignoreSubcompany: get(props, 'company.ignoreSubcompany'),
			enableGenericReferralEmail: get(
				props,
				'company.enableGenericReferralEmail'
			),
			enableLead: get(props, 'company.enableLead'),
			hideSubcompanyOnSignup: get(props, 'company.hideSubcompanyOnSignup'),
			hideSubcompanyOnJobs: get(props, 'company.hideSubcompanyOnJobs', false),
			companyFavicon: get(props, 'company.favicon'),
			companyFaviconChanged: false,
			popupTitle: props.company.popupTitle,
			popupTitleContent: props.company.popupTitleContent
				? RichTextEditor.createValueFromString(
						props.company.popupTitleContent,
						'html'
					)
				: RichTextEditor.createEmptyValue(),
			hideDateCreatedJobDetails:
				get(props, 'company.hideDateCreatedJobDetails') || false,
			defaultToSubcompany: get(props, 'company.defaultToSubcompany'),
			showHTML: true,
			externalReferralNetworkShare: get(
				props,
				'company.enableReferralNetworkShare'
			)
				? get(props, 'company.enableReferralNetworkShare')
				: false,
			shareEmail: get(props, 'company.referralNetworkShareOptions')
				? JSON.parse(get(props, 'company.referralNetworkShareOptions')).email
				: false,
			shareQRCode: get(props, 'company.referralNetworkShareOptions')
				? JSON.parse(get(props, 'company.referralNetworkShareOptions')).qrCode
				: false,
			showPoweredByErin: get(props, 'company.showPoweredByErin', false),
			whiteLabelSettings: {
				hideLoginScreenShading:
					props && props.company && props.company.whiteLabelSettings
						? JSON.parse(props.company.whiteLabelSettings)
								.hideLoginScreenShading
						: false,
			},
			userEditSettings: get(props, 'company.userEditSettings')
				? JSON.parse(get(props, 'company.userEditSettings'))
				: {
						lockProfileFields: {
							employeeName: false,
							employeeDepartment: false,
							employeeJobTitle: false,
							employeeLocation: false,
						},
					},
			disableEmployeeEditableFields: false,
			// Backwards compatibility for disableManagerPermissions to set the state of disableManagerPermissionsByType
			disableManagerPermissionsByType: get(
				props,
				'company.disableManagerPermissionsByType'
			)
				? JSON.parse(get(props, 'company.disableManagerPermissionsByType'))
				: {
						managerReferrals: this.props.company?.disableManagerPermissions
							? 'readOnly'
							: 'edit',
						managerJobs: this.props.company?.disableManagerPermissions
							? 'readOnly'
							: 'edit',
						managerBonuses: 'hidden',
						managerEmployees: 'hidden',
					},
		};
	}

	async componentDidMount() {
		const anyFieldLocked = [
			'employeeName',
			'employeeDepartment',
			'employeeJobTitle',
			'employeeLocation',
		].some((field) => this.state.userEditSettings.lockProfileFields[field]);
		if (anyFieldLocked) {
			this.setState({
				disableEmployeeEditableFields: true,
			});
		}

		let faviconSource;
		let socialSource;
		let spinnerSource;
		let backgroundSource;
		let errorImageSource;
		const {
			companyFavicon,
			currentSocial,
			currentSymbol,
			currentBackground,
			currentErrorImage,
		} = this.state;
		if (companyFavicon) {
			faviconSource = await downloadFromS3Signed(
				companyFavicon.key,
				'erin-images'
			);
		}

		if (currentSocial) {
			socialSource = await downloadFromS3Signed(
				currentSocial.key,
				'erin-images'
			);
		}

		if (currentSymbol) {
			spinnerSource = await downloadFromS3Signed(
				currentSymbol.key,
				'erin-images'
			);
		}

		if (currentBackground) {
			backgroundSource = await downloadFromS3Signed(
				currentBackground.key,
				'erin-images'
			);
		}

		if (currentErrorImage) {
			errorImageSource = await downloadFromS3Signed(
				currentErrorImage.key,
				'erin-images'
			);
		}

		this.setState({
			faviconSrc: faviconSource,
			socialSrc: socialSource,
			spinnerSrc: spinnerSource,
			backgroundSrc: backgroundSource,
			errorImageSrc: errorImageSource,
		});
	}

	onChange = (e) => {
		if (e.target.value === '10') {
			this.setState({
				dashboardReferralPolicyText: this.props.company
					.dashboardReferralPolicyText
					? RichTextEditor.createValueFromString(
							this.props.company.dashboardReferralPolicyText,
							'html'
						)
					: RichTextEditor.createEmptyValue(),
				showHTML: true,
			});
		} else if (e.target.value === '20') {
			this.setState({
				showHTML: false,
			});
		}
	};

	onChangeHiringManager = (value) => {
		this.setState({
			hiringManager: {
				id: value.key,
				name: value.label.join(''),
			},
		});
	};

	onChangeNotificationType = (notificationType) => {
		this.setState({
			notificationType,
		});
	};

	onChangePopupRTE = (value) => {
		this.setState({
			popupTitleContent: value,
			popupDescription: value.toString('html'),
		});
		if (this.state.popupDescription === '<p><br></p>') {
			this.setState({ popupDescription: null });
		}
	};

	onChangeRTE = (value) => {
		this.setState({
			dashboardReferralPolicyText: value,
			description: value.toString('html'),
		});
		if (this.state.description === '<p><br></p>') {
			this.setState({ description: null });
		}
	};

	setTheme = (value, key) => {
		const { theme } = this.state;
		switch (key) {
			case 'enabled': {
				theme.enabled = value;
				break;
			}

			case 'menuColor': {
				theme.menuColor = value;
				break;
			}

			case 'menuHighlightColor': {
				theme.menuHighlightColor = value;
				break;
			}

			case 'buttonColor': {
				theme.buttonColor = value;
				break;
			}

			case 'addButtonColor': {
				theme.addButtonColor = value;
				break;
			}

			case 'jobCardTitleColor': {
				theme.jobCardTitleColor = value;
				break;
			}
		}

		this.setState({ theme });
	};

	addFollowUpEmail = () => {
		const { company, onUpdateCompany } = this.props;
		const { onboardingReminderDays } = this.state;
		if (!onboardingReminderDays || onboardingReminderDays <= 0) return;
		const emailSettings = get(this.state, 'emailSettings');
		let inviteEmails = get(emailSettings, 'onboarding.inviteEmails');
		inviteEmails.push({
			id: 0,
			days: onboardingReminderDays,
		});
		inviteEmails = _.sortBy(inviteEmails, ['days']);
		inviteEmails = inviteEmails.map((email, index) => {
			email.id = index;
			return email;
		});
		emailSettings.onboarding.inviteEmails = inviteEmails;
		this.setState({ emailSettings });
		onUpdateCompany({
			input: {
				id: company.id,
				emailSettings: JSON.stringify(emailSettings),
			},
		});
	};

	confirmRemove = (property) => {
		this.setState({
			[property]: null,
		});
	};

	createMixpanelEvents = () => {
		const { company } = this.props;
		let mixpanelEvents = {};
		let mixpanelEvent = false;

		const eventMap = {
			allowInternalMobility: 'Internal Mobility',
			enableCustomPage: 'Custom Page',
			enableExtendedNetwork: 'Referral Network',
			enableCareerProfile: 'Career Profile',
			whiteLabel: 'Hide ERIN Branding',
			enableGeneralReferrals: 'General Referrals',
			publicLeaderboard: 'Public Leaderboard',
			hideReferralsRanking: 'Hide Referrals Ranking',
		};

		for (const event in eventMap) {
			if (this.state[event] !== company[event]) {
				mixpanelEvents[eventMap[event]] = this.state[event];
				mixpanelEvent = true;
			}
		}

		// Extra case for login screen shading which has nested JSON value that needs parsed
		const whiteLabelSettings = JSON.parse(
			get(this.props, 'company.whiteLabelSettings', '{}')
		);
		if (
			this.state.whiteLabelSettings.hideLoginScreenShading !=
			whiteLabelSettings?.hideLoginScreenShading
		) {
			mixpanelEvents = {
				'Hide Login Screen Shading':
					this.state.whiteLabelSettings.hideLoginScreenShading,
				...mixpanelEvents,
			};
			mixpanelEvent = true;
		}

		return mixpanelEvent ? mixpanelEvents : null;
	};

	deleteFollowUpEmail = (id) => {
		const { company, onUpdateCompany } = this.props;
		const emailSettings = get(this.state, 'emailSettings');
		let inviteEmails = get(emailSettings, 'onboarding.inviteEmails');
		inviteEmails = inviteEmails.filter((email) => email.id !== id);
		inviteEmails = _.sortBy(inviteEmails, ['days']);
		inviteEmails = inviteEmails.map((email, index) => {
			email.id = index;
			return email;
		});
		emailSettings.onboarding.inviteEmails = inviteEmails;
		this.setState({ emailSettings });
		onUpdateCompany({
			input: {
				id: company.id,
				emailSettings: JSON.stringify(emailSettings),
			},
		});
	};

	handleAcceptedReferralReminder = (value) => {
		this.setState({
			enableAcceptedReferralReminder: value.target.checked,
		});
	};

	handleAdminInRanking = (value) => {
		this.setState({
			includeAdminInRanking: value.target.checked,
		});
	};

	handleAllowInternalMobility = (value) => {
		this.setState({
			allowInternalMobility: value.target.checked,
		});
	};

	handleAllowSelfReferralsChange = (value) => {
		this.setState({
			allowSelfReferrals: value.target.checked,
			allowSelfReferralsInternalLink: false,
		});
	};

	handleAllowSelfReferralsInternalLinkChange = (value) => {
		this.setState({
			allowSelfReferralsInternalLink: value.target.checked,
		});
	};

	handleAllowUserLoginWhenClaimInactive = (e) => {
		const { company, onUpdateCompany } = this.props;
		const { automationSettings } = this.state;
		const allowUserLoginWhenClaimInactive = e.target.checked;
		automationSettings.allowUserLoginWhenClaimInactive =
			allowUserLoginWhenClaimInactive;

		this.setState({ allowUserLoginWhenClaimInactive, automationSettings });
		onUpdateCompany({
			input: {
				id: company.id,
				automationSettings: JSON.stringify(automationSettings),
			},
		});
	};

	handleAppReview = (value) => {
		this.setState({
			enableAppReview: value.target.checked,
		});
	};

	handleBonusEarnedNoteChange = (e) => {
		this.setState({
			bonusEarnedNote: e.target.value,
		});
	};

	handleCandidateBonus = (value) => {
		this.setState({
			enableCandidateBonus: value.target.checked,
		});
	};

	handleConfirmCompliance = (value) => {
		this.setState({
			confirmCompliance: value.target.checked,
		});
	};

	handleCreateDepartmentKeyword = async (key) => {
		const { selectedDepartment } = this.state;
		let keywords = get(selectedDepartment, 'keywords');
		keywords = keywords ? keywords : [];
		keywords.push(key);
		const updatedDepartment = {
			id: selectedDepartment.id,
			companyId: selectedDepartment.companyId,
			keywords: JSON.stringify(keywords),
			name: selectedDepartment.name,
		};
		selectedDepartment.keywords = keywords;
		this.setState(selectedDepartment);
		await this.props.onUpdateDepartment({ input: updatedDepartment });
		message.success('Successfully Added Keyword');
	};

	handleCreatePickerValues = async (key, picker) => {
		const { company, onUpdateCompany } = this.props;
		let loginFormPickerSettings = parse(
			get(this.props, 'company.loginFormPickerSettings')
		);
		loginFormPickerSettings = loginFormPickerSettings
			? loginFormPickerSettings
			: [];
		const index = loginFormPickerSettings.findIndex(
			(p) => p.name === picker.name
		);
		if (index >= 0) {
			const newValues = loginFormPickerSettings[index].values;
			if (!newValues.find((value) => value === key)) newValues.push(key);
			const selectedPickerSetting = {
				...get(this.state, 'selectedPickerSetting'),
				values: newValues,
			};
			this.setState({ selectedPickerSetting });
			loginFormPickerSettings[index].values = newValues;
			loginFormPickerSettings = JSON.stringify(loginFormPickerSettings);
			const input = {
				input: {
					id: company.id,
					loginFormPickerSettings,
				},
			};
			const responseForStoreUpdate = await onUpdateCompany(input);
			this.props.updateCurrentUserCompany(
				responseForStoreUpdate.data.updateCompany
			);
		}
	};

	handleCreateUserGroupKeyword = async (key) => {
		const { selectedUserGroup } = this.state;
		let keywords = get(selectedUserGroup, 'keywords');
		keywords = keywords ? keywords : [];
		keywords.push(key);
		const updatedUserGroup = {
			id: selectedUserGroup.id,
			companyId: selectedUserGroup.companyId,
			keywords: JSON.stringify(keywords),
			name: selectedUserGroup.name,
		};
		selectedUserGroup.keywords = keywords;
		this.setState(selectedUserGroup);
		await this.props.onUpdateUserGroup({ input: updatedUserGroup });
		message.success('Successfully Added Keyword');
	};

	handleCustomPage = (value) => {
		this.setState({
			enableCustomPage: value.target.checked,
		});
	};

	handleCustomPage2 = (value) => {
		this.setState({
			enableCustomPage2: value.target.checked,
		});
	};

	handleCustomPage3 = (value) => {
		this.setState({
			enableCustomPage3: value.target.checked,
		});
	};

	handleDefaultToSubcompany = (value) => {
		this.setState({
			defaultToSubcompany: value.target.checked,
		});
	};

	handleDeleteDepartmentKeyword = async (key) => {
		const { selectedDepartment } = this.state;
		const keywords = get(selectedDepartment, 'keywords', []);
		const index = keywords.indexOf(key);
		if (index > -1) {
			keywords.splice(index, 1);
			const updatedDepartment = {
				id: selectedDepartment.id,
				companyId: selectedDepartment.companyId,
				keywords: JSON.stringify(keywords),
				name: selectedDepartment.name,
			};
			await this.props.onUpdateDepartment({ input: updatedDepartment });
			message.success('Successfully Deleted Keyword');
		}
	};

	handleDeletePicker = async (item) => {
		const { company, onUpdateCompany } = this.props;
		let loginFormPickerSettings = parse(
			get(this.props, 'company.loginFormPickerSettings')
		);
		loginFormPickerSettings = loginFormPickerSettings
			? loginFormPickerSettings
			: [];
		const index = loginFormPickerSettings.findIndex((p) => p.name === item);
		if (index > -1) {
			loginFormPickerSettings.splice(index, 1);
			loginFormPickerSettings = JSON.stringify(loginFormPickerSettings);
			const input = {
				input: {
					id: company.id,
					loginFormPickerSettings,
				},
			};
			const responseForStoreUpdate = await onUpdateCompany(input);
			this.props.updateCurrentUserCompany(
				responseForStoreUpdate.data.updateCompany
			);
		}
	};

	handleDeletePickerValues = async (item) => {
		const { company, onUpdateCompany } = this.props;
		const picker = get(this.state, 'selectedPickerSetting');
		let loginFormPickerSettings = parse(
			get(this.props, 'company.loginFormPickerSettings')
		);
		loginFormPickerSettings = loginFormPickerSettings
			? loginFormPickerSettings
			: [];
		const values = get(picker, 'values');

		const index = values.indexOf(item);
		const pickerIndex = loginFormPickerSettings.findIndex(
			(setting) => setting.name === picker.name
		);
		if (index > -1) {
			values.splice(index, 1);
			loginFormPickerSettings[pickerIndex].values = values;
			const selectedPickerSetting = {
				...get(this.state, 'selectedPickerSetting'),
				values,
			};
			this.setState({ selectedPickerSetting });
			loginFormPickerSettings = JSON.stringify(loginFormPickerSettings);
			const input = {
				input: {
					id: company.id,
					loginFormPickerSettings,
				},
			};
			const responseForStoreUpdate = await onUpdateCompany(input);
			this.props.updateCurrentUserCompany(
				responseForStoreUpdate.data.updateCompany
			);
		}
	};

	handleDeleteUserGroupKeyword = async (key) => {
		const { selectedUserGroup } = this.state;
		const keywords = get(selectedUserGroup, 'keywords', []);
		const index = keywords.indexOf(key);
		if (index > -1) {
			keywords.splice(index, 1);
			const updatedUserGroup = {
				id: selectedUserGroup.id,
				companyId: selectedUserGroup.companyId,
				keywords: JSON.stringify(keywords),
				name: selectedUserGroup.name,
			};
			await this.props.onUpdateUserGroup({ input: updatedUserGroup });
			message.success('Successfully Deleted Keyword');
		}
	};

	handleDisableClaimYourAccountLogin = (value) => {
		this.setState({ disableClaimYourAccountLogin: value.target.checked });
	};

	handleDisableEmployeeEditableFields = (value) => {
		this.setState({
			disableEmployeeEditableFields: value.target.checked,
			userEditSettings: {
				lockProfileFields: {
					employeeName: value.target.checked,
					employeeDepartment: value.target.checked,
					employeeJobTitle: value.target.checked,
					employeeLocation: value.target.checked,
				},
			},
		});
	};

	handleDisableExtendedUserJobsView = (value) => {
		this.setState({
			disableExtendedUserJobsView: value.target.checked,
		});
	};

	handleDisableNewReferralSMSNotification = (value) => {
		this.setState({
			disableNewReferralSMSNotification: value.target.checked,
		});
	};

	handleDisableReferrals = (value) => {
		this.setState({
			disableReferrals: value.target.checked,
		});
	};

	handleDisableSAMLLogin = (value) => {
		this.setState({ disableSAMLLogin: value.target.checked });
	};

	handleDisableShareLink = (value) => {
		this.setState({
			disableShareLink: value.target.checked,
		});
	};

	handleDisableShareLinkIfNoPublicUrl = (value) => {
		this.setState({
			hideShareLinkNoPublicUrl: value.target.checked,
		});
	};

	handleDisableSite = (value) => {
		this.setState({
			disableSite: value.target.checked,
		});
	};

	handleDisableSmartReferralChange = (value) => {
		this.setState({
			disableSmartReferrals: value.target.checked,
		});
	};

	handleEnableRecognition = (value) => {
		this.setState({
			enableRecognition: value.target.checked,
		});
	};

	handleEditorChange = (content, editor) => {
		this.setState({
			dashboardReferralPolicyText: content,
		});
	};

	handleEnableCareerProfile = (value) => {
		this.setState({
			enableCareerProfile: value.target.checked,
		});
		if (!value.target.checked && !get(this.state, 'enableExtendedNetwork'))
			this.setState({
				enableJobMatching: false,
			});
	};

	handleEnableExtendedNetwork = (value) => {
		this.setState({
			enableExtendedNetwork: value.target.checked,
		});
		if (!value.target.checked && !get(this.state, 'enableCareerProfile')) {
			this.setState({
				disableExtendedUserJobsView: false,
				enableJobMatching: false,
			});
		} else if (!value.target.checked) {
			this.setState({
				disableExtendedUserJobsView: false,
			});
		}
	};

	handleEnableJobMatching = (value) => {
		this.setState({
			enableJobMatching: value.target.checked,
		});
	};

	handleEnableLead = (value) => {
		this.setState({
			enableLead: value.target.checked,
		});
	};

	handleEnableOnboardingEmails = (e) => {
		const { company, onUpdateCompany } = this.props;
		const emailSettings = get(this.state, 'emailSettings');
		const sendInviteEmails = e.target.checked;
		const onboardingSettings = get(emailSettings, 'onboarding', {});
		let inviteEmails = get(onboardingSettings, 'inviteEmails');
		if (sendInviteEmails && (!inviteEmails || inviteEmails.length <= 0))
			inviteEmails = [{ id: 0, days: 0 }];
		emailSettings.onboarding.inviteEmails = inviteEmails;
		emailSettings.onboarding.sendInviteEmails = sendInviteEmails;
		this.setState({ emailSettings });
		onUpdateCompany({
			input: {
				id: company.id,
				emailSettings: JSON.stringify(emailSettings),
			},
		});
	};

	handleExternalUserSignUp = (value) => {
		this.setState({
			externalUserSignUp: value.target.checked,
		});
	};

	handleFilterDepartment = async (id) => {
		const { departments } = this.props;
		const department = departments.find((x) => {
			return x.id == id;
		});
		this.setState({
			selectedDepartment: department,
		});
	};

	handleFilterPickerSettings = async (name) => {
		const loginFormPickerSettings = parse(
			get(this.props, 'company.loginFormPickerSettings')
		);
		const selectedPickerSetting = (loginFormPickerSettings || []).find(
			(picker) => {
				return picker.name == name;
			}
		);
		this.setState({
			selectedPickerSetting,
		});
	};

	handleFilterUserGroup = async (id) => {
		const { userGroups } = this.props;
		const selectedUserGroup = userGroups.find((x) => {
			return x.id == id;
		});
		this.setState({
			selectedUserGroup,
		});
	};

	handleGeneralReferrals = (value) => {
		this.setState({
			enableGeneralReferrals: value.target.checked,
		});
	};

	handleGenericReferralEmail = (value) => {
		this.setState({
			enableGenericReferralEmail: value.target.checked,
		});
	};

	handleHideBonus = (value) => {
		this.setState({
			hideBonus: value.target.checked,
		});
	};

	handleHideDepartment = async (department, key) => {
		let { filteredDepartments } = this.state;
		if (filteredDepartments.map((dept) => dept.name).includes(department.label))
			return;
		filteredDepartments = [
			...filteredDepartments,
			{ label: department.label, id: key },
		];
		this.setState({
			filteredDepartments,
		});
	};

	handleHideJobs = (value) => {
		this.setState({
			hideJobsPage: value.target.checked,
		});
	};

	handleHideLoginForm = (value) => {
		this.setState({
			hideLoginForm: value.target.checked,
		});
	};

	handleHideLoginScreenShading = (value) => {
		this.setState({
			whiteLabelSettings: {
				...this.state.whiteLabelSettings,
				hideLoginScreenShading: value.target.checked,
			},
		});
	};

	handleHideMesssageRecruiter = (value) => {
		this.setState({
			hideMessageRecruiter: value.target.checked,
		});
	};

	handleHideSubcompanyOnSignup = (value) => {
		this.setState({
			hideSubcompanyOnSignup: value.target.checked,
		});
	};

	handleHideSubcompanyOnJobs = (value) => {
		this.setState({
			hideSubcompanyOnJobs: value.target.checked,
		});
	};

	handleHideUserGroup = async (group, key) => {
		let { filteredUserGroups, hideInterestedForGroup } = this.state;
		if (filteredUserGroups.map((grp) => grp.name).includes(group.label)) return;
		filteredUserGroups = [
			...filteredUserGroups,
			{ label: group.label, id: key },
		];
		this.setState({
			filteredUserGroups,
		});
	};

	handleIgnoreSubCompany = (value) => {
		this.setState({
			ignoreSubcompany: value.target.checked,
		});
	};

	handleLeaderboard = (value) => {
		this.setState({
			publicLeaderboard: value.target.checked,
		});
	};

	handleLocationFilter = (value) => {
		this.setState({
			defaultLocation: value,
		});
	};

	handleLockSpecificField = (value) => {
		this.setState((prevState) => ({
			userEditSettings: {
				...prevState.userEditSettings,
				lockProfileFields: {
					...prevState.userEditSettings.lockProfileFields,
					[value.target.id]: value.target.checked,
				},
			},
		}));
	};

	handleManagerPermissions = (value) => {
		this.setState({
			disableManagerPermissions: value.target.checked,
		});
	};

	// PermissionMode can be "hidden", "readOnly", or "edit"
	handleManagerPermissionsByType = (key, permissionMode) => {
		this.setState((prevState) => ({
			disableManagerPermissionsByType: {
				...prevState.disableManagerPermissionsByType,
				[key]: permissionMode,
			},
		}));
	};

	setManagerPermissionsToDefault = () => {
		this.setState({
			disableManagerPermissionsByType: {
				managerReferrals: 'edit',
				managerJobs: 'edit',
				managerBonuses: 'hidden',
				managerEmployees: 'hidden',
			},
		});
	};

	handlePickerRequiredChange = (value) => {
		const selectedPickerSetting = get(this.state, 'selectedPickerSetting');
		selectedPickerSetting.required = value.target.checked;
		this.setState({
			selectedPickerSetting,
		});
	};

	handleProspect = (value) => {
		this.setState({
			enableProspectCreation: value.target.checked,
		});
	};

	handleRecruiterInfo = (value) => {
		this.setState({
			hideRecruiterInfo: value.target.checked,
		});
	};

	handleRecruiterInfoJobDetails = (value) => {
		this.setState({
			hideRecruiterInfoJobDetails: value.target.checked,
		});
	};

	handleReferralBonusWaitingPeriodChange = (value) => {
		const values = ['0', '30', '60', '90', '120'];
		if (values.includes(value)) {
			values.splice(this.state.daysLookupData.indexOf(value), 1);
			values[0] = value;
		} else if (value.length > 0) {
			values[0] = value;
		} else if (value.length === 0) {
			values[0] = '0';
		}

		const children = values.map((days) => (
			<AutoComplete.Option key={days}>{days}</AutoComplete.Option>
		));

		this.setState({
			referralBonusWaitingPeriod: value,
			daysLookupChildren: children,
		});
	};

	handleReferralRanking = (value) => {
		this.setState({
			hideReferralsRanking: value.target.checked,
		});
	};

	handleRemoveHideDepartment = (department) => {
		const { filteredDepartments } = this.state;
		const index = filteredDepartments
			.map((dept) => dept.name)
			.indexOf(department);
		filteredDepartments.splice(index, 1);
		this.setState({
			filteredDepartments,
		});
	};

	handleRemoveHideUserGroup = (group) => {
		const { filteredUserGroups } = this.state;
		const index = filteredUserGroups.map((grp) => grp.name).indexOf(group);
		filteredUserGroups.splice(index, 1);
		this.setState({
			filteredUserGroups,
		});
	};

	handleResetPopup = (value) => {
		this.setState({
			resetPopup: value.target.checked,
		});
	};

	handleRetroactiveBonus = (value) => {
		this.setState({
			enableRetroactiveBonuses: value.target.checked,
		});
	};

	handleRequireBonusChangeReason = (value) => {
		this.setState({
			requireBonusChangeReason: value.target.checked,
		});
	};

	handleSelectedReferredReportTo = (value, index, e) => {
		const { selectedReferredReportTo } = this.state;
		if (e.target.checked) {
			const sender = {};
			sender.email = value;
			this.setState({
				selectedReferredReportTo: [...selectedReferredReportTo, sender],
			});
		} else {
			const copy = [...selectedReferredReportTo];
			const myIndex = copy.findIndex((p) => p.email == value);
			if (myIndex !== -1) {
				copy.splice(myIndex, 1);
			}

			this.setState({
				selectedReferredReportTo: copy,
			});
		}
	};

	handleSendAdminNotificationsOnReferralChange = (value) => {
		this.setState({
			sendAdminNotificationsOnReferral: value.target.checked,
		});
	};

	handleSenderAdmin = (value, index, e) => {
		const { senderAdmins } = this.state;
		if (e.target.checked) {
			const sender = {};
			sender.email = value;
			this.setState({
				senderAdmins: [...senderAdmins, sender],
			});
		} else {
			const copy = [...senderAdmins];
			const myIndex = copy.findIndex((p) => p.email == value);
			if (myIndex !== -1) {
				copy.splice(myIndex, 1);
			}

			this.setState({
				senderAdmins: copy,
			});
		}
	};

	handleShowPoweredByErin = (value) => {
		this.setState({
			showPoweredByErin: value.target.checked,
		});
	};

	handleSubmit = async (e) => {
		e.preventDefault();
		const {
			company,
			handleDuplicateError,
			departments = [],
			onUpdateCompany,
			onCreateDepartment,
			userGroups,
			onCreateUserGroup,
			handleDuplicateUserGroupError,
		} = this.props;

		const { themeError } = this.state;
		const {
			symbolFile,
			backgroundFile,
			errorImageFile,
			currentSymbol,
			currentBackground,
			currentErrorImage,
			currentSocial,
			filteredUserGroups,
			filteredDepartments,
			companyFaviconChanged,
			companyFavicon,
			hideDateCreatedJobDetails,
			userEditSettings,
			disableManagerPermissionsByType,
		} = this.state;
		this.props.resetErrors();

		// Runs function that checks if any settings have been changed that we track with mixpanel and returns object or null
		const mixpanelEvents = this.createMixpanelEvents();

		// Resolves validate fields promise so that you can use await elsewhere in handleSubmit
		const { error, values } = await new Promise((resolve) => {
			this.props.form.validateFields((error, values) => {
				resolve({ error, values });
			});
		});
		for (const [key, value] of Object.entries(themeError)) {
			if (value === true) {
				this.props.handleError();
				return;
			}
		}

		this.setState({
			departmentError: false,
			userGroupError: false,
		});
		if (error) {
			this.props.handleError();
			return;
		}

		let deptName = values.departmentName;
		deptName &&= sanitizer(deptName);

		const isDuplicateDept = departments.some((department) => {
			return (
				(department.name || '').toLowerCase() === (deptName || '').toLowerCase()
			);
		});

		if (deptName && !isDuplicateDept) {
			onCreateDepartment({
				input: {
					companyId: company.id,
					name: deptName,
					active: true,
				},
			});
		} else if (deptName && isDuplicateDept) {
			handleDuplicateError();
			return;
		}

		const isDuplicateUserGroup = userGroups.some((userGroup) => {
			return userGroup.name === values.userGroupName;
		});
		let userGrp = values.userGroupName;
		userGrp &&= sanitizer(userGrp);
		if (userGrp && !isDuplicateUserGroup) {
			onCreateUserGroup({
				input: {
					companyId: company.id,
					name: userGrp,
					active: true,
					currency: 'USD',
					measurement: 'imperial',
				},
			});
		} else if (userGrp && isDuplicateUserGroup) {
			handleDuplicateUserGroupError();
			return;
		}

		const backgroundImg = currentBackground ? currentBackground : null;

		const errorImg = currentErrorImage ? currentErrorImage : null;

		const symbolImg = currentSymbol ? currentSymbol : null;

		const { hideLoginScreenShading } = this.state.whiteLabelSettings;
		const updatedWhiteLabelSettings = {
			...this.state.whiteLabelSettings,
			hideLoginScreenShading: hideLoginScreenShading || false,
		};

		const whiteLabelSettingsString = JSON.stringify(updatedWhiteLabelSettings);
		// Backwards compatibility with disableManagerPermissions boolean
		let disableManagerPermissions = false;
		if (
			disableManagerPermissionsByType.managerReferrals === 'readOnly' ||
			disableManagerPermissionsByType.managerReferrals === 'hidden' ||
			disableManagerPermissionsByType.managerJobs === 'readOnly' ||
			disableManagerPermissionsByType.managerJobs === 'hidden'
		) {
			disableManagerPermissions = true;
		}

		const vals = {
			id: get(company, 'id'),
			allowSelfReferrals: get(this.state, 'allowSelfReferrals', false),
			allowInternalMobility: get(this.state, 'allowInternalMobility', false),
			disableSmartReferrals: get(this.state, 'disableSmartReferrals', false),
			enableRecognition: get(this.state, 'enableRecognition', false),
			enableExtendedNetwork: get(this.state, 'enableExtendedNetwork', false),
			enableJobMatching: get(this.state, 'enableJobMatching', false),
			enableCareerProfile: get(this.state, 'enableCareerProfile', false),
			logo: this.props.company.logo || null,
			disableExtendedUserJobsView: get(
				this.state,
				'disableExtendedUserJobsView',
				false
			),
			disableClaimYourAccountLogin: get(
				this.state,
				'disableClaimYourAccountLogin'
			),
			disableSAMLLogin: get(this.state, 'disableSAMLLogin'),
			confirmCompliance: get(this.state, 'confirmCompliance', false),
			whiteLabel: get(this.state, 'whiteLabel', false)
				? get(this.state, 'whiteLabel')
				: false,
			enableGeneralReferrals: get(this.state, 'enableGeneralReferrals', false),
			enableCandidateBonus: get(this.state, 'enableCandidateBonus', false),
			enableRetroactiveBonuses: get(
				this.state,
				'enableRetroactiveBonuses',
				false
			),
			disableManagerPermissions,
			enableProspectCreation: get(this.state, 'enableProspectCreation'),
			enableDashboardSearch:
				!get(this.state, 'hideJobsPage', false) &&
				get(this.state, 'enableDashboardSearch', false),
			hideJobsPage: get(this.state, 'hideJobsPage', false),
			disableShareLink: get(this.state, 'disableShareLink'),
			hideShareLinkNoPublicUrl: get(this.state, 'hideShareLinkNoPublicUrl'),
			sendAdminNotificationsOnReferral: get(
				this.state,
				'sendAdminNotificationsOnReferral',
				false
			),
			sendAdminNotificationsOnInternalCandidatesFound:
				get(this.state, 'allowInternalMobility', false) &&
				get(
					this.state,
					'sendAdminNotificationsOnInternalCandidatesFound',
					false
				),
			allowSelfReferralsInternalLink: get(
				this.state,
				'allowSelfReferralsInternalLink',
				false
			),
			enableAcceptedReferralReminder: get(
				this.state,
				'enableAcceptedReferralReminder',
				false
			),
			externalUserSignUp: get(this.state, 'externalUserSignUp'),
			hideLoginForm: get(this.state, 'hideLoginForm'),
			enableAppReview: get(this.state, 'enableAppReview', false),
			disableReferrals: get(this.state, 'disableReferrals', false),
			hideBonus: get(this.state, 'hideBonus', false),
			includeAdminInRanking: get(this.state, 'includeAdminInRanking', false),
			disableSite: get(this.state, 'disableSite'),
			defaultLocation: get(this.state, 'defaultLocation', '0'),
			publicLeaderboard: get(this.state, 'publicLeaderboard', false),
			hideReferralsRanking: get(this.state, 'hideReferralsRanking', false),
			enableCustomPage: get(this.state, 'enableCustomPage', false),
			enableCustomPage2: get(this.state, 'enableCustomPage2', false),
			enableCustomPage3: get(this.state, 'enableCustomPage3', false),
			hideWhatsApp: get(this.state, 'hideWhatsApp', false),
			hideRecruiterInfo: get(this.state, 'hideRecruiterInfo', false),
			disableNewReferralSMSNotification: get(
				this.state,
				'disableNewReferralSMSNotification',
				false
			),
			hideRecruiterInfoJobDetails: get(
				this.state,
				'hideRecruiterInfoJobDetails',
				false
			),
			hideMessageRecruiter: get(this.state, 'hideMessageRecruiter', false),
			whiteLabelSettings: whiteLabelSettingsString,
			ignoreSubcompany: get(this.state, 'ignoreSubcompany', false),
			enableGenericReferralEmail: get(
				this.state,
				'enableGenericReferralEmail',
				false
			),
			enableLead: get(this.state, 'enableLead', false),
			requireBonusChangeReason: get(
				this.state,
				'requireBonusChangeReason',
				false
			),
			resetPopup: get(this.state, 'resetPopup', false),
			defaultToSubcompany: get(this.state, 'defaultToSubcompany', false),
			hideSubcompanyOnSignup: get(this.state, 'hideSubcompanyOnSignup', false),
			hideSubcompanyOnJobs: get(this.state, 'hideSubcompanyOnJobs', false),
			userEditSettings: JSON.stringify(userEditSettings),
			disableManagerPermissionsByType: JSON.stringify(
				disableManagerPermissionsByType
			),
			countryCode:
				this.countryCodeRef?.current?.getCountry() ??
				this.props.currentUser?.company?.countryCode ??
				'US',
		};
		vals.hideDateCreatedJobDetails = hideDateCreatedJobDetails
			? hideDateCreatedJobDetails
			: null;
		if (
			this.props.currentUser?.role === 'superAdmin' &&
			this.props.company?.whiteLabel
		) {
			let { ios } = values;
			ios &&= sanitizer(ios);
			let { android } = values;
			android &&= sanitizer(android);
			vals.appStoreUrls = {
				ios,
				android,
			};
		}

		let name = get(values, 'name');
		name &&= sanitizer(name);
		let defaultBonusAmount = get(values, 'defaultBonusAmount');
		defaultBonusAmount &&= sanitizer(defaultBonusAmount);
		let contactIncentiveBonus = get(values, 'contactIncentiveBonus');
		contactIncentiveBonus &&= sanitizer(contactIncentiveBonus);
		let referralStatus = get(values, 'referralStatus');
		referralStatus &&= sanitizer(referralStatus);
		let subCompanyLabel = get(values, 'subCompanyLabel');
		subCompanyLabel &&= sanitizer(subCompanyLabel);
		let labelDepartment = get(values, 'labelDepartment');
		labelDepartment &&= sanitizer(labelDepartment);
		let labelSocialShare = get(values, 'labelSocialShare');
		labelSocialShare &&= sanitizer(labelSocialShare);
		let internalMobilityCustomTitle = get(
			values,
			'internalMobilityCustomTitle'
		);
		internalMobilityCustomTitle &&= sanitizer(internalMobilityCustomTitle);
		let loginPicker = get(values, 'loginPicker');
		loginPicker &&= sanitizer(loginPicker);
		let pickerError = get(values, 'pickerError');
		pickerError &&= sanitizer(pickerError);
		let pickerName = get(values, 'pickerName');
		pickerName &&= sanitizer(pickerName);
		let pickerPlaceholder = get(values, 'pickerPlaceholder');
		pickerPlaceholder &&= sanitizer(pickerPlaceholder);
		let pickerUserAttribute = get(values, 'pickerUserAttribute');
		pickerUserAttribute &&= sanitizer(pickerUserAttribute);
		let pickerRequired = get(values, 'pickerRequired');
		pickerRequired &&= sanitizer(pickerRequired);
		let selAdmins = get(values, 'selectedAdmins');
		selAdmins &&= sanitizer(selAdmins);
		let refReportTo = get(values, 'referredReportTo');
		refReportTo &&= sanitizer(refReportTo);
		let { ssoGoogleDomain } = values;
		ssoGoogleDomain &&= sanitizer(ssoGoogleDomain);
		let { helpUrl } = values;
		helpUrl &&= sanitizer(helpUrl);
		let { privacyUrl } = values;
		privacyUrl &&= sanitizer(privacyUrl);
		let { termsUrl } = values;
		termsUrl &&= sanitizer(termsUrl);
		let { linkUrl } = values;
		linkUrl &&= sanitizer(linkUrl);
		let { linkName } = values;
		linkName &&= sanitizer(linkName);
		let { senderEmailAddress } = values;
		senderEmailAddress &&= senderEmailAddress;
		let { whiteLabelServiceName } = values;
		whiteLabelServiceName &&= sanitizer(whiteLabelServiceName);
		let { labelEmployeeID } = values;
		labelEmployeeID &&= sanitizer(labelEmployeeID);
		let { customPageTitle } = values;
		customPageTitle &&= sanitizer(customPageTitle);
		let { customPageTitle2 } = values;
		customPageTitle2 &&= sanitizer(customPageTitle2);
		let { customPageTitle3 } = values;
		customPageTitle3 &&= sanitizer(customPageTitle3);
		let { faviconTitle } = values;
		faviconTitle &&= sanitizer(faviconTitle);
		let { popupTitle } = values;
		popupTitle &&= sanitizer(popupTitle);

		if (get(values, 'name')) vals.name = name;
		if (get(values, 'defaultBonusAmount'))
			vals.defaultBonusAmount = values.defaultBonusAmount
				? defaultBonusAmount.replaceAll(/\D+/g, '')
				: '0';
		if (get(values, 'contactIncentiveBonus'))
			vals.defaultBonusAmount = values.contactIncentiveBonus
				? contactIncentiveBonus.replaceAll(/\D+/g, '')
				: '0';
		if (get(this.state, 'referralBonusWaitingPeriod'))
			vals.referralBonusWaitingPeriod = get(
				this.state,
				'referralBonusWaitingPeriod',
				'90'
			);
		if (backgroundImg) vals.background = backgroundImg;
		if (errorImg) vals.errorImage = errorImg;
		if (symbolImg) vals.symbol = symbolImg;
		if (!currentBackground) vals.background = null;
		if (!currentErrorImage) vals.errorImage = null;
		if (!currentSymbol) vals.symbol = null;
		if (get(this.state, 'dashboardReferralPolicyText'))
			vals.dashboardReferralPolicyText =
				this.state.dashboardReferralPolicyText.toString('html');
		if (get(this.state, 'bonusEarnedNote'))
			vals.bonusEarnedNote = get(this.state, 'bonusEarnedNote', ' ');
		if (get(this.state, 'theme')) vals.theme = JSON.stringify(this.state.theme);
		if (get(values, 'referralStatus')) {
			vals.referralStatus = referralStatus ? referralStatus : 'Interviewing';
		}

		if (get(values, 'subCompanyLabel')) {
			vals.subCompanyLabel = subCompanyLabel ? subCompanyLabel : 'Company';
		}

		if (get(values, 'labelDepartment')) {
			vals.labelDepartment = labelDepartment ? labelDepartment : 'Company';
		}

		if (get(values, 'labelSocialShare')) {
			vals.labelSocialShare = labelSocialShare ? labelSocialShare : '';
		}

		if (filteredUserGroups) {
			vals.hideInterestedForGroup = JSON.stringify(filteredUserGroups);
		}

		if (filteredDepartments) {
			vals.hideShareLinkForDepartment = JSON.stringify(filteredDepartments);
		}

		if (get(values, 'internalMobilityCustomTitle')) {
			vals.internalMobilityCustomTitle = internalMobilityCustomTitle
				? internalMobilityCustomTitle
				: 'Internal Mobility';
		}

		if (get(values, 'loginPicker')) {
			let loginFormPickerSettings = parse(
				get(this.props, 'company.loginFormPickerSettings')
			);
			loginFormPickerSettings = loginFormPickerSettings
				? loginFormPickerSettings
				: [];
			const newPicker = {
				id: loginFormPickerSettings.length,
				name: loginPicker,
				error: 'Please select an Item',
				placeholder: 'Select an Item',
				required: true,
				values: [],
			};
			if (
				!loginFormPickerSettings.find((picker) => picker.name === loginPicker)
			)
				loginFormPickerSettings.push(newPicker);
			vals.loginFormPickerSettings = JSON.stringify(loginFormPickerSettings);
		}

		if (
			pickerError ||
			pickerName ||
			pickerPlaceholder ||
			pickerUserAttribute ||
			pickerRequired !== undefined
		) {
			let loginFormPickerSettings = parse(
				get(this.props, 'company.loginFormPickerSettings')
			);
			loginFormPickerSettings = loginFormPickerSettings
				? loginFormPickerSettings
				: [];
			const index = loginFormPickerSettings.findIndex(
				(setting) =>
					get(this.state, 'selectedPickerSetting.name') === setting.name
			);
			if (get(values, 'pickerError'))
				loginFormPickerSettings[index].error = pickerError;
			if (get(values, 'pickerName'))
				loginFormPickerSettings[index].name = pickerName;
			if (get(values, 'pickerPlaceholder'))
				loginFormPickerSettings[index].placeholder = pickerPlaceholder;
			if (get(values, 'pickerUserAttribute'))
				loginFormPickerSettings[index].userAttribute =
					values.pickerUserAttribute;
			if (get(values, 'pickerRequired') !== undefined)
				loginFormPickerSettings[index].required = pickerRequired;
			vals.loginFormPickerSettings = JSON.stringify(loginFormPickerSettings);
		}

		const selectedAdmins = [];
		if (get(values, 'selectedAdmins')) {
			var string_array = selAdmins.split(',');

			for (var i = 0; i < string_array.length; i++) {
				// Trim the excess whitespace.
				string_array[i] = string_array[i]
					.replace(/^\s*/, '')
					.replace(/\s*$/, '');
				// Add additional code here, such as:
				selectedAdmins.push({ email: string_array[i] });
			}

			vals.selectedAdmins = JSON.stringify(selectedAdmins);
		}

		const referredReportTo = [];
		if (get(values, 'referredReportTo')) {
			var string_array = refReportTo.split(',');

			for (var i = 0; i < string_array.length; i++) {
				// Trim the excess whitespace.
				string_array[i] = string_array[i]
					.replace(/^\s*/, '')
					.replace(/\s*$/, '');
				// Add additional code here, such as:
				referredReportTo.push({ email: string_array[i] });
			}

			vals.referredReportTo = JSON.stringify(referredReportTo);
		}

		vals.customPageTitle = customPageTitle || null;
		vals.customPageTitle2 = customPageTitle2 || null;
		vals.customPageTitle3 = customPageTitle3 || null;

		// --------------------------------------
		// LICENSING > MANAGE URLS (SuperAdmin)
		// --------------------------------------
		// * check keeps regular admin from clearing superAdmin settings
		const supportAdmin = parse(get(this.props, 'currentUser.admin'));
		const supportAdminPermissions = get(supportAdmin, 'permissions') === '*';
		if (this.props.currentUser?.role === 'superAdmin') {
			vals.ssoGoogleDomain = ssoGoogleDomain || null;
			vals.helpUrl = helpUrl || null;
			vals.privacyUrl = privacyUrl || null;
			vals.termsUrl = termsUrl || null;
			vals.linkUrl = linkUrl || null;
			vals.linkName = linkName || null;

			vals.senderEmailAddress = senderEmailAddress;
			vals.whiteLabelServiceName = whiteLabelServiceName;
			vals.labelEmployeeID = labelEmployeeID;
			vals.faviconTitle = faviconTitle || null;

			vals.favicon = companyFaviconChanged
				? companyFavicon
				: this.props.company.companyFavicon;

			// External share options
			vals.enableReferralNetworkShare = this.state.externalReferralNetworkShare;

			const socialShareOptions = JSON.stringify({
				email: this.state.shareEmail,
				qrCode: this.state.shareQRCode,
			});
			vals.referralNetworkShareOptions = socialShareOptions;

			vals.showPoweredByErin = this.state.showPoweredByErin;
		}

		// NOTE: [values.popupTitle] is a shared admin setting
		vals.popupTitle = popupTitle || null;
		if (get(this.state, 'popupTitleContent'))
			vals.popupTitleContent = this.state.popupTitleContent.toString('html');
		const input = { input: vals };
		const responseForStoreUpdate = await onUpdateCompany(input);
		// Update redux store with response from mutation
		localStorage.removeItem('setWithInDistance');
		this.props.updateCurrentUserCompany(
			responseForStoreUpdate.data.updateCompany
		);
		this.props.handleSuccess();
		this.props.handleEmpSuccess();
		if (mixpanelEvents) {
			mixpanel.track('Company Settings Updated', mixpanelEvents);
		}
	};

	handleThemeChange = (e, key) => {
		const { value } = e.target;
		this.validateColor(value, key);

		if (value !== '') {
			this.setTheme(value, key);
		}
	};

	handleWhatsApp = (value) => {
		this.setState({
			hideWhatsApp: value.target.checked,
		});
	};

	handleWhiteLabelChange = (value) => {
		this.setState({
			whiteLabel: value.target.checked,
		});
	};

	insert_contents = async (inst) => {
		const data = get(this.props, 'company.dashboardReferralPolicyText');
		inst.setContent(data);
	};

	newTinyMsc = () => {
		return (
			<div
				style={{
					height: 350,
					overflow: 'scroll',
					fontFamily: 'inherit',
					width: '100%',
					marginTop: 20,
				}}
			>
				<Editor
					apiKey="folpz5iy2cdn4oje0s8vqakhxncbe6voeqvmbjk88k9127te"
					init={{
						height: 350,
						menubar: false,
						branding: false,
						plugins: ['image code'],
						toolbar:
							'formatselect |bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent  ',
						images_upload_handler: this.uploadImageCallBack,
						automatic_uploads: true,
						file_picker_types: 'image',
						init_instance_callback: this.insert_contents,
						max_width: '100%',
					}}
					onEditorChange={this.handleEditorChange}
				/>
			</div>
		);
	};

	sendReport = () => {
		const endpoint =
			configMode === 'DEV'
				? 'referral-120hour-report-dev-app'
				: 'referral-120hour-report-prod-app';
		lambda({
			endpoint,
			variables: { companyId: this.props.currentUser.companyId },
		});
		message.success('Your request is submitted successfully.', 5);
	};

	toggleSendAdminNotificationsOnInternalCandidatesFound = (value) => {
		this.setState({
			sendAdminNotificationsOnInternalCandidatesFound: value.target.checked,
		});
	};

	uploadFile = (files) => {
		const { file } = files;
		this.setState({ file });
	};

	uploadImageCallBack = async (blobInfo, success, failure, progress) => {
		progress(1);
		const uploadfile = await this.uploadFile(
			blobInfo.base64(),
			blobInfo.blob()
		);
		const link = await downloadFromS3Signed(uploadfile.key, uploadfile.bucket);
		progress(100);
		success(link);
	};

	userExists = (email) => {
		const { senderAdmins } = this.state;
		return senderAdmins.some(function (element) {
			return element.email === email;
		});
	};

	validateColor = (value, key) => {
		const color = new RegExp(colorRegex, 'i');
		const { themeError } = this.state;
		const error = color.test(value);

		switch (key) {
			case 'menuColor': {
				themeError.menuColor = !error;
				break;
			}

			case 'menuHighlightColor': {
				themeError.menuHighlightColor = !error;
				break;
			}

			case 'buttonColor': {
				themeError.buttonColor = !error;
				break;
			}

			case 'addButtonColor': {
				themeError.addButtonColor = !error;
				break;
			}

			case 'jobCardTitleColor': {
				themeError.jobCardTitleColor = !error;
				break;
			}
		}

		this.setState({ themeError });
		return error;
	};

	validateEmail(email) {
		const re = /\S+@\S+\.\S+/;
		return re.test(email);
	}

	renderAdmins = () => {
		const { adminUsers } = this.props;
		const { senderAdmins } = this.state;
		return adminUsers.map((admin, index = 0) => {
			return (
				<div key={index} className={groupRow}>
					<Checkbox
						checked={
							senderAdmins.findIndex((p) => p.email == admin.emailAddress) !==
							-1
						}
						onChange={(e) =>
							this.handleSenderAdmin(admin.emailAddress, index, e)
						}
					>
						{admin.firstName} {admin.lastName}
					</Checkbox>
				</div>
			);
		});
	};

	renderHiringManager = () => {
		const { managers } = this.props;
		const { hiringManager } = this.state;
		const { Option } = Select;
		const options = managers
			.filter((manager) => manager.role !== 'employee')
			.sort(function (a, b) {
				const nameA = a.firstName.toUpperCase(); // ignore upper and lowercase
				const nameB = b.firstName.toUpperCase(); // ignore upper and lowercase
				if (nameA < nameB) {
					return -1;
				}

				if (nameA > nameB) {
					return 1;
				}

				// Names must be equal
				return 0;
			})
			.map((manager) => (
				<Option key={manager.id}>
					{manager.firstName} {manager.lastName}
				</Option>
			));

		return (
			<PageItemSetting
				title=""
				optional={false}
				className={inputContainerSetting}
			>
				<label className={greenLabel} style={{ marginLeft: -10 }}>
					Recruiter
				</label>
				{hiringManager ? (
					<Select
						showSearch
						labelInValue
						style={{ marginLeft: 15 }}
						className={inputContainerSetting}
						optionFilterProp="children"
						value={{ key: hiringManager.id, label: hiringManager.name }}
						placeholder="Ex. James Smith"
						filterOption={(input, option) =>
							option.props.children
								.join('')
								.toLowerCase()
								.includes(input.toLowerCase())
						}
						onSelect={(value) => this.onChangeHiringManager(value)}
					>
						{options}
					</Select>
				) : (
					<Select
						showSearch
						labelInValue
						className={inputContainerSetting}
						style={{ marginLeft: 15 }}
						optionFilterProp="children"
						placeholder="Ex. James Smith"
						filterOption={(input, option) =>
							option.props.children
								.join('')
								.toLowerCase()
								.includes(input.toLowerCase())
						}
						onChange={(value) => this.onChangeHiringManager(value)}
					>
						{options}
					</Select>
				)}
			</PageItemSetting>
		);
	};

	renderNotification = () => {
		const { notificationType } = this.state;

		return (
			<PageItemSetting
				title="Notification"
				subtitle="Who should we notify that this job has been added?"
			>
				{Object.keys(notificationTypes).map((key) => {
					return (
						<Button
							key={key}
							style={{ marginLeft: -10 }}
							className={regularButton(key === notificationType)}
							onClick={this.onChangeNotificationType.bind(this, key)}
						>
							{notificationTypes[key]}
						</Button>
					);
				})}
			</PageItemSetting>
		);
	};

	renderOnboardingEmails = () => {
		const { emailSettings } = this.state;
		const onboardingSettings = get(emailSettings, 'onboarding');
		const inviteEmails = get(onboardingSettings, 'inviteEmails');
		return inviteEmails.map((invite) => {
			return (
				invite.id !== 0 && (
					<div style={{ marginLeft: 40 }}>
						Reminder <strong>{invite.id}</strong> after{' '}
						<strong>{invite.days}</strong> days.
						<Button
							type="link"
							style={{ color: COLORS.red }}
							icon={<DeleteOutlined />}
							onClick={() => this.deleteFollowUpEmail(invite.id)}
						/>
					</div>
				)
			);
		});
	};

	render() {
		const { getFieldDecorator, getFieldValue } = this.props.form;
		const { role } = this.props.currentUser;
		const FormItem = Form.Item;
		const { Option } = Select;
		const { Panel } = Collapse;
		const {
			allMultiLingualData,
			currentUser,
			company,
			success,
			error,
			duplicateDeptError,
			departments,
			handleDepartmentError,
			departmentError,
			userGroups,
			duplicateUserGroupError,
			userGroupError,
			handleUserGroupError,
		} = this.props;
		const {
			currentSymbol,
			currentBackground,
			currentErrorImage,
			currentSocial,
			allowUserLoginWhenClaimInactive,
			themeError,
			theme,
			senderEmailAddress,
			whiteLabelServiceName,
			filteredUserGroups,
			filteredDepartments,
			labelEmployeeID,
			senderAdmins,
			companyFavicon,
			selectedReferredReportTo,
			emailSettings,
			faviconSrc,
			spinnerSrc,
			backgroundSrc,
			errorImageSrc,
		} = this.state;

		const sendOnboardingInviteEmails = get(
			emailSettings,
			'onboarding.sendInviteEmails'
		);
		let loginFormPickerSettings = parse(
			get(this.props, 'company.loginFormPickerSettings')
		);
		loginFormPickerSettings = loginFormPickerSettings
			? loginFormPickerSettings
			: [];

		let selectedAdmins = '';
		if (senderAdmins.length > 0) {
			for (var i = 0; i < senderAdmins.length; i++) {
				selectedAdmins += senderAdmins[i].email + ',';
			}

			selectedAdmins = selectedAdmins.replace(/,\s*$/, '');
		}

		let referredReportTo = '';
		if (selectedReferredReportTo.length > 0) {
			for (var i = 0; i < selectedReferredReportTo.length; i++) {
				referredReportTo += selectedReferredReportTo[i].email + ',';
			}

			referredReportTo = referredReportTo.replace(/,\s*$/, '');
		}

		const uploadFavicon = async ({ file, onSuccess, onError }) => {
			try {
				const newCompanyFavicon = {
					bucket: 'erin-images',
					key: `favicon/${company.id}/${file.name}`,
					region: 'us-east-2',
				};

				await uploadToS3Multipart(
					file,
					newCompanyFavicon.key,
					newCompanyFavicon.bucket
				);

				onSuccess(newCompanyFavicon);
				this.setState({
					companyFavicon: newCompanyFavicon,
					companyFaviconChanged: true,
					faviconSrc: await downloadFromS3Signed(
						newCompanyFavicon.key,
						'erin-images'
					),
				});
			} catch (error_) {
				onError(error_);
			}
		};

		const uploadSymbol = async ({ file, onSuccess, onError }) => {
			try {
				const newSymbol = {
					bucket: 'erin-images',
					key: `symbol/${company.id}/${file.name}`,
					region: 'us-east-2',
				};

				await uploadToS3Multipart(file, newSymbol.key, newSymbol.bucket);

				onSuccess(newSymbol);
				this.setState({
					symbolFile: file,
					currentSymbol: newSymbol,
					spinnerSrc: await downloadFromS3Signed(newSymbol.key, 'erin-images'),
				});
			} catch (error_) {
				onError(error_);
			}
		};

		const uploadBackground = async ({ file, onSuccess, onError }) => {
			try {
				const newBackground = {
					bucket: 'erin-images',
					key: `background/${company.id}/${file.name}`,
					region: 'us-east-2',
				};

				await uploadToS3Multipart(
					file,
					newBackground.key,
					newBackground.bucket
				);

				onSuccess(newBackground);
				this.setState({
					backgroundFile: file,
					currentBackground: newBackground,
					backgroundSrc: await downloadFromS3Signed(
						newBackground.key,
						'erin-images'
					),
				});
			} catch (error_) {
				onError(error_);
			}
		};

		const uploadErrorImage = async ({ file, onSuccess, onError }) => {
			try {
				const newErrorImage = {
					bucket: 'erin-images',
					key: `error-image/${company.id}/${file.name}`,
					region: 'us-east-2',
				};

				await uploadToS3Multipart(
					file,
					newErrorImage.key,
					newErrorImage.bucket
				);

				onSuccess(newErrorImage);
				this.setState({
					errorImageFile: file,
					currentErrorImage: newErrorImage,
					errorImageSrc: await downloadFromS3Signed(
						newErrorImage.key,
						'erin-images'
					),
				});
			} catch (error_) {
				onError(error_);
			}
		};

		const fileListCompanyFavicon = companyFavicon
			? [
					{
						uid: '-1',
						name: companyFavicon.key,
						status: 'done',
						url: faviconSrc,
					},
				]
			: [];

		const symbolFileList = currentSymbol
			? [
					{
						uid: '-1',
						name: currentSymbol.key,
						status: 'done',
						url: spinnerSrc,
					},
				]
			: [];
		const backgroundFileList = currentBackground
			? [
					{
						uid: '-1',
						name: currentBackground.key,
						status: 'done',
						url: backgroundSrc,
					},
				]
			: [];
		const errorImageFileList = currentErrorImage
			? [
					{
						uid: '-1',
						name: currentErrorImage.key,
						status: 'done',
						url: errorImageSrc,
					},
				]
			: [];
		const toolbarConfig = {
			// Optionally specify the groups to display (displayed in the order listed).
			display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS'],
			INLINE_STYLE_BUTTONS: [
				{ label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
				{ label: 'Italic', style: 'ITALIC' },
			],
			BLOCK_TYPE_BUTTONS: [{ label: 'UL', style: 'unordered-list-item' }],
		};
		const uploadButton = (
			<div>
				{this.state?.loading ? <LoadingOutlined /> : <PlusOutlined />}
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		const selectedDepartmentKeywords = get(
			this.state,
			'selectedDepartment.keywords',
			[]
		);

		const selectedUserGroupKeywords = get(
			this.state,
			'selectedUserGroup.keywords',
			[]
		);
		const multiSelectDefaultValue = [];
		const multiSelectDepartmentDefaultValue = [];

		const selectedUserGroups =
			filteredUserGroups.length > 0 ? filteredUserGroups : [];
		if (selectedUserGroups) {
			selectedUserGroups.map((item) => {
				multiSelectDefaultValue.push({ key: item.id, label: item.label });
			});
		}

		const selectedDepartments =
			filteredDepartments.length > 0 ? filteredDepartments : [];
		if (selectedDepartments) {
			selectedDepartments.map((item) => {
				multiSelectDepartmentDefaultValue.push({
					key: item.id,
					label: item.label,
				});
			});
		}

		const unit =
			get(this.props, 'currentUser.userGroup.measurement', 'imperial') ===
			'metric'
				? 'km'
				: 'mi';
		const Unit =
			unit === 'km'
				? 'km'
				: ml('miles', this.props.currentUser, this.props.allMultiLingualData);
		const Within = capitalizeFirstLetter(
			ml('within', this.props.currentUser, this.props.allMultiLingualData)
		);
		const supportAdmin = parse(get(this.props, 'currentUser.admin'));
		const supportAdminPermissions = get(supportAdmin, 'permissions') === '*';
		const hiddenToolTip = (
			<div>
				<span>Hidden </span>
				<Tooltip
					placement="top"
					title="Selecting 'Hidden' hides linked pages from the left menu."
					style={{ fontWeight: 1000 }}
				>
					<InfoCircleOutlined
						style={{
							cursor: 'pointer',
							color: '#1890ff',
						}}
					/>
				</Tooltip>
			</div>
		);
		const managerPermissionsColumns = [
			{
				title: 'Permission Type',
				dataIndex: 'name',
				key: 'name',
				render: (_, record) => (
					<div>
						{record.name + ' '}
						<Tooltip
							placement="top"
							title={record.message}
							style={{ fontWeight: 1000 }}
						>
							<InfoCircleOutlined
								style={{
									cursor: 'pointer',
									verticalAlign: 'center',
									color: '#1890ff',
								}}
							/>
						</Tooltip>
					</div>
				),
			},
			{
				title: hiddenToolTip,
				dataIndex: 'key',
				key: 'hiddenPermissions',
				render: (key) => (
					<Radio
						checked={
							this.state.disableManagerPermissionsByType[key] === 'hidden'
						}
						onChange={() => this.handleManagerPermissionsByType(key, 'hidden')}
					/>
				),
			},
			{
				title: 'Read-Only',
				dataIndex: 'key',
				key: 'readOnlyPermissions',
				render: (key) => (
					<Radio
						checked={
							this.state.disableManagerPermissionsByType[key] === 'readOnly'
						}
						onChange={() =>
							this.handleManagerPermissionsByType(key, 'readOnly')
						}
					/>
				),
			},
			{
				title: 'Edit',
				dataIndex: 'key',
				key: 'editPermissions',
				render: (key) => (
					<Radio
						checked={this.state.disableManagerPermissionsByType[key] === 'edit'}
						onChange={() => this.handleManagerPermissionsByType(key, 'edit')}
					/>
				),
			},
		];
		const managerPermissionsData = [
			{
				key: 'managerReferrals',
				name: 'Referrals',
				message:
					'Read-Only: View All Referrals. Edit: Modify and delete existing referrals. Neither option will change their ability to make a referral.',
			},
			{
				key: 'managerJobs',
				name: 'Jobs',
				message:
					'Read-Only: View Open Jobs. Edit: Add and modify jobs. Neither option will change their ability to browse jobs.',
			},
			{
				key: 'managerEmployees',
				name: 'Employees',
				message: 'Read-Only: View employee info. Edit: Modify employee info.',
			},
			{
				key: 'managerBonuses',
				name: 'Bonuses',
				message:
					'Read-Only: View bonuses. Edit: Modify bonuses. Neither option grants access to Campaigns or Settings pages.',
			},
		];

		return (
			<div>
				<Form className={FormStyles}>
					{error ? (
						<Alert
							message="There was an error submitting your updates, please try again."
							type="error"
						/>
					) : null}
					{success ? (
						<Alert
							message="Your updates were successfully submitted."
							type="success"
						/>
					) : null}

					<div className="page-title">
						<h2 className="page-heading">
							{ml('Settings', currentUser, allMultiLingualData)}
						</h2>

						<Button
							htmlType="submit"
							type="primary"
							size="large"
							onClick={this.handleSubmit}
						>
							{ml('Update Settings', currentUser, allMultiLingualData)}
						</Button>
					</div>

					<div className="setting-card">
						<div>
							<h4 className="setting-card-title">
								{ml(
									'Dashboard Referral Policy Text',
									currentUser,
									allMultiLingualData
								)}
							</h4>

							<div className={filterGroupSpace}>
								<Radio.Group
									defaultValue="10"
									buttonStyle="solid"
									size="small"
									onChange={this.onChange}
								>
									<Radio.Button value="10">HTML Editor</Radio.Button>
									<Radio.Button value="20">Normal Editor</Radio.Button>
								</Radio.Group>
							</div>
							{this.state.showHTML ? (
								<RichTextEditor
									value={this.state.dashboardReferralPolicyText}
									className="setting-card-editor"
									rootStyle={{ maxWidth: '72.5vw' }}
									onChange={this.onChangeRTE}
								/>
							) : (
								this.newTinyMsc()
							)}
						</div>

						<h4 className="setting-card-title">
							{ml(
								'Custom Pages',
								this.props.currentUser,
								this.props.allMultiLingualData
							)}
						</h4>
						<div className="setting-form-inline">
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="enableCustomPage"
									type="checkbox"
									checked={this.state.enableCustomPage}
									onChange={this.handleCustomPage}
								/>
								<label htmlFor="enableCustomPage">Enable Custom Page 1</label>
							</div>
							<FormItem className="custom-form-group">
								{getFieldDecorator('customPageTitle', {
									initialValue: get(
										this.props,
										'company.customPageTitle',
										null
									),
								})(
									<Input
										className="custom-input"
										placeholder="Custom Page Title"
									/>
								)}
							</FormItem>
						</div>
						<div className="setting-form-inline">
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="enableCustomPage2"
									type="checkbox"
									checked={this.state.enableCustomPage2}
									onChange={this.handleCustomPage2}
								/>
								<label htmlFor="enableCustomPage2">Enable Custom Page 2</label>
							</div>
							<FormItem className="custom-form-group">
								{getFieldDecorator('customPageTitle2', {
									initialValue: get(
										this.props,
										'company.customPageTitle2',
										null
									),
								})(
									<Input
										className="custom-input"
										placeholder="Custom Page Title"
									/>
								)}
							</FormItem>
						</div>
						<div className="setting-form-inline">
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="enableCustomPage3"
									type="checkbox"
									checked={this.state.enableCustomPage3}
									onChange={this.handleCustomPage3}
								/>
								<label htmlFor="enableCustomPage3">Enable Custom Page 3</label>
							</div>
							<FormItem className="custom-form-group">
								{getFieldDecorator('customPageTitle3', {
									initialValue: get(
										this.props,
										'company.customPageTitle3',
										null
									),
								})(
									<Input
										className="custom-input"
										placeholder="Custom Page Title"
									/>
								)}
							</FormItem>
						</div>

						<h4 className="setting-card-title">One Time Popup</h4>
						<FormItem className="custom-form-group">
							{getFieldDecorator('popupTitle', {
								initialValue: company.popupTitle,
								rules: [],
							})(<Input className="custom-input" placeholder="Pop Up Tile" />)}
						</FormItem>
						<RichTextEditor
							toolbarConfig={toolbarConfig}
							value={this.state.popupTitleContent}
							className="setting-card-editor"
							onChange={this.onChangePopupRTE}
						/>
						<div className="custom-form-group custom-checkbox left-check mb-0">
							<input
								id="showAtEveryLogin"
								type="checkbox"
								checked={this.state.resetPopup}
								onChange={this.handleResetPopup}
							/>
							<label htmlFor="showAtEveryLogin">
								{ml(
									'Show at every login',
									this.props.currentUser,
									this.props.allMultiLingualData
								)}
							</label>
						</div>
					</div>
					{role === 'superAdmin' || supportAdminPermissions ? (
						<>
							<h2 className="setting-heading">Licensing</h2>
							<div className="setting-card">
								<h4 className="setting-card-title">
									{ml('Internal Mobility', currentUser, allMultiLingualData)}
								</h4>
								<div className="setting-form-inline">
									<div className="custom-form-group custom-checkbox left-check">
										<input
											id="enabledInternalMobilityPage"
											type="checkbox"
											checked={this.state.allowInternalMobility}
											onChange={this.handleAllowInternalMobility}
										/>
										<label htmlFor="enabledInternalMobilityPage">
											{ml(
												'Enable Internal Mobility Page.',
												currentUser,
												allMultiLingualData
											)}
										</label>
									</div>
									<div className="custom-form-group">
										<FormItem>
											{getFieldDecorator('internalMobilityCustomTitle', {
												initialValue: get(
													this.props,
													'company.internalMobilityCustomTitle',
													null
												),
											})(
												<Input
													className="custom-input"
													placeholder={ml(
														'Enter your title.',
														currentUser,
														allMultiLingualData
													)}
												/>
											)}
										</FormItem>
									</div>
								</div>

								<div className="custom-form-group custom-checkbox left-check">
									<input
										id="allowUsersReferThemselvesByIndicating"
										type="checkbox"
										checked={this.state.allowSelfReferrals}
										onChange={this.handleAllowSelfReferralsChange}
									/>
									<label htmlFor="allowUsersReferThemselvesByIndicating">
										{ml(
											'Allow users to refer themselves by indicating they are interested in a job posting.',
											currentUser,
											allMultiLingualData
										)}
									</label>
								</div>

								{/* Allow user to disable allowSelfReferral for first 7 days a job is created */}
								<div className="custom-form-group custom-checkbox left-check l2">
									<input
										id="internalJobLink"
										type="checkbox"
										disabled={!this.state.allowSelfReferrals}
										checked={this.state.allowSelfReferralsInternalLink}
										onChange={this.handleAllowSelfReferralsInternalLinkChange}
									/>
									<label htmlFor="internalJobLink">
										{
											'Redirect the "I\'m Interested" button to an internal job link'
										}
									</label>
								</div>

								<div className="custom-form-group custom-checkbox left-check">
									<input
										id="enableCareerProfile"
										type="checkbox"
										checked={this.state.enableCareerProfile}
										onChange={this.handleEnableCareerProfile}
									/>
									<label htmlFor="enableCareerProfile">
										Enable Career Profile.
									</label>
								</div>

								{this.state.enableCareerProfile && (
									<div className="custom-form-group custom-checkbox left-check l2">
										<input
											id="enableCareerMatching"
											type="checkbox"
											checked={this.state.enableJobMatching}
											onChange={this.handleEnableJobMatching}
										/>
										<label htmlFor="enableCareerMatching">
											Enable Job Matching.
										</label>
									</div>
								)}
								<h4 className="setting-card-title">Referral Network</h4>
								<div className="custom-form-group custom-checkbox left-check flex-wrap">
									<input
										id="enableReferralNetwork"
										type="checkbox"
										checked={this.state.enableExtendedNetwork}
										onChange={this.handleEnableExtendedNetwork}
									/>
									<label htmlFor="enableReferralNetwork">
										Enable Referral Network.
									</label>
									<p
										className="custom-form-group-note"
										style={{ flex: '0 0 100%' }}
									>
										(Sends an invitation email to new contacts and referrals to
										join your extended network.)
									</p>
								</div>

								{this.state.enableExtendedNetwork && (
									<div className="custom-form-group custom-checkbox left-check l2">
										<input
											id="enableJobMatching"
											type="checkbox"
											checked={this.state.enableJobMatching}
											onChange={this.handleEnableJobMatching}
										/>
										<label htmlFor="enableJobMatching">
											Enable Job Matching.
										</label>
									</div>
								)}
								{this.state.enableExtendedNetwork && (
									<div className="custom-form-group custom-checkbox left-check l2">
										<input
											id="doNotAllowExtendedUsersViewOpenJobs"
											type="checkbox"
											checked={this.state.disableExtendedUserJobsView}
											onChange={this.handleDisableExtendedUserJobsView}
										/>
										<label htmlFor="doNotAllowExtendedUsersViewOpenJobs">
											Do not allow extended users to view open jobs.
										</label>
									</div>
								)}

								{this.state.enableExtendedNetwork && (
									<div className="custom-form-group custom-checkbox left-check l2">
										<input
											id="configureReferralNetworkSharingOptions"
											type="checkbox"
											checked={this.state.externalReferralNetworkShare}
											onChange={() => {
												if (!this.state.externalReferralNetworkShare == false) {
													this.setState({
														...this.state,
														externalReferralNetworkShare:
															!this.state.externalReferralNetworkShare,
														shareEmail: false,
														shareQRCode: false,
													});
												} else {
													this.setState({
														...this.state,
														externalReferralNetworkShare:
															!this.state.externalReferralNetworkShare,
													});
												}
											}}
										/>
										<label htmlFor="configureReferralNetworkSharingOptions">
											Configure referral network sharing options (Select all the
											sharing options you want to appear to employee)
										</label>
									</div>
								)}

								{this.state.enableExtendedNetwork &&
									this.state.externalReferralNetworkShare && (
										<>
											<div className="custom-form-group custom-checkbox left-check l3">
												<input
													id="Email1"
													type="checkbox"
													checked={this.state.shareEmail}
													onChange={() =>
														this.setState({
															...this.state,
															shareEmail: !this.state.shareEmail,
														})
													}
												/>
												<label htmlFor="Email1">Email</label>
											</div>
											<div className="custom-form-group custom-checkbox left-check l3">
												<input
													id="QRCode"
													type="checkbox"
													checked={this.state.shareQRCode}
													onChange={() =>
														this.setState({
															...this.state,
															shareQRCode: !this.state.shareQRCode,
														})
													}
												/>
												<label htmlFor="QRCode">QR code</label>
											</div>
										</>
									)}

								{this.state.enableExtendedNetwork && (
									<div className="custom-form-group custom-checkbox left-check l2">
										<input
											id="disableSmartReferrals"
											type="checkbox"
											checked={this.state.disableSmartReferrals}
											onChange={this.handleDisableSmartReferralChange}
										/>
										<label htmlFor="disableSmartReferrals">
											Disable Smart Referrals.
										</label>
									</div>
								)}
								{this.props.currentUser?.role === 'superAdmin' && (
									<>
										<h4 className="setting-card-title">Recognition Platform</h4>
										<div className="custom-form-group custom-checkbox left-check flex-wrap">
											<input
												id="enableRecognition"
												type="checkbox"
												checked={this.state.enableRecognition}
												onChange={this.handleEnableRecognition}
											/>
											<label htmlFor="enableRecognition">
												Enable Recognition.
											</label>
										</div>
										<h4 className="setting-card-title">White Label</h4>
										<div className="custom-form-group custom-checkbox left-check">
											<input
												id="hideERINBranding"
												type="checkbox"
												checked={this.state.whiteLabel}
												onChange={this.handleWhiteLabelChange}
											/>
											<label htmlFor="hideERINBranding">
												Hide ERIN branding.
											</label>
										</div>
										<h4 className="setting-card-title">Powered By Erin</h4>
										<div className="custom-form-group custom-checkbox left-check">
											<input
												id="showPoweredByErin"
												type="checkbox"
												checked={this.state.showPoweredByErin}
												onChange={this.handleShowPoweredByErin}
											/>
											<label htmlFor="showPoweredByErin">
												Show Powered By Erin
											</label>
										</div>
									</>
								)}

								<h4 className="setting-card-title">
									Company Background Image
									<span style={{ fontSize: 12, color: '#8d99a3' }} />
								</h4>

								<div className="custom-upload-picture">
									<Upload
										showUploadList
										name="background"
										listType="picture-card"
										customRequest={uploadBackground}
										beforeUpload={beforeUpload}
										multiple={false}
										disabled={backgroundFileList.length > 0}
										fileList={backgroundFileList}
										accept=".png"
									>
										{uploadButton}
									</Upload>
									{currentBackground && (
										<Popconfirm
											title="Are you sure？"
											placement="right"
											icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
											onConfirm={() => this.confirmRemove('currentBackground')}
										>
											<span className="cancel-picture-btn">
												<i className="icon-bin" />
												Remove Background
											</span>
										</Popconfirm>
									)}
								</div>

								{get(company, 'whiteLabel') && (
									<>
										<h4 className="setting-card-title">
											Loading Spinner Symbol
										</h4>
										<div className="custom-upload-picture">
											<Upload
												showUploadList
												name="symbol"
												listType="picture-card"
												customRequest={uploadSymbol}
												beforeUpload={beforeUpload}
												multiple={false}
												disabled={symbolFileList.length > 0}
												fileList={symbolFileList}
												accept=".png"
											>
												{uploadButton}
											</Upload>
											{currentSymbol && (
												<Popconfirm
													title="Are you sure？"
													placement="right"
													icon={
														<QuestionCircleOutlined style={{ color: 'red' }} />
													}
													onConfirm={() => this.confirmRemove('currentSymbol')}
												>
													<span className="cancel-picture-btn">
														<i className="icon-bin" />
														Remove Loading Spinner
													</span>
												</Popconfirm>
											)}
										</div>
									</>
								)}

								<h4 className="setting-card-title">
									Error Image
									<span style={{ fontSize: 12, color: '#8d99a3' }} />
								</h4>

								<div className="custom-upload-picture">
									<Upload
										showUploadList
										name="errorImage"
										listType="picture-card"
										customRequest={uploadErrorImage}
										beforeUpload={beforeUpload}
										multiple={false}
										disabled={errorImageFileList.length > 0}
										fileList={errorImageFileList}
										accept=".png"
									>
										{uploadButton}
									</Upload>
									{currentErrorImage && (
										<Popconfirm
											title="Are you sure？"
											placement="right"
											icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
											onConfirm={() => this.confirmRemove('currentErrorImage')}
										>
											<span className="cancel-picture-btn">
												<i className="icon-bin" />
												Remove Error Image
											</span>
										</Popconfirm>
									)}
								</div>

								<h4 className="setting-card-title">Manage URLs</h4>

								<div className="custom-form-group">
									<label className="custom-label">Help & Support:</label>
									<FormItem>
										{getFieldDecorator('helpUrl', {
											initialValue: get(this.props, 'company.helpUrl', null),
										})(
											<Input
												className="custom-input"
												placeholder="Enter Help & Support URL"
											/>
										)}
									</FormItem>
								</div>

								<div className="custom-form-group">
									<label className="custom-label">Privacy Policy:</label>
									<FormItem>
										{getFieldDecorator('privacyUrl', {
											initialValue: get(this.props, 'company.privacyUrl', null),
										})(
											<Input
												className="custom-input"
												placeholder="Enter Privacy Policy URL"
											/>
										)}
									</FormItem>
								</div>

								<div className="custom-form-group">
									<label className="custom-label">Terms of Use:</label>
									<FormItem>
										{getFieldDecorator('termsUrl', {
											initialValue: get(this.props, 'company.termsUrl', null),
										})(
											<Input
												className="custom-input"
												placeholder="Enter Terms of Use URL"
											/>
										)}
									</FormItem>
								</div>

								{get(company, 'whiteLabel') && (
									<>
										<div className="custom-form-group">
											<label className="custom-label">iOS Download:</label>
											<FormItem>
												{getFieldDecorator('ios', {
													initialValue: get(
														this.props,
														'company.appStoreUrls.ios',
														null
													),
												})(
													<Input
														className="custom-input"
														placeholder="Enter IOS Download URL"
													/>
												)}
											</FormItem>
										</div>
										<div className="custom-form-group">
											<label className="custom-label">Android Download:</label>

											<FormItem>
												{getFieldDecorator('android', {
													initialValue: get(
														this.props,
														'company.appStoreUrls.android',
														null
													),
												})(
													<Input
														className="custom-input"
														placeholder="Enter Android Download URL"
													/>
												)}
											</FormItem>
										</div>
									</>
								)}

								<div className="custom-form-group">
									<label className="custom-label">Link:</label>

									<FormItem>
										{getFieldDecorator('linkUrl', {
											initialValue: get(this.props, 'company.linkUrl', null),
										})(
											<Input
												className="custom-input"
												placeholder="Enter Link"
											/>
										)}
									</FormItem>
								</div>

								<div className="custom-form-group">
									<label className="custom-label">Name:</label>

									<FormItem>
										{getFieldDecorator('linkName', {
											initialValue: get(this.props, 'company.linkName', null),
										})(
											<Input
												className="custom-input"
												placeholder="Enter Name"
											/>
										)}
									</FormItem>
								</div>

								{role === 'superAdmin' || supportAdminPermissions ? (
									<div className="custom-form-group">
										<label className="custom-label">
											Referral Service Name
										</label>
										<FormItem>
											{getFieldDecorator('whiteLabelServiceName', {
												initialValue: whiteLabelServiceName
													? whiteLabelServiceName
													: '',
											})(
												<Input
													placeholder="BestCo Referral Service"
													className="custom-input"
												/>
											)}
										</FormItem>
									</div>
								) : null}

								{role === 'superAdmin' || supportAdminPermissions ? (
									<div className="custom-form-group">
										<label className="custom-label">Sender Email Address</label>
										<FormItem>
											{getFieldDecorator('senderEmailAddress', {
												initialValue: senderEmailAddress
													? senderEmailAddress
													: '',
												rules: [
													{
														required: true,
														message: 'Please input sender email address.',
													},
												],
											})(
												<Input
													placeholder="BestCo <noreply@bestco.com>"
													className="custom-input"
												/>
											)}
										</FormItem>
									</div>
								) : null}

								{role === 'superAdmin' || supportAdminPermissions ? (
									<>
										<h4 className="setting-card-title">Disable Site</h4>
										<div className="custom-form-group custom-checkbox left-check">
											<input
												id="disableAccessForAllUsersTheSite"
												type="checkbox"
												checked={this.state.disableSite}
												onChange={this.handleDisableSite}
											/>
											<label htmlFor="disableAccessForAllUsersTheSite">
												Disable access for all users of the site
											</label>
										</div>
									</>
								) : null}

								{role === 'superAdmin' || supportAdminPermissions ? (
									<div className="custom-form-group">
										<label className="custom-label">
											Label for Claim Your Account
										</label>
										<FormItem>
											{getFieldDecorator('labelEmployeeID', {
												initialValue: labelEmployeeID ? labelEmployeeID : '',
												rules: [],
											})(
												<Input
													placeholder="Employee ID"
													className="custom-input"
												/>
											)}
										</FormItem>
									</div>
								) : null}

								{role === 'superAdmin' ? (
									<>
										<h4 className="setting-card-title">Favicon</h4>
										<div className="custom-upload-picture">
											<Upload
												name="favicon"
												listType="picture-card"
												showUploadList
												// Custom request uploads the image to s3, sets to state, then filelist in render converts that logo
												// to enable it to display in antD upload component through url
												customRequest={uploadFavicon}
												beforeUpload={beforeUploadFavicon}
												multiple={false}
												disabled={fileListCompanyFavicon.length > 0}
												fileList={fileListCompanyFavicon}
											>
												<div className="upload-picture-btn">
													<i className="icon-plus" />
													<p className="upload-picture-text">
														{ml('Upload', currentUser, allMultiLingualData)}
													</p>
												</div>
											</Upload>
											{companyFavicon && (
												<Popconfirm
													title={ml(
														'Are you sure？',
														currentUser,
														allMultiLingualData
													)}
													placement="right"
													icon={
														<QuestionCircleOutlined style={{ color: 'red' }} />
													}
													onConfirm={() =>
														this.setState({
															companyFavicon: null,
															companyFaviconChanged: true,
														})
													}
												>
													<span className="cancel-picture-btn">
														<i className="icon-bin" />
														{ml('Remove', currentUser, allMultiLingualData)}
													</span>
												</Popconfirm>
											)}
										</div>
										<div className="custom-form-group">
											<label className="custom-label">
												{ml('Website Title', currentUser, allMultiLingualData)}
											</label>
											<FormItem>
												{getFieldDecorator('faviconTitle', {
													initialValue:
														company.faviconTitle ??
														(this.state.whiteLabel
															? null
															: 'ERIN | Employee Referral Portal'),
													rules: [],
												})(<Input className="custom-input" />)}
											</FormItem>
										</div>
									</>
								) : null}
							</div>

							<h2 className="setting-heading">
								{ml('Login Settings', currentUser, allMultiLingualData)}
							</h2>

							<div className="setting-card">
								<h4 className="setting-card-title">SAML Login</h4>
								<div className="custom-form-group custom-checkbox left-check">
									<input
										id="disableSAMLLogin"
										type="checkbox"
										checked={this.state.disableSAMLLogin}
										onChange={this.handleDisableSAMLLogin}
									/>
									<label htmlFor="disableSAMLLogin">
										{ml(
											'Disable SAML Login.',
											currentUser,
											allMultiLingualData
										)}
									</label>
								</div>

								<h4 className="setting-card-title">Hide Login Form</h4>
								<div className="custom-form-group custom-checkbox left-check">
									<input
										id="hideLoginFormOnApp"
										type="checkbox"
										checked={this.state.hideLoginForm}
										onChange={this.handleHideLoginForm}
									/>
									<label htmlFor="hideLoginFormOnApp">
										Hide login form on app ("Create An Account" must also be
										DISABLED to hide form)
									</label>
								</div>

								<h4 className="setting-card-title">
									{ml(
										'Claim Your Account Login',
										currentUser,
										allMultiLingualData
									)}
								</h4>
								<div className="custom-form-group custom-checkbox left-check">
									<input
										id="disableClaimYourAccountLogin"
										type="checkbox"
										checked={this.state.disableClaimYourAccountLogin}
										onChange={this.handleDisableClaimYourAccountLogin}
									/>
									<label htmlFor="disableClaimYourAccountLogin">
										{ml(
											'Disable Claim Your Account Login.',
											currentUser,
											allMultiLingualData
										)}
									</label>
								</div>

								<h4 className="setting-card-title">External User Sign Up</h4>
								<div className="custom-form-group custom-checkbox left-check">
									<input
										id="createAnAccount"
										type="checkbox"
										checked={this.state.externalUserSignUp}
										onChange={this.handleExternalUserSignUp}
									/>
									<label htmlFor="createAnAccount">Create an Account</label>
								</div>

								<h4 className="setting-card-title">App Review</h4>
								<div className="custom-form-group custom-checkbox left-check">
									<input
										id="appReview"
										type="checkbox"
										checked={this.state.enableAppReview}
										onChange={this.handleAppReview}
									/>
									<label htmlFor="appReview">Enable App Review</label>
								</div>

								<h4 className="setting-card-title">
									{ml(
										'Google Login Email Domain',
										currentUser,
										allMultiLingualData
									)}
								</h4>
								<FormItem className="custom-form-group">
									{getFieldDecorator('ssoGoogleDomain', {
										initialValue: get(
											this.props,
											'company.ssoGoogleDomain',
											null
										),
									})(
										<Input className="custom-input" placeholder="gmail.com" />
									)}
								</FormItem>

								<h4 className="setting-card-title">Login Screen Shading</h4>
								<div className="custom-form-group custom-checkbox left-check">
									<input
										id="hideLoginScreenShading"
										type="checkbox"
										checked={
											this.state.whiteLabelSettings.hideLoginScreenShading
										}
										onChange={this.handleHideLoginScreenShading}
									/>
									<label htmlFor="hideLoginScreenShading">
										Hide Login Screen Shading
									</label>
								</div>
							</div>

							<h2 className="setting-heading">Themes</h2>

							<div className="setting-card">
								<h4 className="setting-card-title">Theme Options</h4>
								<div className="custom-form-group custom-checkbox left-check">
									<input
										id="enableCustomColorThemes"
										type="checkbox"
										checked={this.state.theme.enabled}
										onChange={() => {
											const { theme } = this.state;
											theme.enabled = !theme.enabled;
											this.setState({ theme });
										}}
									/>
									<label htmlFor="enableCustomColorThemes">
										{ml(
											'Enable custom color themes.',
											currentUser,
											allMultiLingualData
										)}
									</label>
								</div>

								{this.state.theme.enabled && (
									<>
										<div className="custom-form-group">
											<label className="custom-label">
												{ml(
													'Navigation Menu Color:',
													currentUser,
													allMultiLingualData
												)}
											</label>
											<FormItem>
												<Input
													placeholder="ex: #ffffff"
													value={this.state.theme.menuColor}
													className="custom-input"
													onChange={(e) => {
														this.handleThemeChange(e, 'menuColor');
													}}
												/>
												{themeError.menuColor && (
													<span className="input-error">
														{ml(
															'Please enter the color in the format',
															currentUser,
															allMultiLingualData
														)}
														: #cccccc
													</span>
												)}
											</FormItem>
										</div>
										<div className="custom-form-group">
											<label className="custom-label">
												{ml(
													'Navigation Menu Highlight Color',
													currentUser,
													allMultiLingualData
												)}
												:
											</label>
											<FormItem>
												<Input
													placeholder="ex: #ffffff"
													value={this.state.theme.menuHighlightColor}
													className="custom-input"
													onChange={(e) =>
														this.handleThemeChange(e, 'menuHighlightColor')
													}
												/>
												{themeError.menuHighlightColor && (
													<span className="input-error">
														{ml(
															'Please enter the color in the format',
															currentUser,
															allMultiLingualData
														)}
														: #cccccc
													</span>
												)}
											</FormItem>
										</div>

										<div className="custom-form-group">
											<label className="custom-label">
												{ml(
													'Primary Button Color',
													currentUser,
													allMultiLingualData
												)}
												:
											</label>
											<FormItem>
												<Input
													placeholder="ex: #ffffff"
													value={this.state.theme.buttonColor}
													className="custom-input"
													onChange={(e) =>
														this.handleThemeChange(e, 'buttonColor')
													}
												/>
												{themeError.buttonColor && (
													<span className="input-error">
														{ml(
															'Please enter the color in the format',
															currentUser,
															allMultiLingualData
														)}
														: #cccccc
													</span>
												)}
											</FormItem>
										</div>

										<div className="custom-form-group">
											<label className="custom-label">
												{ml(
													'Add Button Color',
													currentUser,
													allMultiLingualData
												)}
												:
											</label>
											<FormItem>
												<Input
													placeholder="ex: #ffffff"
													value={this.state.theme.addButtonColor}
													className="custom-input"
													onChange={(e) =>
														this.handleThemeChange(e, 'addButtonColor')
													}
												/>
												{themeError.addButtonColor && (
													<span className="input-error">
														{ml(
															'Please enter the color in the format',
															currentUser,
															allMultiLingualData
														)}
														: #cccccc
													</span>
												)}
											</FormItem>
										</div>

										<div className="custom-form-group">
											<label className="custom-label">
												{ml(
													'Job Card Title Color',
													currentUser,
													allMultiLingualData
												)}
												:
											</label>
											<FormItem>
												<Input
													placeholder="ex: #ffffff"
													value={get(
														this.state,
														'theme.jobCardTitleColor',
														'#ffffff'
													)}
													className="custom-input"
													onChange={(e) => {
														this.handleThemeChange(e, 'jobCardTitleColor');
													}}
												/>
												{themeError.jobCardTitleColor && (
													<span className="input-error">
														{ml(
															'Please enter the color in the format',
															currentUser,
															allMultiLingualData
														)}
														: #cccccc
													</span>
												)}
											</FormItem>
										</div>
									</>
								)}
							</div>
						</>
					) : null}

					<>
						<h2 className="setting-heading">
							{ml('Employee Data', currentUser, allMultiLingualData)}
						</h2>
						<div className="setting-card">
							<h4 className="setting-card-title">
								{ml('Manage Departments', currentUser, allMultiLingualData)}
							</h4>

							<div className="custom-form-group">
								<label className="custom-label">
									{ml('Departments', currentUser, allMultiLingualData)}:
								</label>
								<FormItem>
									{getFieldDecorator('departmentName')(
										<Input
											className="custom-input"
											placeholder={ml(
												'Enter a new department',
												currentUser,
												allMultiLingualData
											)}
										/>
									)}
								</FormItem>
							</div>

							{duplicateDeptError ? (
								<Alert
									message={ml(
										'This department already exists.',
										currentUser,
										allMultiLingualData
									)}
									type="error"
								/>
							) : null}

							<Collapse
								defaultActiveKey="1"
								className="custom-collapse-wrapper"
								bordered={false}
							>
								<Panel
									key="1"
									className="custom-collapse-panel"
									header={
										<>
											<i className="icon-arrow-down custom-collapse-icon" />
											<h4>
												{ml(
													'View or Delete Departments',
													currentUser,
													allMultiLingualData
												)}
											</h4>
										</>
									}
								>
									<DepartmentItem
										departments={departments.filter(
											(department) => department.active
										)}
										handleDepartmentError={handleDepartmentError}
										onUpdateDepartment={this.props.onUpdateDepartment}
									/>
								</Panel>
							</Collapse>
							{departmentError ? (
								<Alert
									message="You can not delete department 'Other'."
									type="error"
								/>
							) : null}

							<h4 className="setting-card-title">Department Settings </h4>
							<div className="custom-form-group">
								<label className="custom-label">Select Department: </label>
								<Select
									showSearch
									className="custom-input"
									placeholder="None"
									onSelect={(department) =>
										this.handleFilterDepartment(department)
									}
								>
									{(departments || [])
										.filter((department) => department.active)
										.map((department) => {
											return (
												<Option key={department.id} value={department.id}>
													{department.name}
												</Option>
											);
										})}
								</Select>
							</div>

							{this.state.selectedDepartment && (
								<>
									<h4 className="setting-card-title">
										{get(this.state, 'selectedDepartment.name', '')}
									</h4>
									<>
										<>
											<h4 className="setting-card-title">
												Department Keywords
											</h4>
											<div className="custom-form-group">
												<br />
												<label className="custom-label">
													Enter Department Keyword:
												</label>
												<Form.Item>
													{getFieldDecorator('departmentKeywordField', {
														initialValue:
															this.state.departmentKeywordField || '',
													})(
														<Input
															className="custom-input"
															placeholder="Enter Keyword"
														/>
													)}
												</Form.Item>

												<Button
													type="link"
													size="small"
													className="add-btn ml-2"
													onClick={() =>
														this.handleCreateDepartmentKeyword(
															getFieldValue('departmentKeywordField')
														)
													}
												>
													<span className="icon-circle">
														<i className="icon-plus" />
													</span>
													Add Keyword
												</Button>
											</div>
										</>
										<div className={FormItemContainer}>
											<ListItems
												data={selectedDepartmentKeywords}
												deleteItem={this.handleDeleteDepartmentKeyword}
											/>
										</div>
									</>
								</>
							)}

							<h4 className="setting-card-title">
								{ml('Employee Group', currentUser, allMultiLingualData)}
							</h4>

							<div className="custom-form-group">
								<label className="custom-label">
									{ml('Type:', currentUser, allMultiLingualData)}
								</label>
								<FormItem>
									{getFieldDecorator('userGroupName')(
										<Input
											className="custom-input"
											placeholder={ml(
												'Enter a new employee group',
												currentUser,
												allMultiLingualData
											)}
										/>
									)}
								</FormItem>
							</div>

							{duplicateUserGroupError ? (
								<Alert
									message="This employee group already exists."
									type="error"
								/>
							) : null}

							<Collapse
								defaultActiveKey="2"
								className="custom-collapse-wrapper"
								bordered={false}
							>
								<Panel
									key="2"
									className="custom-collapse-panel"
									header={
										<>
											<i className="icon-arrow-down custom-collapse-icon" />
											<h4>
												{ml(
													'View or Delete Employee groups',
													currentUser,
													allMultiLingualData
												)}
											</h4>
										</>
									}
								>
									<UserGroupItem
										userGroups={userGroups}
										handleUserGroupError={handleUserGroupError}
										onUpdateUserGroup={this.props.onUpdateUserGroup}
									/>
								</Panel>
							</Collapse>
							{userGroupError ? (
								<Alert
									message="You can not delete employee group 'Default'."
									type="error"
								/>
							) : null}

							<h4 className="setting-card-title">
								{'Employee Group Settings'}{' '}
							</h4>
							<div className="custom-form-group">
								<label className="custom-label">Select Employee Group:</label>
								<Select
									showSearch
									className="custom-input"
									placeholder="None"
									onSelect={(group) => this.handleFilterUserGroup(group)}
								>
									{(userGroups || []).map((group) => {
										return (
											<Option key={group.id} value={group.id}>
												{group.name}
											</Option>
										);
									})}
								</Select>
							</div>

							{this.state.selectedUserGroup && (
								<>
									<h4 className="setting-card-title">
										{get(this.state, 'selectedUserGroup.name', '')}
									</h4>
									<h4 className="setting-card-title">
										Employee Group Keywords
									</h4>
									<div className="custom-form-group">
										<label className="custom-label">
											Enter Employee Group Keyword:
										</label>
										<Form.Item>
											{getFieldDecorator('userGroupKeywordField', {
												initialValue: this.state.userGroupKeywordField || '',
											})(
												<Input
													className="custom-input"
													placeholder="Enter Keyword"
												/>
											)}
										</Form.Item>
										<Button
											type="link"
											size="small"
											className="add-btn ml-2"
											onClick={() =>
												this.handleCreateUserGroupKeyword(
													getFieldValue('userGroupKeywordField')
												)
											}
										>
											<span className="icon-circle">
												<i className="icon-plus" />
											</span>
											Add Keyword
										</Button>
									</div>
									<div className={FormItemContainer}>
										<ListItems
											data={selectedUserGroupKeywords}
											deleteItem={this.handleDeleteUserGroupKeyword}
										/>
									</div>
								</>
							)}

							<h4 className="setting-card-title">
								{ml(
									'Custom Authentication Picker',
									currentUser,
									allMultiLingualData
								)}
							</h4>

							<div className="custom-form-group">
								<label className="custom-label">
									{ml('Type:', currentUser, allMultiLingualData)}
								</label>
								<FormItem>
									{getFieldDecorator('loginPicker')(
										<Input
											className="custom-input"
											placeholder={ml(
												'Enter a new picker title.',
												currentUser,
												allMultiLingualData
											)}
										/>
									)}
								</FormItem>
							</div>

							{duplicateUserGroupError ? (
								<Alert message="This picker already exists." type="error" />
							) : null}

							<Collapse
								defaultActiveKey="3"
								className="custom-collapse-wrapper"
								bordered={false}
							>
								<Panel
									key="3"
									className="custom-collapse-panel"
									header={
										<>
											<i className="icon-arrow-down custom-collapse-icon" />
											<h4>
												{ml(
													'View or Delete Custom Pickers',
													currentUser,
													allMultiLingualData
												)}
											</h4>
										</>
									}
								>
									<CustomPickerList
										handleDelete={this.handleDeletePicker}
										data={loginFormPickerSettings}
									/>
								</Panel>
							</Collapse>

							<h4 className="setting-card-title">Picker Settings </h4>
							<div className="custom-form-group">
								<label className="custom-label">Select Picker Item:</label>
								<Select
									showSearch
									className="custom-input"
									placeholder="None"
									onSelect={(picker) => this.handleFilterPickerSettings(picker)}
								>
									{(loginFormPickerSettings || []).map((picker) => {
										return (
											<Option key={picker.id} value={picker.name}>
												{picker.name}
											</Option>
										);
									})}
								</Select>
							</div>

							{this.state.selectedPickerSetting && (
								<>
									<h4 className="setting-card-title">
										{get(this.state, 'selectedPickerSetting.name', '')}
									</h4>
									<h4 className="setting-card-title">Picker Data:</h4>
									<div className="custom-form-group">
										<label className="custom-label">Picker Values:</label>
										<Form.Item>
											{getFieldDecorator('pickerValuesField', {
												initialValue: this.state.userGroupKeywordField || '',
											})(
												<Input
													className="custom-input"
													placeholder="Enter Value"
												/>
											)}
										</Form.Item>

										<Button
											type="link"
											size="small"
											className="add-btn ml-2"
											onClick={() =>
												this.handleCreatePickerValues(
													getFieldValue('pickerValuesField'),
													get(this.state, 'selectedPickerSetting')
												)
											}
										>
											<span className="icon-circle">
												<i className="icon-plus" />
											</span>
											Add Value
										</Button>
									</div>
									<ListItems
										data={get(this.state, 'selectedPickerSetting.values')}
										deleteItem={this.handleDeletePickerValues}
									/>
									<div className="custom-form-group">
										<label className="custom-label">Name:</label>
										<Form.Item>
											{getFieldDecorator('pickerName', {
												initialValue:
													get(this.state, 'selectedPickerSetting.name') || '',
											})(
												<Input
													className="custom-input"
													placeholder="Enter Name"
												/>
											)}
										</Form.Item>
									</div>
									<div className="custom-form-group custom-checkbox left-check">
										<Form.Item>
											{getFieldDecorator(
												'pickerRequired',
												{}
											)(
												<>
													<input
														id="fieldIsRrequired"
														type="checkbox"
														checked={get(
															this.state,
															'selectedPickerSetting.required'
														)}
														onChange={this.handlePickerRequiredChange}
													/>
													<label htmlFor="fieldIsRrequired">
														{ml(
															'This field is required.',
															currentUser,
															allMultiLingualData
														)}
													</label>
												</>
											)}
										</Form.Item>
									</div>
									<div className="custom-form-group">
										<label className="custom-label">Error Message:</label>
										<Form.Item>
											{getFieldDecorator('pickerError', {
												initialValue:
													get(this.state, 'selectedPickerSetting.error') || '',
											})(
												<Input
													className="custom-input"
													placeholder="Enter Error Message"
												/>
											)}
										</Form.Item>
									</div>
									<div className="custom-form-group">
										<label className="custom-label">Placeholder Message:</label>
										<Form.Item>
											{getFieldDecorator('pickerPlaceholder', {
												initialValue:
													get(
														this.state,
														'selectedPickerSetting.placeholder'
													) || '',
											})(
												<Input
													className="custom-input"
													placeholder="Enter Placeholder Message"
												/>
											)}
										</Form.Item>
									</div>
									<div className="custom-form-group">
										<label className="custom-label">User Attribute:</label>
										<Form.Item>
											{getFieldDecorator('pickerUserAttribute', {
												initialValue:
													get(
														this.state,
														'selectedPickerSetting.userAttribute'
													) || '',
											})(
												<Input
													className="custom-input"
													placeholder="Enter User Attribute"
												/>
											)}
										</Form.Item>
									</div>
								</>
							)}

							<h4 className="setting-card-title">
								{ml(
									'Employee Profile Options',
									currentUser,
									allMultiLingualData
								)}
							</h4>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="disableEmployeeEditableFields"
									type="checkbox"
									checked={this.state.disableEmployeeEditableFields}
									onChange={this.handleDisableEmployeeEditableFields}
								/>
								<label htmlFor="disableEmployeeEditableFields">
									{ml(
										'Prevent employees from editing their profile fields',
										currentUser,
										allMultiLingualData
									)}
								</label>
							</div>

							{this.state.disableEmployeeEditableFields && (
								<>
									<div className="custom-form-group custom-checkbox left-check l2">
										<input
											id="employeeName"
											type="checkbox"
											checked={
												this.state.userEditSettings.lockProfileFields
													.employeeName
											}
											onChange={this.handleLockSpecificField}
										/>
										<label htmlFor="employeeName">Lock Name Field</label>
									</div>
									<div className="custom-form-group custom-checkbox left-check l2">
										<input
											id="employeeDepartment"
											type="checkbox"
											checked={
												this.state.userEditSettings.lockProfileFields
													.employeeDepartment
											}
											onChange={this.handleLockSpecificField}
										/>
										<label htmlFor="employeeDepartment">
											Lock{' '}
											{get(this.props, 'company.labelDepartment', 'Department')}{' '}
											Field
										</label>
									</div>
									<div className="custom-form-group custom-checkbox left-check l2">
										<input
											id="employeeJobTitle"
											type="checkbox"
											checked={
												this.state.userEditSettings.lockProfileFields
													.employeeJobTitle
											}
											onChange={this.handleLockSpecificField}
										/>
										<label htmlFor="employeeJobTitle">
											Lock Job Title Field
										</label>
									</div>
									<div className="custom-form-group custom-checkbox left-check l2">
										<input
											id="employeeLocation"
											type="checkbox"
											checked={
												this.state.userEditSettings.lockProfileFields
													.employeeLocation
											}
											onChange={this.handleLockSpecificField}
										/>
										<label htmlFor="employeeLocation">
											Lock Location Field
										</label>
									</div>
								</>
							)}

							<h4 className="setting-card-title">
								{ml('Compliance', currentUser, allMultiLingualData)}
							</h4>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="enableGDPRCompliant"
									type="checkbox"
									checked={this.state.confirmCompliance}
									onChange={this.handleConfirmCompliance}
								/>
								<label htmlFor="enableGDPRCompliant">
									{ml(
										'Enable GDPR Compliant Referrals',
										currentUser,
										allMultiLingualData
									)}
								</label>
							</div>
							<h4 className="setting-card-title">
								{ml('General Referrals', currentUser, allMultiLingualData)}
							</h4>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="allowUsersMakeGeneralReferrals"
									type="checkbox"
									checked={this.state.enableGeneralReferrals}
									onChange={this.handleGeneralReferrals}
								/>
								<label htmlFor="allowUsersMakeGeneralReferrals">
									{ml(
										'Allow users to make general referrals',
										currentUser,
										allMultiLingualData
									)}
								</label>
							</div>
							<Form>
								<h4 className="setting-card-title">
									{ml('Active Employee Feed', currentUser, allMultiLingualData)}
								</h4>
								<div className="custom-form-group custom-checkbox left-check">
									<input
										id="activeEmployeeFeed"
										type="checkbox"
										checked={allowUserLoginWhenClaimInactive}
										onChange={this.handleAllowUserLoginWhenClaimInactive}
									/>
									<label htmlFor="activeEmployeeFeed">
										{ml(
											'Allow users to log in if they are not included in the active employee feed.',
											currentUser,
											allMultiLingualData
										)}
									</label>
								</div>
							</Form>
							<Form>
								<div className="custom-form-group custom-checkbox left-check">
									<input
										id="sendEmailInvitationForYourEmployees"
										type="checkbox"
										checked={sendOnboardingInviteEmails}
										onChange={this.handleEnableOnboardingEmails}
									/>
									<label htmlFor="sendEmailInvitationForYourEmployees">
										Send an email invitation for your employees to sign up when
										they are added to the active employee feed with an email
										address.
									</label>
								</div>
								{sendOnboardingInviteEmails && (
									<>
										<h4 className="setting-card-title">
											You may schedule follow up reminder emails if the employee
											has not yet created an account.
										</h4>
										<div className="custom-input-group">
											<label className="custom-label">
												Send Reminder After:
											</label>
											<InputNumber
												className="custom-input"
												placeholder={ml(
													'Days',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												min={0}
												onChange={(onboardingReminderDays) =>
													this.setState({
														onboardingReminderDays,
													})
												}
											/>
											<span className="mx-2">
												Days of the first invitation.
											</span>
											<Button
												type="link"
												className="btn-link"
												onClick={this.addFollowUpEmail}
											>
												Add Follow Up Invitation
											</Button>
										</div>
										<>{this.renderOnboardingEmails()}</>
									</>
								)}
							</Form>
							<h4 className="setting-card-title">
								{ml(
									'Public Leaderboard',
									this.props.currentUser,
									this.props.allMultiLingualData
								)}
							</h4>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="displayPublicLeaderboard"
									type="checkbox"
									checked={this.state.publicLeaderboard}
									onChange={this.handleLeaderboard}
								/>
								<label htmlFor="displayPublicLeaderboard">
									{ml(
										'Display a public leaderboard to all employees',
										currentUser,
										allMultiLingualData
									)}
								</label>
							</div>
							<h4 className="setting-card-title">
								{ml(
									'Referrals Ranking',
									this.props.currentUser,
									this.props.allMultiLingualData
								)}
							</h4>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="hideReferralsRanking"
									type="checkbox"
									checked={this.state.hideReferralsRanking}
									onChange={this.handleReferralRanking}
								/>
								<label htmlFor="hideReferralsRanking">
									{ml(
										'Hide referrals ranking to all employees',
										currentUser,
										allMultiLingualData
									)}
								</label>
							</div>
							<h4 className="setting-card-title">Referral Status Label</h4>
							<div className="custom-form-group">
								<label className="custom-label">
									The name of the middle status for referrals, after Accepted
									and before Hired :
								</label>
								<FormItem>
									{getFieldDecorator('referralStatus', {
										initialValue: get(
											this.props,
											'company.referralStatus',
											'Interviewing'
										),
										rules: [
											{
												required: true,
												message: 'Please input referral status.',
											},
											{
												max: 15,
												message:
													'Referral status can not be more than 15 characters.',
											},
										],
									})(<Input className="custom-input" />)}
								</FormItem>
							</div>
							<h4 className="setting-card-title">Manager Permissions</h4>
							<Table
								columns={managerPermissionsColumns}
								dataSource={managerPermissionsData}
								pagination={false}
							/>
							<div className="custom-form-group">
								<Button
									type="primary"
									size="small"
									style={{ marginTop: '16px', cursor: 'pointer' }}
									onClick={this.setManagerPermissionsToDefault}
								>
									{ml(
										'Set to Default Settings',
										currentUser,
										allMultiLingualData
									)}
								</Button>
							</div>
							<h4 className="setting-card-title">Create Prospect</h4>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="enableProspectCreation"
									type="checkbox"
									checked={this.state.enableProspectCreation}
									onChange={this.handleProspect}
								/>
								<label htmlFor="enableProspectCreation">
									Enable Prospect Creation
								</label>
							</div>
							<h4 className="setting-card-title">Hide Jobs Page</h4>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="hideJobsPageFromEmployees"
									type="checkbox"
									checked={this.state.hideJobsPage}
									onChange={this.handleHideJobs}
								/>
								<label htmlFor="hideJobsPageFromEmployees">
									{'Hide Jobs Page from Employees '}
									<em>(Also hides Employee Dashboard Search)</em>
								</label>
							</div>
							<h4 className="setting-card-title">Disable Share Link</h4>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="disableShareLink"
									type="checkbox"
									checked={this.state.disableShareLink}
									onChange={this.handleDisableShareLink}
								/>
								<label htmlFor="disableShareLink">Disable Share Link</label>
							</div>
							<h4 className="setting-card-title">
								Disable Share Link If No Public Job URL
							</h4>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="disableShareLinkIfNoPublicJobURL"
									type="checkbox"
									checked={this.state.hideShareLinkNoPublicUrl}
									onChange={this.handleDisableShareLinkIfNoPublicUrl}
								/>
								<label htmlFor="disableShareLinkIfNoPublicJobURL">
									Disable Share Link If No Public Job URL
								</label>
							</div>
							<h4 className="setting-card-title">Hide Refer Someone Button</h4>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="hideReferSomeoneButtonWhenApplyURLNotAvailable"
									type="checkbox"
									checked={this.state.disableReferrals}
									onChange={this.handleDisableReferrals}
								/>
								<label htmlFor="hideReferSomeoneButtonWhenApplyURLNotAvailable">
									Hide refer someone button when Apply URL is not available
								</label>
							</div>
							<h4 className="setting-card-title">Hide Bonuses</h4>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="hideMyBonusesPageAndBonusTotals"
									type="checkbox"
									checked={this.state.hideBonus}
									onChange={this.handleHideBonus}
								/>
								<label htmlFor="hideMyBonusesPageAndBonusTotals">
									Hide My Bonuses page and bonus totals on the employee
									Dashboard
								</label>
							</div>
							<h4 className="setting-card-title">Hide Date Created</h4>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="hideDateCreatedBrowseJobs"
									type="checkbox"
									checked={this.state.hideDateCreatedJobDetails}
									onChange={(event) =>
										this.setState({
											hideDateCreatedJobDetails: event.target.checked,
										})
									}
								/>
								<label htmlFor="hideDateCreatedBrowseJobs">
									Hide Date Created Browse Jobs &gt; Job Details
								</label>
							</div>
							<h4 className="setting-card-title">Include Admin in Ranking</h4>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="includeAdminUsersPointsAndRankings"
									type="checkbox"
									checked={this.state.includeAdminInRanking}
									onChange={this.handleAdminInRanking}
								/>
								<label htmlFor="includeAdminUsersPointsAndRankings">
									Include Admin Users in Points and Rankings
								</label>
							</div>
							<h4 className="setting-card-title">Subcompany Filter Label</h4>
							<div className="custom-form-group">
								<label className="custom-label">
									The label to display for the Company filter:
								</label>

								<FormItem>
									{getFieldDecorator('subCompanyLabel', {
										initialValue: get(
											this.props,
											'company.subCompanyLabel',
											'Company'
										)
											? get(this.props, 'company.subCompanyLabel', 'Company')
											: 'Company',
										rules: [
											{
												required: true,
												message: 'Please input company label.',
											},
											{
												max: 15,
												message:
													'Company label can not be more than 15 characters.',
											},
										],
									})(<Input className="custom-input" />)}
								</FormItem>
							</div>
							<h4 className="setting-card-title">
								Hide I'm Interested based on group
							</h4>
							<div className="custom-form-group">
								<label className="custom-label">Select Employee Group:</label>
								<div className="custom-form-green-select">
									<Select
										showSearch
										labelInValue
										className="custom-input"
										filterOption={(input, option) => {
											return option.props.children
												.toLowerCase()
												.includes(input.toLowerCase());
										}}
										defaultValue={multiSelectDefaultValue}
										placeholder="None"
										mode="multiple"
										onSelect={(group, { key }) =>
											this.handleHideUserGroup(group, key)
										}
										onDeselect={(group, { key }) =>
											this.handleRemoveHideUserGroup(group, key)
										}
									>
										{(userGroups || []).map((group) => {
											return (
												<Option key={group.id} value={group.name}>
													{group.name}
												</Option>
											);
										})}
									</Select>
								</div>
							</div>
							<h4 className="setting-card-title">
								Hide Share Link based on Department
							</h4>
							<div className="custom-form-group">
								<label className="custom-label">Select Department:</label>
								<div className="custom-form-green-select">
									<Select
										showSearch
										labelInValue
										className="custom-input"
										filterOption={(input, option) => {
											return option.props.children
												.toLowerCase()
												.includes(input.toLowerCase());
										}}
										defaultValue={multiSelectDepartmentDefaultValue}
										placeholder="None"
										mode="multiple"
										onSelect={(group, { key }) =>
											this.handleHideDepartment(group, key)
										}
										onDeselect={(group, { key }) =>
											this.handleRemoveHideDepartment(group, key)
										}
									>
										{(departments || []).map((department) => {
											return (
												<Option key={department.id} value={department.name}>
													{department.name}
												</Option>
											);
										})}
									</Select>
								</div>
							</div>

							<CountrySelect
								ref={this.countryCodeRef}
								defaultCountry={currentUser?.company?.countryCode ?? 'US'}
								currentUser={currentUser}
								allMultiLingualData={allMultiLingualData}
							/>

							<h4 className="setting-card-title">Default Job Filters</h4>
							<div className="custom-form-group">
								<label className="custom-label">Select Location: </label>
								<Select
									className="custom-input"
									defaultValue={this.state.defaultLocation}
									placeholder="Location"
									onSelect={(value) => this.handleLocationFilter(value)}
								>
									<Select.Option key={0} value="0">
										{ml(
											'All Locations',
											this.props.currentUser,
											this.props.allMultiLingualData
										)}
									</Select.Option>
									<Select.Option key={1} value="US">
										{ml(
											'My Country',
											this.props.currentUser,
											this.props.allMultiLingualData
										)}
									</Select.Option>
									<Select.Option key={2} value="-1">
										{ml(
											'Remote',
											this.props.currentUser,
											this.props.allMultiLingualData
										)}
									</Select.Option>
									<Select.Option key={3} value="25">
										{Within} 25 {Unit}
									</Select.Option>
									<Select.Option key={4} value="50">
										{Within} 50 {Unit}
									</Select.Option>
									<Select.Option key={5} value="100">
										{Within} 100 {Unit}
									</Select.Option>
									<Select.Option key={6} value="200">
										{Within} 200 {Unit}
									</Select.Option>
								</Select>
							</div>
							<h4 className="setting-card-title">Default Jobs by SubCompany</h4>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="defaultEmployeeSubcompany"
									type="checkbox"
									checked={this.state.defaultToSubcompany}
									onChange={this.handleDefaultToSubcompany}
								/>
								<label htmlFor="defaultEmployeeSubcompany">
									Default to Employee Subcompany
								</label>
							</div>
							<h4 className="setting-card-title">
								{ml(
									'Hide WhatsApp',
									this.props.currentUser,
									this.props.allMultiLingualData
								)}
							</h4>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="hideWhatsAppFromShareMyReferralLink"
									type="checkbox"
									checked={this.state.hideWhatsApp}
									onChange={this.handleWhatsApp}
								/>
								<label htmlFor="hideWhatsAppFromShareMyReferralLink">
									Hide WhatsApp from Share My Referral Link
								</label>
							</div>
							<h4 className="setting-card-title">
								{ml(
									'Hide Recruiter Info',
									this.props.currentUser,
									this.props.allMultiLingualData
								)}
							</h4>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="hideRecruiterNameOrEmail"
									type="checkbox"
									checked={this.state.hideRecruiterInfo}
									onChange={this.handleRecruiterInfo}
								/>
								<label htmlFor="hideRecruiterNameOrEmail">
									Hide recruiter name or email on new referral acceptance page
								</label>
							</div>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="hideRecruiterNameEmailOnTheJobDetailsPage"
									type="checkbox"
									checked={this.state.hideRecruiterInfoJobDetails}
									onChange={this.handleRecruiterInfoJobDetails}
								/>
								<label htmlFor="hideRecruiterNameEmailOnTheJobDetailsPage">
									Hide recruiter name or email on the job details page
								</label>
							</div>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="hideMessageRecruiterOnTheJobDetailsPage"
									type="checkbox"
									checked={this.state.hideMessageRecruiter}
									onChange={this.handleHideMesssageRecruiter}
								/>

								<label htmlFor="hideMessageRecruiterOnTheJobDetailsPage">
									Hide Message the Recruiter text box when submitting a referral
								</label>
							</div>
							<h4 className="setting-card-title">Disable Text Referrals</h4>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="disableNewReferralSMSNotification"
									type="checkbox"
									checked={this.state.disableNewReferralSMSNotification}
									onChange={this.handleDisableNewReferralSMSNotification}
								/>
								<label htmlFor="disableNewReferralSMSNotification">
									Do not allow referrals to be sent by text message
								</label>
							</div>
							<h4 className="setting-card-title">Department Label</h4>
							<div className="custom-form-group">
								<label className="custom-label">
									The label to display for the Department:
								</label>
								<FormItem>
									{getFieldDecorator('labelDepartment', {
										initialValue: get(
											this.props,
											'company.labelDepartment',
											'Department'
										)
											? get(this.props, 'company.labelDepartment', 'Department')
											: 'Department',
										rules: [
											{
												required: true,
												message: 'Please input department label.',
											},
										],
									})(<Input className="custom-input" />)}
								</FormItem>
							</div>
							<h4 className="setting-card-title">
								{ml(
									'Ignore SubCompany',
									this.props.currentUser,
									this.props.allMultiLingualData
								)}
							</h4>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="ignoreSubCompanyInReferralEmailSubject"
									type="checkbox"
									checked={this.state.ignoreSubcompany}
									onChange={this.handleIgnoreSubCompany}
								/>
								<label htmlFor="ignoreSubCompanyInReferralEmailSubject">
									Ignore SubCompany in referral email subject
								</label>
							</div>
							<h4 className="setting-card-title">
								{ml(
									'Enable Generic Referral Email',
									this.props.currentUser,
									this.props.allMultiLingualData
								)}
							</h4>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="enableGenericReferralEmail"
									type="checkbox"
									checked={this.state.enableGenericReferralEmail}
									onChange={this.handleGenericReferralEmail}
								/>
								<label htmlFor="enableGenericReferralEmail">
									Enable generic referral email
								</label>
							</div>

							<h4 className="setting-card-title">Social Share Popup</h4>
							<div className="custom-form-group">
								<label className="custom-label">
									The label to display on the Social Share Popup:
								</label>
								<FormItem>
									{getFieldDecorator('labelSocialShare', {
										initialValue: get(
											this.props,
											'company.labelSocialShare',
											''
										)
											? get(this.props, 'company.labelSocialShare', '')
											: '',
										rules: [],
									})(<Input className="custom-input" />)}
								</FormItem>
							</div>
							<h4 className="setting-card-title">
								{ml(
									'Enable Referral Leads',
									this.props.currentUser,
									this.props.allMultiLingualData
								)}
							</h4>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="enableReferralLeads"
									type="checkbox"
									checked={this.state.enableLead}
									onChange={this.handleEnableLead}
								/>
								<label htmlFor="enableReferralLeads">
									Enable Referral Leads
								</label>
							</div>
							<h4 className="setting-card-title">
								{ml(
									'Hide Subcompany to Employees',
									this.props.currentUser,
									this.props.allMultiLingualData
								)}
							</h4>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="hideSubcompanyUserSignup"
									type="checkbox"
									checked={this.state.hideSubcompanyOnSignup}
									onChange={this.handleHideSubcompanyOnSignup}
								/>
								<label htmlFor="hideSubcompanyUserSignup">
									Hide Subcompany on User Signup
								</label>
							</div>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="hideSubcompanyOnJobs"
									type="checkbox"
									checked={this.state.hideSubcompanyOnJobs}
									onChange={this.handleHideSubcompanyOnJobs}
								/>
								<label htmlFor="hideSubcompanyOnJobs">
									Hide Subcompany on Job Cards and Job Descriptions
								</label>
							</div>
						</div>

						<h2 className="setting-heading">
							{ml('Notifications', currentUser, allMultiLingualData)}
						</h2>
						<div className="setting-card">
							<h4 className="setting-card-title">
								{ml(
									'Recruiter Notifications',
									currentUser,
									allMultiLingualData
								)}
							</h4>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="sendEmailNotificationRecruiterWhenReferralMade"
									type="checkbox"
									checked={this.state.sendAdminNotificationsOnReferral}
									onChange={this.handleSendAdminNotificationsOnReferralChange}
								/>
								<label htmlFor="sendEmailNotificationRecruiterWhenReferralMade">
									{ml(
										'Send an email notification to recruiter when a referral is made, accepted and candidate is hired.',
										currentUser,
										allMultiLingualData
									)}
								</label>
							</div>

							<div className="custom-form-group custom-checkbox left-check">
								<Tooltip
									title={
										this.state.allowInternalMobility
											? ''
											: 'Internal Mobility Page must be enabled in order to use this feature.'
									}
								>
									<input
										id="sendEmailNotificationWhenInternalCandidatesFound"
										type="checkbox"
										checked={
											this.state.allowInternalMobility &&
											this.state.sendAdminNotificationsOnInternalCandidatesFound
										}
										disabled={!this.state.allowInternalMobility}
										onChange={
											this.toggleSendAdminNotificationsOnInternalCandidatesFound
										}
									/>
									<label htmlFor="sendEmailNotificationWhenInternalCandidatesFound">
										{ml(
											'Send an email notification to the recruiter when there are top internal candidate matches for a newly listed job.',
											currentUser,
											allMultiLingualData
										)}
									</label>
								</Tooltip>
							</div>

							<h4 className="setting-card-title">
								{ml(
									'Enable Accepted Referral Reminder',
									currentUser,
									allMultiLingualData
								)}
							</h4>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="emailRemindersCandidatesWhoHaveAccepted"
									type="checkbox"
									checked={this.state.enableAcceptedReferralReminder}
									onChange={this.handleAcceptedReferralReminder}
								/>
								<label htmlFor="emailRemindersCandidatesWhoHaveAccepted">
									{ml(
										'Email Reminders for Candidates who have accepted and not applied',
										currentUser,
										allMultiLingualData
									)}
								</label>
							</div>
							<h4 className="setting-card-title">
								{ml(
									'When Referral Leads is Created, Send Email to',
									currentUser,
									allMultiLingualData
								)}
							</h4>
							<div className="custom-form-group">
								<FormItem>
									{getFieldDecorator('selectedAdmins', {
										initialValue: selectedAdmins,
									})(
										<Input
											style={{ width: 450 }}
											placeholder={ml(
												'Enter Comma Separated Email Address',
												currentUser,
												allMultiLingualData
											)}
											className="custom-input text-blue"
										/>
									)}
								</FormItem>
							</div>
							<h4 className="setting-card-title">
								{ml(
									'Anyone who is in Referred or Accepted Status for 120 hours, Send Email to',
									currentUser,
									allMultiLingualData
								)}
							</h4>
							<div className="custom-form-group">
								<FormItem>
									{getFieldDecorator('referredReportTo', {
										initialValue: referredReportTo,
									})(
										<Input
											placeholder="Enter Comma Separated Email Address"
											className="custom-input text-blue"
										/>
									)}
								</FormItem>
								<Button
									type="primary"
									size="large"
									htmlType="submit"
									onClick={this.sendReport}
								>
									{ml('Send Report', currentUser, allMultiLingualData)}
								</Button>
							</div>
						</div>

						<h2 className="setting-heading">
							{ml('Bonuses', currentUser, allMultiLingualData)}
						</h2>
						<div className="setting-card">
							<h4 className="setting-card-title">
								{ml('Referral Bonuses', currentUser, allMultiLingualData)}
							</h4>

							<div className="custom-form-group">
								<label className="custom-label">
									{ml(
										'Default Bonus Amount:',
										currentUser,
										allMultiLingualData
									)}
								</label>
								<FormItem>
									{getFieldDecorator('defaultBonusAmount', {
										initialValue: company.defaultBonusAmount
											? `$${company.defaultBonusAmount}`.replaceAll(
													/\B(?=(\d{3})+(?!\d))/g,
													','
												)
											: '$0',
										rules: [
											{
												required: true,
												message: 'Please input default bonus amount.',
											},
										],
									})(<Input className="custom-input text-green" />)}
								</FormItem>
							</div>
							<div className="custom-form-group">
								<label className="custom-label">
									{ml(
										'Contact Incentive Amount:',
										currentUser,
										allMultiLingualData
									)}
								</label>
								<FormItem>
									{getFieldDecorator('contactIncentiveBonus', {
										initialValue: company.contactIncentiveBonus
											? `$${company.contactIncentiveBonus}`.replaceAll(
													/\B(?=(\d{3})+(?!\d))/g,
													','
												)
											: '$0',
										rules: [
											{
												required: true,
												message: 'Please input contact incentive amount.',
											},
										],
									})(<Input className="custom-input" />)}
								</FormItem>
							</div>
							<p className="text-note" style={{ maxWidth: 640 }}>
								{ml(
									'Incentivize your employees to connect their contacts. All job referrals will be reduced by this amount for employees who have not connected their contacts. Note: Contact Incentive is not compatible with Bonus Builder.',
									currentUser,
									allMultiLingualData
								)}
							</p>
							<h4 className="setting-card-title">
								{ml('Bonus Waiting Period', currentUser, allMultiLingualData)}
							</h4>
							<div className="custom-form-group">
								<label className="custom-label">
									{ml('Bonus Waiting Period', currentUser, allMultiLingualData)}
									:
								</label>
								<div className="cf-addon-wrap">
									<AutoComplete
										dataSource={this.state.daysLookupChildren}
										layout="inline"
										size="large"
										defaultValue={this.state.referralBonusWaitingPeriod}
										onChange={this.handleReferralBonusWaitingPeriodChange}
									>
										{() => this.state.daysLookupChildren.map((child) => child)}
										{getFieldDecorator('notificationDays', {
											rules: [
												{
													type: 'string',
													message: ' ',
												},
											],
										})(<Input className="custom-input" />)}
									</AutoComplete>
									<div className="cf-addon">days</div>
								</div>
								<label htmlFor="notifications" />
							</div>
							<h4 className="setting-card-title">
								{ml('Bonus Earned Note', currentUser, allMultiLingualData)}
							</h4>
							<div className="custom-form-group">
								{company.bonusEarnedNote && company.bonusEarnedNote !== ' ' ? (
									<FormItem>
										{getFieldDecorator('bonusEarnedNote', {
											initialValue: company.bonusEarnedNote,
										})(
											<Input
												maxLength={60}
												className="custom-input"
												onChange={this.handleBonusEarnedNoteChange}
											/>
										)}
									</FormItem>
								) : (
									<FormItem>
										<Input
											maxLength={60}
											className="custom-input"
											onChange={this.handleBonusEarnedNoteChange}
										/>
									</FormItem>
								)}
								<label className="custom-label mr-0 ml-md-3 mt-2 mt-md-0">
									{ml(
										'Max of 60 Characters. This note displays on the My Referrals page.',
										currentUser,
										allMultiLingualData
									)}
								</label>
							</div>
							<h4 className="setting-card-title">
								{ml(
									'Display Candidate Bonus',
									currentUser,
									allMultiLingualData
								)}
							</h4>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="showCandidateRetentionBonusAcceptPage"
									type="checkbox"
									checked={this.state.enableCandidateBonus}
									onChange={this.handleCandidateBonus}
								/>
								<label htmlFor="showCandidateRetentionBonusAcceptPage">
									{ml(
										'Show Candidate Retention Bonus on Accept Page',
										currentUser,
										allMultiLingualData
									)}
								</label>
							</div>
							<h4 className="setting-card-title">
								{ml(
									'Enable Retroactive Bonuses',
									currentUser,
									allMultiLingualData
								)}
							</h4>
							<div className="custom-form-group custom-checkbox left-check">
								<input
									id="createBonusesUsingTheBonusATheTimeReferral"
									type="checkbox"
									checked={this.state.enableRetroactiveBonuses}
									onChange={this.handleRetroactiveBonus}
								/>
								<label htmlFor="createBonusesUsingTheBonusATheTimeReferral">
									{ml(
										'Create bonuses using the bonus at the time of referral, instead of on hire.',
										currentUser,
										allMultiLingualData
									)}
								</label>
							</div>
						</div>
						<h2 className="setting-heading">
							{ml('Integrations', currentUser, allMultiLingualData)}
						</h2>
					</>
				</Form>
			</div>
		);
	}
}

export default Form.create()(SettingsForm);

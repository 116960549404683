import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { ArrowRightOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Input, Row, Select } from 'antd';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import _ from 'lodash';
import get from 'lodash/get';
import mixpanel from 'mixpanel-browser';
import { Component, Fragment } from 'react';
import { withApollo } from 'react-apollo';
import { geolocated } from 'react-geolocated';
import PlacesAutocomplete from 'src/_shared/components/location/PlacesAutocomplete.jsx';
import { withRouter } from 'react-router-dom';
import { GetUserByCognitoId } from 'src/_shared/api/graphql/custom/users/';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import {
	findItemByNameAndKeywords,
	getLocation,
	getUpdatedUserFromSAMLAttributes,
	graphql,
	ml,
	parse,
} from 'src/_shared/services/utils.js';
import { requiredFields } from '../errorHandlers.js';
import { PageItem } from './AddLocationPageItemComponent.jsx';
import { USStates } from './copy.js';
import {
	AddLocationBtn as AddLocationButton,
	CheckIcon,
	FormStyles,
	InputStyles2,
	LabelStyles,
	MiscText,
	SubTitleStyles,
	SubmitBtn as SubmitButton,
	SubmitBtnContainer as SubmitButtonContainer,
	TitleStyles,
	btnText as buttonText,
	errorStyles,
} from './newUserFormStyles.js';
import { SelectStyles } from './selectStyles.js';

class NewSamlUserForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			address: '',
			geolocationAllowed: false,
			serverError: false,
			lengthError: false,
			department: '',
			requiredErrors: {},
			token: this.props.token,
			redirectURL: this.props.redirectURL,
			submitting: false,
			currencyAbbrev: this.props?.defaultUserGroup?.currency ?? 'USD',
			multiLingualData: '',
			isSubCompanyIdOnToken: false,
		};
	}

	async componentDidMount() {
		const provider = get(this.props, 'token.identities[0].providerName', null);
		const saml = await graphql({
			input: { provider },
			query: 'getSAMLAuthByProvider',
		});
		const permissions = await navigator.permissions.query({
			name: 'geolocation',
		});
		const geolocationAllowed = permissions?.state === 'granted';
		if (geolocationAllowed) this.getCurrentLocation();
		this.setState({ geolocationAllowed, saml });
		// Double check to make sure cognito id does not already exist for this user
		const cognitoId = get(this.state, 'token.cognito:username');
		const subCompanyName = get(this.props, 'token.custom:subCompany');
		const subCompanies = get(this.props, 'company.subCompanies', []);
		const subCompany = findItemByNameAndKeywords(subCompanyName, subCompanies);
		const subCompanyId = subCompany ? subCompany.id : null;
		if (subCompanyId) this.setState({ isSubCompanyIdOnToken: true });
		const { data } = await this.props.client.query({
			query: GetUserByCognitoId,
			variables: {
				cognitoId,
			},
		});
		const currentUser = data?.getUserByCognitoId;
		if (currentUser && currentUser.cognitoId) {
			// Found the user - do not let them login
			const error =
				'The provided user already exists. Please try logging in again';
			this.props.history.push({
				pathname: '/login',
				state: {
					err: error,
				},
			});
		}
	}

	onAuthentication = async (authToken, currentUser) => {
		const cleanToken = {};
		for (const [key, value] of Object.entries(authToken)) {
			if (!key.includes('custom:')) cleanToken[key] = value;
		}

		const encodedToken = `#access_token=${encodeURIComponent(
			Buffer.from(JSON.stringify(cleanToken)).toString('base64')
		)}`;
		Cookies.set('jwt', encodedToken);
		if (currentUser) {
			this.props.setCurrentUser(currentUser);
		}
	};

	onChange = (event_) => {
		const { value } = event_.target;
		this.setState({ currencyAbbrev: value });
	};

	getAccountClaimByEmployeeCompanyId = async (employeeId, companyId) => {
		try {
			const claim = await graphql(
				{
					input: { employeeId, companyId },
					query: 'getAccountClaimByEmployeeIdCompanyId',
				},
				'apiKey'
			);
			return claim;
		} catch {
			return null;
		}
	};

	getCurrentLocation = () => {
		if (navigator.geolocation) {
			const options = { timeout: 60_000 };
			navigator.geolocation.getCurrentPosition(
				this.showLocation,
				this.errorHandler,
				options
			);
		} else {
			console.log('Sorry, your browser does not support geolocation!');
		}
	};

	addLocation = async () => {
		if (this.props.isGeolocationAvailable) {
			const lat = this.props?.coords?.latitude;
			const lon = this.props?.coords?.longitude;
			const location = await getLocation({ lat, lon }, 'apiKey');
			this.setState({ address: location?.address, location });
		} else {
			console.log('Location is not available');
		}
	};

	errorHandler = (error) => {
		if (error.code == 1) {
			console.log('Error: Access is denied!');
		} else if (error.code == 2) {
			console.log('Error: Position is unavailable!');
		}
	};

	handleSelectDepartment = (department) => {
		this.setState({ department });
	};

	handleSubmit = (e) => {
		if (e) e.preventDefault();
		const {
			company,
			defaultUserGroupId,
			hasPermissions,
			onCreate,
			onUpdateAccountClaim,
		} = this.props;
		const token = get(this.state, 'token');
		const roles = get(token, 'custom:role', '').split(',');
		const employeeId =
			token?.['custom:employeeid'] || token?.['custom:userid'] || null;

		const subCompanyName = get(token, 'custom:subCompany');
		const subCompanies = get(this.props, 'company.subCompanies', []);
		const subCompany = findItemByNameAndKeywords(subCompanyName, subCompanies);
		const subCompanyId = subCompany ? subCompany.id : null;

		const userSignupSettings = get(this.props, 'company.userSignupSettings')
			? parse(get(this.props, 'company.userSignupSettings'))
			: {};

		const cognitoId = token?.['cognito:username'];

		this.setState({ serverError: false, pageIsValid: true });
		this.props.form.validateFields(async (error, values) => {
			let myDepartment = get(values, 'department.key');
			if (get(userSignupSettings, 'department.hidden')) {
				const hiddenDepartmentDefault = get(
					userSignupSettings,
					'department.default',
					'Other'
				);
				const departments = get(this.props, 'departments', []);
				myDepartment = departments.find(
					(department) => get(department, 'name') === hiddenDepartmentDefault
				).id;
			}

			const departmentId = myDepartment;
			const title = get(userSignupSettings, 'title.hidden')
				? get(userSignupSettings, 'title.default', 'Employee')
				: get(values, 'title');

			let loginFormPickerSettings = parse(
				get(this.props, 'company.loginFormPickerSettings')
			);
			loginFormPickerSettings = loginFormPickerSettings
				? loginFormPickerSettings
				: [];
			if (error) {
				this.setState({ serverError: true });
			}

			if (this.pageIsValid(values)) {
				this.setState({ submitting: true });
				try {
					const createUser = {
						cognitoId,
						companyId: company?.id,
						emailAddress: get(values, 'emailAddress', '').toLowerCase(),
						role: 'employee',
						firstName: values.firstName,
						lastName: values.lastName,
						title,
						departmentId,
						avatar: null,
						lastLogin: dayjs().toISOString(),
						active: true,
						createdById: this.props?.match?.params?.id,
						userGroupId: defaultUserGroupId,
						currency: this.state?.currencyAbbrev || 'USD',
						languageCode: 'US',
					};

					if (this.state?.location)
						createUser.location = JSON.stringify(this.state.location);
					if (hasPermissions(roles)) createUser.role = 'admin';

					if (subCompanyId) {
						createUser.subCompanyId = subCompanyId;
					} else if (get(values, 'subCompany.key')) {
						createUser.subCompanyId = get(values, 'subCompany.key');
					}

					let accountClaim = null;
					const companyId = get(company, 'id');
					if (employeeId && companyId) {
						accountClaim = await this.getAccountClaimByEmployeeCompanyId(
							employeeId,
							companyId
						);
					}

					const { input } = getUpdatedUserFromSAMLAttributes({
						accountClaim,
						currentUser: { company },
						token,
						createUser,
					});

					for (const [index, picker] of loginFormPickerSettings.entries()) {
						if (get(values, `picker${index}`))
							input[get(picker, 'userAttribute')] = get(
								values,
								`picker${index}.key`
							);
					}

					onCreate({ input }).then(async (user) => {
						const { data } = await this.props.client.query({
							query: GetUserByCognitoId,
							variables: {
								cognitoId: get(user, 'data.createUser.cognitoId'),
							},
						});
						const currentUser = data.getUserByCognitoId;
						const userId = get(currentUser, 'id');
						if (accountClaim?.id) {
							onUpdateAccountClaim({
								input: {
									id: accountClaim.id,
									claimed: true,
									userId,
								},
							});
						}

						mixpanel.identify(currentUser.id);
						mixpanel.register({
							'Company Name': currentUser?.company?.name,
							'Company ID': currentUser?.company?.id,
						});
						mixpanel.track('Account Created');
						mixpanel.track('Logged In');
						localStorage.setItem('mixpanel_user_identified', 'true');
						this.onAuthentication(token, currentUser);
						window.location.href = '/dashboard';
					});
				} catch (error) {
					this.setState({ serverError: true, submitting: false });
					console.error(error);
				}
			} else {
				this.setState({ loading: false });
			}
		});
	};

	pageIsValid = (values) => {
		let loginFormPickerSettings = parse(
			get(this.props, 'company.loginFormPickerSettings', [])
		);
		loginFormPickerSettings = loginFormPickerSettings
			? loginFormPickerSettings
			: [];
		loginFormPickerSettings = loginFormPickerSettings.map((picker, index) => {
			return { ...picker, index };
		});
		const filteredPickers = loginFormPickerSettings.filter(
			(picker) => get(picker, 'required') === true
		);
		const requiredPickers = filteredPickers.map((picker) => {
			return {
				key: `picker${picker.index}`,
				message: get(picker, 'error', 'Please select an item'),
			};
		});
		let isValid = true;
		const requiredErrors = {};
		let subCompanies = get(this.props, 'company.subCompanies');
		const userSignupSettings = get(this.props, 'company.userSignupSettings')
			? parse(get(this.props, 'company.userSignupSettings'))
			: {};
		subCompanies = subCompanies ? subCompanies : [];
		const requestFields = [...requiredPickers, ...requiredFields];
		for (const { key, message } of requestFields) {
			if (
				key === 'department' &&
				get(values[key], 'key') === 'Select a department' &&
				!get(userSignupSettings, 'department.hidden')
			) {
				requiredErrors[key] = message;
				isValid = false;
			}

			if (!values[key]) {
				if (
					key === 'subCompany' &&
					subCompanies.length > 0 &&
					!this.state.isSubCompanyIdOnToken
				) {
					requiredErrors[key] = message;
					isValid = false;
				} else if (
					key !== 'subCompany' &&
					!get(userSignupSettings, `[${key}].hidden`)
				) {
					requiredErrors[key] = message;
					isValid = false;
				}
			}
		}

		this.setState({
			requiredErrors,
		});
		return isValid;
	};

	showLocation = async (position) => {
		const lat = position?.coords?.latitude;
		const lon = position?.coords?.longitude;
		const location = await getLocation({ lat, lon }, 'apiKey');
		this.setState({ address: location?.address, location });
	};

	renderCustomPickers() {
		const FormItem = Form.Item;
		const { Option } = Select;
		const { getFieldDecorator } = this.props.form;
		const { requiredErrors } = this.state;
		let loginFormPickerSettings = parse(
			get(this.props, 'company.loginFormPickerSettings')
		);
		loginFormPickerSettings = loginFormPickerSettings
			? loginFormPickerSettings
			: [];
		const pickers = loginFormPickerSettings.map((picker, index) => {
			let options = [];
			options = picker.values.map((value) => {
				return (
					<Option key={value} value={value}>
						{value}
					</Option>
				);
			});
			const newPicker = (
				<FormItem key={picker.name + index.toString()} className={InputStyles2}>
					<label className={LabelStyles}>{get(picker, 'name')}</label>
					{getFieldDecorator(
						`picker${index}`,
						{}
					)(
						<Select
							labelInValue
							className={SelectStyles}
							placeholder={get(picker, 'placeholder')}
							showArrow={false}
						>
							{options}
						</Select>
					)}
					{requiredErrors && requiredErrors[`picker${index}`] ? (
						<div>
							<p className={errorStyles}>
								{' '}
								{requiredErrors[`picker${index}`]}{' '}
							</p>
						</div>
					) : null}
				</FormItem>
			);
			return newPicker;
		});

		const result = [];
		const groups = _.chunk(pickers, 2);
		for (const group of groups) {
			if (group.length === 1) result.push(group[0]);
			if (group.length === 2) {
				const double = (
					<Fragment key={group[0].key + group[1].key}>
						<Col md={11} xs={24}>
							{group[0]}
						</Col>
						<Col md={{ span: 12, offset: 1 }} xs={24}>
							{group[1]}
						</Col>
					</Fragment>
				);
				result.push(double);
			}
		}

		return result;
	}

	handleSelectLocation(location) {
		this.setState({ address: location?.address, location });
	}

	handleChangeLocation(address) {
		this.setState({ address });
	}

	renderLocation = () => {
		const { allMultiLingualData } = this.props;
		const languageCode = 'US';
		const { Option } = Select;
		const options = [];
		Object.keys(USStates).map((key) =>
			options.push(<Option key={key}>{USStates[key]}</Option>)
		);
		return (
			<div style={{ marginBottom: '14px', width: '100%', marginTop: '-24px' }}>
				<PageItem>
					<Col md={24} xs={48}>
						<label className={LabelStyles}>
							{ml('Location', { languageCode }, allMultiLingualData)}
						</label>
						{this.state.geolocationAllowed && (
							<Button className={AddLocationButton} onClick={this.addLocation}>
								<EnvironmentOutlined />
								<span className={buttonText}>
									{ml(
										'Autofill Location',
										{ languageCode },
										allMultiLingualData
									)}
								</span>
							</Button>
						)}
					</Col>
					<Col md={24} xs={16}>
						<div>
							<PlacesAutocomplete
								auth="apiKey"
								address={this.state.address}
								setAddress={this.handleChangeLocation.bind(this)}
								onSelect={this.handleSelectLocation.bind(this)}
							/>
						</div>
					</Col>
				</PageItem>
			</div>
		);
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { saml, serverError, requiredErrors, submitting } = this.state;
		const { allMultiLingualData } = this.props;
		const languageCode = 'US';
		const FormItem = Form.Item;
		const { Option } = Select;
		const token = get(this.state, 'token');
		const firstName = get(token, 'given_name', '');
		const lastName = get(token, 'family_name', '');
		const emailAddress = get(token, 'email', '');
		const userSignupSettings = get(this.props, 'company.userSignupSettings')
			? parse(get(this.props, 'company.userSignupSettings'))
			: {};
		let title = get(token, 'custom:jobtitle', '');
		if (!title && get(userSignupSettings, 'title.hidden'))
			title = get(userSignupSettings, 'title.default')
				? get(userSignupSettings, 'title.default')
				: 'None';
		const { whiteLabel, theme, lblDepartment, lblCompany } = this.props;
		let options = [];
		options = this.props?.departments.map((department) => {
			return (
				<Option key={department.id} value={department.id}>
					{department.name}
				</Option>
			);
		});

		let optionsSubCompanies = [];
		const sortedSubCompanies = _.sortBy(
			get(this.props, 'company.subCompanies', []),
			['name']
		);
		if (sortedSubCompanies !== null) {
			optionsSubCompanies = sortedSubCompanies.map((subCompany) => {
				return (
					<Option key={subCompany.id} value={subCompany.id}>
						{subCompany.name}
					</Option>
				);
			});
		}

		if (submitting) {
			return (
				<div style={{ width: '100%' }}>
					<Spinner />
				</div>
			);
		}

		const departmentHeader = get(saml, 'departmentHeader')
			? get(saml, 'departmentHeader')
			: lblDepartment
				? lblDepartment
				: ml('Department', { languageCode }, allMultiLingualData);
		const initialDepartment = get(this.props, 'defaultDepartment.id') ? (
			<Option
				key={get(this.props, 'defaultDepartment.id', null)}
				value={get(this.props, 'defaultDepartment.id', null)}
			>
				{get(this.props, 'defaultDepartment.name', null)}
			</Option>
		) : (
			[]
		);
		return (
			<div>
				<Row style={{ justifyContent: 'center' }}>
					<h1 className={TitleStyles}>
						{ml(`Let's Get Started`, { languageCode }, allMultiLingualData)}
					</h1>
				</Row>
				<Row style={{ justifyContent: 'center' }}>
					<h2 className={SubTitleStyles}>
						{ml(
							'First complete your profile',
							{ languageCode },
							allMultiLingualData
						)}
					</h2>
				</Row>
				<Form className={FormStyles}>
					<Row>
						<Col md={11} xs={24}>
							<FormItem className={InputStyles2}>
								<label className={LabelStyles}>
									{ml('First Name', { languageCode }, allMultiLingualData)}
								</label>
								{getFieldDecorator('firstName', {
									initialValue: firstName ? firstName : '',
								})(<Input />)}
								{requiredErrors && requiredErrors.firstName ? (
									<div>
										<p className={errorStyles}> {requiredErrors.firstName} </p>
									</div>
								) : null}
							</FormItem>
						</Col>
						<Col md={{ span: 12, offset: 1 }} xs={24}>
							<FormItem className={InputStyles2}>
								<label className={LabelStyles}>
									{ml('Last Name', { languageCode }, allMultiLingualData)}
								</label>
								{getFieldDecorator('lastName', {
									initialValue: lastName ? lastName : '',
								})(<Input />)}
								{requiredErrors && requiredErrors.lastName ? (
									<div>
										<p className={errorStyles}> {requiredErrors.lastName} </p>
									</div>
								) : null}
							</FormItem>
						</Col>
					</Row>
					<Row>
						{get(userSignupSettings, 'department.hidden') ? null : (
							<Col md={11} xs={24}>
								<FormItem className={InputStyles2}>
									<label className={LabelStyles}>{departmentHeader}</label>
									{getFieldDecorator('department', {
										initialValue: initialDepartment,
									})(
										<Select
											labelInValue
											className={SelectStyles}
											placeholder={`Select a ${departmentHeader}`}
											showArrow={false}
										>
											{options}
										</Select>
									)}
									{requiredErrors && requiredErrors.department ? (
										<div>
											<p className={errorStyles}>
												{' '}
												{requiredErrors.department}{' '}
											</p>
										</div>
									) : null}
								</FormItem>
							</Col>
						)}
						{get(userSignupSettings, 'title.hidden') ? null : (
							<Col md={{ span: 12, offset: 1 }} xs={24}>
								<FormItem className={InputStyles2}>
									<label className={LabelStyles}>
										{ml('Job Title', { languageCode }, allMultiLingualData)}
									</label>
									{getFieldDecorator('title', {
										initialValue: title,
									})(<Input />)}
									{requiredErrors &&
									requiredErrors.title &&
									!get(userSignupSettings, 'title.hidden') ? (
										<div>
											<p className={errorStyles}> {requiredErrors.title} </p>
										</div>
									) : null}
								</FormItem>
							</Col>
						)}
					</Row>
					{optionsSubCompanies.length > 0 &&
						!this.state.isSubCompanyIdOnToken && (
							<Row>
								<Col md={24} xs={24}>
									<FormItem className={InputStyles2}>
										<label className={LabelStyles}>
											{ml(
												lblCompany ? lblCompany : 'Company',
												{ languageCode },
												allMultiLingualData
											)}
										</label>
										{getFieldDecorator(
											'subCompany',
											{}
										)(
											<Select
												labelInValue
												className={SelectStyles}
												placeholder="Select a company"
												showArrow={false}
											>
												{optionsSubCompanies}
											</Select>
										)}
										{requiredErrors && requiredErrors.subCompany ? (
											<div>
												<p className={errorStyles}>
													{' '}
													{requiredErrors.subCompany}{' '}
												</p>
											</div>
										) : null}
									</FormItem>
								</Col>
							</Row>
						)}
					<Row>{this.renderCustomPickers()}</Row>
					{get(userSignupSettings, 'location.hidden') ? null : (
						<Row>{this.renderLocation()}</Row>
					)}
					<FormItem className={InputStyles2}>
						<label className={LabelStyles}>
							{ml('Email Address', { languageCode }, allMultiLingualData)}
						</label>
						{getFieldDecorator('emailAddress', {
							initialValue: emailAddress ? emailAddress : '',
							rules: [
								{
									type: 'email',
									message: ml(
										'Not a valid email address.',
										{ languageCode },
										allMultiLingualData
									),
								},
							],
						})(<Input disabled={Boolean(emailAddress)} />)}
					</FormItem>
					{!whiteLabel && (
						<p className={MiscText}>
							{ml(
								'By creating a profile you agree to the ERIN',
								{ languageCode },
								allMultiLingualData
							)}{' '}
							<a
								href="http://erinapp.com/terms-of-use"
								rel="noopener noreferrer"
								target="_blank"
							>
								{ml('Terms of Use', { languageCode }, allMultiLingualData)}
							</a>{' '}
							{ml('and', { languageCode }, allMultiLingualData)}{' '}
							<a
								href="https://erinapp.com/privacy-policy"
								rel="noopener noreferrer"
								target="_blank"
							>
								{ml('Privacy Policy', { languageCode }, allMultiLingualData)}
							</a>
							{', '}
							{ml(
								'and agree that we can send you information about jobs and referrals at your company. You can opt out at any time.',
								{ languageCode },
								allMultiLingualData
							)}
						</p>
					)}
					{serverError ? (
						<Alert
							type="error"
							message={ml(
								'There was a problem creating your account. Please try again.',
								{ languageCode },
								allMultiLingualData
							)}
						/>
					) : null}

					<FormItem className={SubmitButtonContainer(theme)}>
						<Button
							className={SubmitButton(theme)}
							onClick={_.debounce(this.handleSubmit, 300, {
								leading: true,
								trailing: false,
							})}
						>
							{ml('Get Started', { languageCode }, allMultiLingualData)}
							<ArrowRightOutlined
								className={CheckIcon}
								style={{ lineHeight: 0 }}
							/>
						</Button>
					</FormItem>
				</Form>
			</div>
		);
	}
}

export default withApollo(
	withRouter(
		Form.create()(
			geolocated({
				positionOptions: {
					enableHighAccuracy: true,
				},
				userDecisionTimeout: 60_000,
			})(NewSamlUserForm)
		)
	)
);

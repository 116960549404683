import { forwardRef } from 'react';
import { initialCaps } from 'src/_shared/services/utils.js';

export const Container = forwardRef(
	(
		{
			children,
			columns = 1,
			handleProps,
			horizontal,
			hover,
			onClick,
			onRemove,
			label,
			placeholder,
			style,
			scrollable,
			shadow,
			unstyled,
			...props
		},
		ref
	) => {
		const Component = onClick ? 'button' : 'div';

		return (
			<Component
				{...props}
				ref={ref}
				style={{
					...style,
					'--columns': columns,
				}}
				className={`report-builder-container ${hover ? 'hover' : ''} ${
					placeholder ? 'placeholder' : ''
				}`}
				tabIndex={onClick ? 0 : undefined}
				onClick={onClick}
			>
				{label ? (
					<div className="report-builder-heading">
						<h2 className="report-builder-list-heading">
							{initialCaps(label)}
						</h2>
					</div>
				) : null}
				{placeholder ? (
					children
				) : (
					<div className="scrollable">
						<ul className="report-builder-item-list">{children}</ul>
						<div className="dummy-element" />
					</div>
				)}
			</Component>
		);
	}
);

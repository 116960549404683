import {
	Col,
	DatePicker,
	Form,
	Input,
	Modal,
	Row,
	Select,
	message,
} from 'antd';
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { Component, createRef } from 'react';
import ProgressButton from 'react-progress-button';
import { queryContactsByUserIdIndexMinimal } from 'src/_shared/api/graphql/custom';
import { ml, sanitize } from '../../_shared/services/utils.js';

class AddBonusModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			bonusTypeValue: 'Employee Referral',
			bonusDate: '',
			buttonState: '',
			contacts: null,
			recipientType: null,
			selectedEmployeeId: null,
			selectedContactId: null,
			selectedJobId: null,
		};
		this.formRef = createRef();
	}

	componentDidMount() {
		this.setState({
			...(this.props.jobs ? { autoCompleteJobsResult: this.props.jobs } : {}),
			...(this.props.AllUsers
				? { autoCompleteResult: this.props.AllUsers }
				: {}),
		});
	}

	async componentDidUpdate(_prevProps, prevState) {
		const { selectedEmployeeId } = this.state;
		if (selectedEmployeeId !== prevState.selectedEmployeeId) {
			try {
				const { client } = this.props;
				const contacts = [];

				const getContacts = async (next = null) => {
					const { data } = await client.query({
						query: gql(queryContactsByUserIdIndexMinimal),
						variables: {
							userId: selectedEmployeeId,
							nextToken: next,
						},
						fetchPolicy: 'network-only',
					});
					const result = data.queryContactsByUserIdIndex.items;
					contacts.push(...result);
					const { nextToken } = data.queryContactsByUserIdIndex;
					if (nextToken) {
						await getContacts(nextToken);
					}
				};

				await getContacts();

				this.setState({ contacts });
			} catch (error) {
				console.error(error);
			}
		}
	}

	onClose = () => {
		this.setState({
			bonusTypeValue: 'Employee Referral',
			bonusDate: '',
			buttonState: '',
			contacts: null,
			recipientType: null,
			selectedEmployeeId: null,
			selectedContactId: null,
			selectedJobId: null,
		});
		this.props.handleClose();
	};

	onselectContact = (id) => {
		this.setState({
			selectedContactId: id,
		});
	};

	onselectEmployee = (id) => {
		this.setState({
			selectedEmployeeId: id,
		});
	};

	onselectJOb = (id) => {
		this.setState({
			selectedJobId: id,
		});
	};

	setStartDate = (value) => {
		this.setState({
			bonusDate: value,
		});
	};

	onFinishFailed = () => {
		this.setState({
			buttonState: 'error',
		});
		setTimeout(() => {
			this.setState({
				buttonState: '',
			});
		}, 1500);
	};

	addBonus = (values) => {
		const { onCreateBonus, currentUser } = this.props;
		const {
			selectedEmployeeId,
			selectedJobId,
			selectedContactId,
			recipientType,
			bonusDate,
			bonusTypeValue,
		} = this.state;
		const selectedDate = dayjs(bonusDate).toISOString();
		this.setState({
			buttonState: 'loading',
		});

		let amountDue = get(values, 'amountInput', 0);
		amountDue &&= sanitize(amountDue);
		const input = {
			input: {
				companyId: currentUser?.companyId,
				contactId: selectedContactId,
				userId: selectedEmployeeId,
				jobId: selectedJobId,
				bonusStatus: 'pending',
				recipientType,
				amountDue,
				hireDate: selectedDate,
				startDate: selectedDate,
				earnedDate: selectedDate,
				payment: '1 of 1',
				bonusType: bonusTypeValue,
				creationType: 'manual',
			},
		};
		onCreateBonus(input);
		setTimeout(() => {
			this.setState({
				buttonState: 'success',
			});
		}, 2000);
		this.formRef.current.resetFields();
		message.success('Bonus is added successfully.', 5);
		setTimeout(() => {
			this.onClose();
		}, 2000);

		this.setState({
			buttonState: '',
		});
	};

	handleBonusType = (value) => {
		this.setState({ bonusTypeValue: value });
	};

	handleRecipient = (value) => {
		this.setState({ recipientType: value });
	};

	renderContact = () => {
		const { contacts } = this.state;

		const selectedOption = contacts?.map((item) => {
			return (
				<Select.Option key={item?.id} value={item?.id}>
					{item?.firstName + ' ' + item?.lastName}
				</Select.Option>
			);
		});

		return (
			<Form.Item
				name="candidateId"
				rules={[{ required: true, message: 'Please select a candidate' }]}
			>
				<Select
					showSearch
					className="custom-input"
					placeholder="Type a candidate Name"
					optionFilterProp="children"
					filterOption={(input, option) =>
						option.props.children.toLowerCase().includes(input.toLowerCase())
					}
					disabled={!contacts}
					onChange={this.onselectContact}
				>
					{selectedOption}
				</Select>
			</Form.Item>
		);
	};

	renderEmployees = () => {
		const { employees } = this.props;

		const selectedOption = employees?.map((item) => {
			return (
				<Select.Option key={item?.id} value={item?.id}>
					{item?.firstName + ' ' + item?.lastName}
				</Select.Option>
			);
		});

		return (
			<Form.Item
				name="employeeId"
				rules={[{ required: true, message: 'Please select an employee' }]}
			>
				<Select
					showSearch
					className="custom-input"
					placeholder="Type an employee Name"
					filterOption={(input, option) =>
						option.props.children.toLowerCase().includes(input.toLowerCase())
					}
					onChange={this.onselectEmployee}
				>
					{selectedOption}
				</Select>
			</Form.Item>
		);
	};

	renderJobs = () => {
		const { jobs } = this.props;

		const selectedOption = jobs?.map((item) => {
			return (
				<Select.Option key={item?.id} value={item?.id}>
					{item?.title}
				</Select.Option>
			);
		});

		return (
			<div>
				<Form.Item
					name="jobId"
					rules={[{ required: true, message: 'Please select a job' }]}
				>
					<Select
						showSearch
						className="custom-input"
						placeholder="Select a Job"
						filterOption={(input, option) =>
							option.props.children.toLowerCase().includes(input.toLowerCase())
						}
						onChange={this.onselectJOb}
					>
						{selectedOption}
					</Select>
				</Form.Item>
			</div>
		);
	};

	render() {
		const { open, currentUser, allMultiLingualData } = this.props;
		return (
			<Modal
				centered
				open={open}
				footer={null}
				title="Create a Bonus"
				onCancel={() => {
					this.onClose();
					this.formRef.current.resetFields();
				}}
			>
				<Form
					ref={this.formRef}
					initialValues={{ bonusType: 'Employee Referral' }}
					onFinish={this.addBonus}
					onFinishFailed={this.onFinishFailed}
				>
					<div className="custom-form-group">
						<label className="custom-label">
							{ml('Bonus Type', currentUser, allMultiLingualData)}:&nbsp;
						</label>
						<Form.Item
							name="bonusType"
							rules={[
								{ required: true, message: 'Please select a Bonus Type.' },
							]}
							validateTrigger={['onChange', 'onBlur']}
						>
							<Select className="custom-input" onChange={this.handleBonusType}>
								<Select.Option key={0} value="Employee Referral">
									Employee Referral
								</Select.Option>
							</Select>
						</Form.Item>
					</div>
					<div className="divider" />
					<Row gutter={32}>
						<Col sm={12} xs={24}>
							<div className="custom-form-group">
								<label className="custom-label">
									{ml('Employee', currentUser, allMultiLingualData)}:&nbsp;
								</label>
								{this.renderEmployees()}
							</div>
						</Col>
						<Col sm={12} xs={24}>
							<div className="custom-form-group">
								<label className="custom-label">
									{ml('Candidate', currentUser, allMultiLingualData)}:&nbsp;
								</label>
								{this.renderContact()}
							</div>
						</Col>
					</Row>
					<Row gutter={32}>
						<Col sm={12} xs={24}>
							<div className="custom-form-group">
								<label className="custom-label">
									{ml('Recipient', currentUser, allMultiLingualData)}:&nbsp;
								</label>
								<Form.Item
									name="recipientType"
									rules={[
										{
											required: true,
											message: 'Please select a Recipient Type.',
										},
									]}
									validateTrigger={['onChange', 'onBlur']}
								>
									<Select
										className="custom-input"
										onChange={(value) => this.handleRecipient(value)}
									>
										<Select.Option key={1} value="employee">
											Employee
										</Select.Option>
										<Select.Option key={2} value="candidate">
											Candidate
										</Select.Option>
									</Select>
								</Form.Item>
							</div>
						</Col>
						<Col sm={12} xs={24}>
							<div className="custom-form-group">
								<label className="custom-label">
									{ml('Job', currentUser, allMultiLingualData)}:&nbsp;
								</label>
								{this.renderJobs()}
							</div>
						</Col>
					</Row>
					<Row gutter={32}>
						<Col sm={12} xs={24}>
							<div className="custom-form-group">
								<label className="custom-label">
									{ml('Amount', currentUser, allMultiLingualData)}:&nbsp;
								</label>
								<Form.Item
									name="amountInput"
									rules={[{ required: true, message: 'Please enter Amount.' }]}
								>
									<Input className="custom-input" placeholder="Amount" />
								</Form.Item>
							</div>
						</Col>
						<Col sm={12} xs={24}>
							<div className="custom-form-group">
								<label className="custom-label">
									{ml('Date', currentUser, allMultiLingualData)}:&nbsp;
								</label>
								<Form.Item
									name="date"
									rules={[{ required: true, message: 'Please select date' }]}
								>
									<DatePicker
										disabledDate={(current) => {
											const customDate = dayjs().format('YYYY-MM-DD');
											return (
												current && current < dayjs(customDate, 'YYYY-MM-DD')
											);
										}}
										className="custom-input"
										format="MM-DD-YYYY"
										onChange={(value) => this.setStartDate(value)}
									/>
								</Form.Item>
							</div>
						</Col>
					</Row>
					<div className="modal-footer-btn">
						<ProgressButton
							controlled
							durationSuccess={3000}
							state={this.state.buttonState}
							type="submit"
						>
							{ml('Add Bonus', currentUser, allMultiLingualData)}
						</ProgressButton>
					</div>
				</Form>
			</Modal>
		);
	}
}

export default AddBonusModal;

import { useState, useEffect } from 'react';
import { message } from 'antd';
import { useMyRewardsService } from '../service/MyRewards.service.jsx';

const useManageAddress = (handleCancel) => {
	const initialAddressValues = {
		id: undefined,
		addressLine1: '',
		addressLine2: '',
		state: undefined,
		country: undefined,
		city: undefined,
		landmark: '',
		pinCode: undefined,
		name: 'Work',
	};

	const [employeeAddress, setEmployeeAddress] =
		useState(initialAddressValues);
	const [addressLoader, setAddressLoader] = useState(false);
	const [manageAddressLoader, setManageAddressLoader] = useState(false);
	const {
		getEmployeeAddressById,
		createEmployeeAddress,
		updateEmployeeAddress,
	} = useMyRewardsService();

	const handleAddressError = (
		error,
		errorMessage = 'Failed to fetch address! Try again later.'
	) => {
		message.error(error?.response?.data?.message || errorMessage);
		setEmployeeAddress({ ...initialAddressValues });
	};

	const getAddressByEmpId = async (empId) => {
		setAddressLoader(true);
		try {
			const response = await getEmployeeAddressById(empId);
			if (response?.code === 200) {
				if (response.data) {
					setEmployeeAddress({
						...response.data,
					});
				}
			} else {
				handleAddressError(
					null,
					response?.message ||
						'Failed to fetch address! Try again later.'
				);
			}
		} catch (error) {
			setEmployeeAddress({
				...initialAddressValues,
			});
			handleAddressError(error);
		} finally {
			setAddressLoader(false);
		}
	};

	const createAddress = async (addressObj) => {
		try {
			const response = await createEmployeeAddress({
				...addressObj,
				name: 'Work',
			});
			if (response?.code === 200 || response?.code === 201) {
				message.success('Address created successfully!');
			} else {
				message.error(
					response?.message ||
						'Failed to create address! Try again later.'
				);
			}
			handleCancel();
		} catch (error) {
			message.error(
				error?.response?.message ||
					'Failed to create address! Try again later.'
			);
			handleCancel();
		} finally {
			setManageAddressLoader(false);
			handleCancel();
		}
	};

	const updateAddress = async (addressObj) => {
		try {
			const response = await updateEmployeeAddress(addressObj.id, {
				...addressObj,
				name: 'Work',
			});
			if (response?.code === 200 || response?.code === 201) {
				message.success('Address updated successfully!');
			} else {
				message.error(
					response?.message ||
						'Failed to update address! Try again later.'
				);
			}
			handleCancel();
		} catch (error) {
			message.error(
				error?.response?.message ||
					'Failed to update address! Try again later.'
			);
			handleCancel();
		} finally {
			setManageAddressLoader(false);
			handleCancel();
		}
	};

	const manageAddressByEmpId = async (addressObj) => {
		setManageAddressLoader(true);
		try {
			if (addressObj?.id) {
				await updateAddress(addressObj);
			} else {
				await createAddress(addressObj);
			}
		} catch (error) {
			message.error(
				error?.message || 'Failed to manage address! Try again later.'
			);
		}
	};

	useEffect(() => {
		getAddressByEmpId();
	}, []);

	return {
		employeeAddress,
		addressLoader,
		manageAddressLoader,
		manageAddressByEmpId,
	};
};

export default useManageAddress;

import { useState } from 'react';
import { RewardList as RewardListContainer } from '../../../RnRCommonComponent';
import RewardPointsAndBonusContainer from '../../container/rewardPointsAndBonusContainer';
import ActionItemCard from '../actionItemCard';
import './style.scss';
import { BADGE_TYPE } from '../../../RnRCommonComponent/CoreBadge/rewardsConstants.js';
import ViewRewardModal from '../../../RnRCommonComponent/ViewRewardModal/ViewRewardModal.jsx';

const MyrewardsPresentation = (props) => {
	const {
		handleOpenViewReward,
		handleCloseViewReward,
		isViewRewardModalVisible,
		rewardDetails,
		isModalLoaderVisible,
	} = props;
	const [shouldRefresh, setShouldRefresh] = useState(false);

	return (
		<div className="my-recognitions">
			<div className="my-recognitions-container">
				<div className="row">
					<div className="col-xl-6 col-12 pr-0">
						<RewardPointsAndBonusContainer />
					</div>
					<div className="col-xl-6 col-12">
						<div className="rewards-points d-card height-equal">
							<div className="d-card-head">
								<h4 className="d-title-no-case">
									Action Items{' '}
								</h4>
							</div>
							<ActionItemCard />
						</div>
					</div>
				</div>
			</div>
			<RewardListContainer
				handleIconClickEvent={(badge) => {
					handleOpenViewReward(badge.badgeId);
				}}
				title="My Recognitions"
				badgeType={BADGE_TYPE.PEERTOPEER}
				getMyBadge
				shouldRefresh={shouldRefresh}
				setShouldRefresh={setShouldRefresh}
				isDetailsShown={false}
				isPointsShown={true}
				isIssuedVisible={true}
			/>
			<ViewRewardModal
				isViewRewardModalVisible={isViewRewardModalVisible}
				handleCloseViewReward={handleCloseViewReward}
				rewardDetails={rewardDetails}
				isModalLoaderVisible={isModalLoaderVisible}
				isSharingVisible={true}
			/>
		</div>
	);
};

export default MyrewardsPresentation;

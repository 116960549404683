import { useEffect, useRef, useState } from 'react';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { TabList } from 'src/_shared/components/tab-list';
import {
	FREQUENCY_MAP,
	FREQUENCY_TYPE,
	FREQUENCY_VALUE,
	REPORT_DATE_RANGE,
	REPORT_TYPES,
	USER_ROLES,
} from 'src/_shared/constants/';
import { initialCaps, lambda } from 'src/_shared/services/utils.js';
import { ReportBuilderForm } from './ReportBuilderFormComponent.jsx';

export function ReportBuilderComponent(props) {
	const [referralsConfig, setReferralsConfig] = useState(null);
	const [employeeConfig, setEmployeeConfig] = useState(null);
	const [bonusConfig, setBonusConfig] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [remoteConfigExists, setRemoteConfigExists] = useState({
		bonusConfig: false,
		referralsConfig: false,
		employeeConfig: false,
	});

	// All frequencies default to TYPE: WEEKLY and VALUE: MONDAYS if not set at the company level
	const [referralsReportFrequencyValue, setReferralsReportFrequencyValue] =
		useState(
			props?.company?.referralsReportFrequency ?? FREQUENCY_VALUE.MONDAYS
		);
	const [referralsReportFrequencyType, setReferralsReportFrequencyType] =
		useState(
			FREQUENCY_MAP?.[props?.company?.referralsReportFrequency]?.type ??
				FREQUENCY_TYPE.WEEKLY
		);
	const [employeeReportFrequencyValue, setEmployeeReportFrequencyValue] =
		useState(
			props?.company?.employeeReportFrequency ?? FREQUENCY_VALUE.MONDAYS
		);
	const [employeeReportFrequencyType, setEmployeeReportFrequencyType] =
		useState(
			FREQUENCY_MAP?.[props?.company?.employeeReportFrequency]?.type ??
				FREQUENCY_TYPE.WEEKLY
		);
	const [bonusReportFrequencyValue, setBonusReportFrequencyValue] = useState(
		props?.company?.bonusReportFrequency ?? FREQUENCY_VALUE.MONDAYS
	);
	const [bonusReportFrequencyType, setBonusReportFrequencyType] = useState(
		FREQUENCY_MAP?.[props?.company?.bonusReportFrequency]?.type ??
			FREQUENCY_TYPE.WEEKLY
	);

	// Reporting Date Ranges
	const [referralsReportDateRange, setReferralsReportDateRange] = useState(
		props?.company?.referralsReportDateRange ?? REPORT_DATE_RANGE.TWO_YEARS
	);

	/** Get and set report headers */
	useEffect(() => {
		const fetchReportHeaders = async () => {
			let data;

			try {
				data = await fetchS3ConfigData('ALL');
			} catch (error) {
				console.error(error);
			} finally {
				const {
					referralsReportConfigured = null,
					referralsReportDefault,
					referralsReportCustom = null,
					employeeReportConfigured = null,
					employeeReportDefault,
					employeeReportCustom = null,
					bonusReportConfigured,
					bonusReportDefault = null,
					bonusReportCustom = null,
				} = data;
				handleReferralsReportConfigData({
					referralsReportConfigured,
					referralsReportCustom,
					referralsReportDefault,
				});
				handleEmployeeReportConfigData({
					employeeReportConfigured,
					employeeReportCustom,
					employeeReportDefault,
				});
				handleBonusReportConfigData({
					bonusReportConfigured,
					bonusReportCustom,
					bonusReportDefault,
				});
				setIsLoading(false);
			}
		};

		fetchReportHeaders();
	}, []);

	/** Temporary workaround for open @dndkit issue https://github.com/clauderic/dnd-kit/issues/1025 */
	useEffect(() => {
		window.addEventListener('error', (e) => {
			if (
				e.message === 'ResizeObserver loop limit exceeded' ||
				e.message ===
					'ResizeObserver loop completed with undelivered notifications.'
			) {
				const resizeObserverErrorDiv = document.querySelector(
					'#webpack-dev-server-client-overlay-div'
				);
				const resizeObserverError = document.querySelector(
					'#webpack-dev-server-client-overlay'
				);
				if (resizeObserverError) {
					resizeObserverError.setAttribute('style', 'display: none');
				}

				if (resizeObserverErrorDiv) {
					resizeObserverErrorDiv.setAttribute('style', 'display: none');
				}
			}
		});
	}, []);

	/** Get report config data from S3 */
	const fetchS3ConfigData = async (reportType) => {
		try {
			const fileName = `${props.companyId}.json`;
			const bucket = 'report-builder-config-erin';
			const defaultFileName = `defaultHeaderDefinitions.json`;
			const customKey = `customHeaderDefinitions/${fileName}`;
			const configuredKey = `configuredHeaders/${fileName}`;
			const referralsReportFiles = [
				{
					bucket,
					key: `referralsReport/${defaultFileName}`,
					name: 'referralsReportDefault',
				},
				{
					bucket,
					key: `referralsReport/${customKey}`,
					name: 'referralsReportCustom',
				},
				{
					bucket,
					key: `referralsReport/${configuredKey}`,
					name: 'referralsReportConfigured',
				},
			];
			const employeeReportFiles = [
				{
					bucket,
					key: `employeeReport/${defaultFileName}`,
					name: 'employeeReportDefault',
				},
				{
					bucket,
					key: `employeeReport/${customKey}`,
					name: 'employeeReportCustom',
				},
				{
					bucket,
					key: `employeeReport/${configuredKey}`,
					name: 'employeeReportConfigured',
				},
			];
			const bonusReportFiles = [
				{
					bucket,
					key: `bonusReport/${defaultFileName}`,
					name: 'bonusReportDefault',
				},
				{
					bucket,
					key: `bonusReport/${customKey}`,
					name: 'bonusReportCustom',
				},
				{
					bucket,
					key: `bonusReport/${configuredKey}`,
					name: 'bonusReportConfigured',
				},
			];
			let files;

			switch (reportType) {
				case REPORT_TYPES.REFERRALS_REPORT: {
					files = referralsReportFiles;
					break;
				}

				case REPORT_TYPES.EMPLOYEE_REPORT: {
					files = employeeReportFiles;
					break;
				}

				case REPORT_TYPES.BONUS_REPORT: {
					files = bonusReportFiles;
					break;
				}

				case 'ALL': {
					files = [
						...referralsReportFiles,
						...employeeReportFiles,
						...bonusReportFiles,
					];
					break;
				}

				default: {
					throw new Error(
						`Unable to fetch config data. Report Type: ${reportType} not found.`
					);
				}
			}

			return await lambda({
				endpoint: 'getS3Objects',
				variables: {
					userId: props.currentUser.id,
					files,
				},
			});
		} catch (error) {
			console.error(error);
			throw new Error(error);
		}
	};

	/** Set Referrals Report Config Data.
	 *
	 * If previously configured data exists, use it.
	 * Otherwise, if custom headers exist, combine them with the default headers.
	 * 		- Default headers are replaced by any custom header with the same `default` value.
	 * Otherwise, use the default headers.
	 */
	const handleReferralsReportConfigData = ({
		referralsReportConfigured,
		referralsReportCustom,
		referralsReportDefault,
	}) => {
		if (referralsReportConfigured && referralsReportConfigured?.data) {
			setReferralsConfig(referralsReportConfigured);
			if (props.currentUser.role === USER_ROLES.SUPER_ADMIN) {
				setRemoteConfigExists((backupConfigs) => ({
					...backupConfigs,
					referralsConfig: true,
				}));
			}
		} else if (referralsReportCustom && referralsReportCustom?.data) {
			// Combine headers, overriding header from reportDefault if custom has header with same default value
			const defaultSelected = [...referralsReportDefault.data.selected];
			const customSelected = [...referralsReportCustom.data.selected];
			const overrideDefaults = new Set(
				customSelected.map((header) => header.default)
			);

			setReferralsConfig({
				data: {
					selected: [
						...defaultSelected.map((header) =>
							overrideDefaults.has(header.default)
								? customSelected.find(
										(customHeader) => customHeader.default === header.default
									)
								: header
						),
						...customSelected.filter(
							(customHeader) =>
								!defaultSelected.some(
									(header) => header.default === customHeader.default
								)
						),
					],
					unused: [
						...referralsReportDefault.data.unused,
						...referralsReportCustom.data.unused,
					],
				},
			});
		} else {
			setReferralsConfig(referralsReportDefault);
		}
	};

	/** Set Employee Report Config Data.
	 *
	 * If previously configured data exists, use it.
	 * Otherwise, if custom headers exist, combine them with the default headers.
	 * 		- Default headers are replaced by any custom header with the same `default` value.
	 * Otherwise, use the default headers.
	 */
	const handleEmployeeReportConfigData = ({
		employeeReportConfigured,
		employeeReportCustom,
		employeeReportDefault,
	}) => {
		if (employeeReportConfigured?.data) {
			setEmployeeConfig(employeeReportConfigured);
			if (currentUser.role === USER_ROLES.SUPER_ADMIN) {
				setRemoteConfigExists((backupConfigs) => ({
					...backupConfigs,
					employeeConfig: true,
				}));
			}
		} else if (employeeReportCustom?.data) {
			// Combine headers, overriding header from reportDefault if custom has header with same default value
			const defaultSelected = [...employeeReportDefault.data.selected];
			const customSelected = [...employeeReportCustom.data.selected];
			const overrideDefaults = new Set(
				customSelected.map((header) => header.default)
			);

			setEmployeeConfig({
				data: {
					selected: [
						...defaultSelected.map((header) =>
							overrideDefaults.has(header.default)
								? customSelected.find(
										(customHeader) => customHeader.default === header.default
									)
								: header
						),
						...customSelected.filter(
							(customHeader) =>
								!defaultSelected.some(
									(header) => header.default === customHeader.default
								)
						),
					],
					unused: [
						...employeeReportDefault.data.unused,
						...employeeReportCustom.data.unused,
					],
				},
			});
		} else {
			setEmployeeConfig(employeeReportDefault);
		}
	};

	/** Set Bonuss Report Config Data.
	 *
	 * If previously configured data exists, use it.
	 * Otherwise, if custom headers exist, combine them with the default headers.
	 * 		- Default headers are replaced by any custom header with the same `default` value.
	 * Otherwise, use the default headers.
	 */
	const handleBonusReportConfigData = ({
		bonusReportConfigured,
		bonusReportCustom,
		bonusReportDefault,
	}) => {
		if (bonusReportConfigured?.data) {
			setBonusConfig(bonusReportConfigured);
			if (currentUser.role === USER_ROLES.SUPER_ADMIN) {
				setRemoteConfigExists((backupConfigs) => ({
					...backupConfigs,
					bonusConfig: true,
				}));
			}
		} else if (bonusReportCustom?.data) {
			// Combine headers, overriding header from reportDefault if custom has header with same default value
			const defaultSelected = [...bonusReportDefault.data.selected];
			const customSelected = [...bonusReportCustom.data.selected];
			const overrideDefaults = new Set(
				customSelected.map((header) => header.default)
			);

			setBonusConfig({
				data: {
					selected: [
						...defaultSelected.map((header) =>
							overrideDefaults.has(header.default)
								? customSelected.find(
										(customHeader) => customHeader.default === header.default
									)
								: header
						),
						...customSelected.filter(
							(customHeader) =>
								!defaultSelected.some(
									(header) => header.default === customHeader.default
								)
						),
					],
					unused: [
						...bonusReportDefault.data.unused,
						...bonusReportCustom.data.unused,
					],
				},
			});
		} else {
			setBonusConfig(bonusReportDefault);
		}
	};

	/** Delete report config from S3 */
	const deleteRemoteConfig = async (reportType) => {
		return new Promise(async (resolve, reject) => {
			try {
				const bucket = 'report-builder-config-erin';
				const keyPrefix = reportType
					.split(' ')
					.map((word, index) =>
						index === 0 ? word.toLowerCase() : initialCaps(word)
					)
					.join('');
				const key = `${keyPrefix}/configuredHeaders/${props.companyId}.json`;
				const config = {
					endpoint: 'deleteS3Object',
					variables: {
						userId: props.currentUser.id,
						files: [
							{
								bucket,
								key,
								name: 'deleteRequest',
							},
						],
					},
				};

				const response = await lambda(config);
				await updateRemoteConfig(reportType);
				resolve(response);
			} catch (error) {
				console.error(error);
				reject(error);
			}
		});
	};

	/** Gets presigned URL for report config from S3. Prompts user for download as .json file */
	const exportRemoteConfig = async (reportType) => {
		return new Promise(async (resolve, reject) => {
			try {
				const bucket = 'report-builder-config-erin';
				const keyPrefix = reportType
					.split(' ')
					.map((word, index) =>
						index === 0 ? word.toLowerCase() : initialCaps(word)
					)
					.join('');
				const key = `${keyPrefix}/configuredHeaders/${props.companyId}.json`;
				const config = {
					endpoint: 'getPresignedURL',
					variables: {
						userId: props.currentUser.id,
						files: [
							{
								bucket,
								key,
								name: 'presignedURL',
								fileName: `${props.companyId}-${reportType
									.toLowerCase()
									.replace(' ', '-')}-config-${Date.now()}.json`,
								isAttachment: true,
							},
						],
					},
				};

				const downloader = document.createElement('a');
				const link = await lambda(config);
				downloader.href = link.presignedURL;
				downloader.download = config.variables.files[0].fileName;

				document.body.append(downloader);
				downloader.click();

				downloader.remove();
				resolve('Download initiated');
			} catch (error) {
				console.error(error);
				reject(error);
			}
		});
	};

	/** Manages state for remote config existence, used to enable/disable Super Admin functionality.
	 *
	 * If remote config is deleted, get custom and default headers to reset UI.
	 * If config is passed as an argument, consider it to exist
	 */
	const updateRemoteConfig = async (reportType, config = null) => {
		try {
			switch (reportType) {
				case REPORT_TYPES.REFERRALS_REPORT: {
					setRemoteConfigExists((remoteConfigs) => ({
						...remoteConfigs,
						referralsConfig: Boolean(config),
					}));

					if (config) {
						handleReferralsReportConfigData({
							referralsReportConfigured: config,
						});
					} else {
						const {
							referralsReportConfigured = null,
							referralsReportDefault,
							referralsReportCustom = null,
						} = await fetchS3ConfigData('ALL');
						handleReferralsReportConfigData({
							referralsReportConfigured,
							referralsReportDefault,
							referralsReportCustom,
						});
					}

					break;
				}

				case REPORT_TYPES.EMPLOYEE_REPORT: {
					setRemoteConfigExists((remoteConfigs) => ({
						...remoteConfigs,
						employeeConfig: Boolean(config),
					}));

					if (config) {
						handleEmployeeReportConfigData({
							employeeReportConfigured: config,
						});
					} else {
						const {
							employeeReportConfigured = null,
							employeeReportDefault,
							employeeReportCustom = null,
						} = await fetchS3ConfigData('ALL');
						handleEmployeeReportConfigData({
							employeeReportConfigured,
							employeeReportDefault,
							employeeReportCustom,
						});
					}

					break;
				}

				case REPORT_TYPES.BONUS_REPORT: {
					setRemoteConfigExists((remoteConfigs) => ({
						...remoteConfigs,
						bonusConfig: Boolean(config),
					}));

					if (config) {
						handleBonusReportConfigData({
							bonusReportConfigured: config,
						});
					} else {
						const {
							bonusReportConfigured = null,
							bonusReportDefault,
							bonusReportCustom = null,
						} = await fetchS3ConfigData('ALL');
						handleBonusReportConfigData({
							bonusReportConfigured,
							bonusReportDefault,
							bonusReportCustom,
						});
					}

					break;
				}

				default: {
					console.error(
						`Unable to update backup config. Unknown report type: ${reportType}`
					);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	/** Switches Delivery Date to default value when Delivery Frequency is changed */
	const handleFrequencyTypeChange = (selection, reportType) => {
		const notificationFrequency =
			selection === FREQUENCY_TYPE.WEEKLY
				? FREQUENCY_VALUE.MONDAYS
				: selection === FREQUENCY_TYPE.MONTHLY
					? FREQUENCY_VALUE.FIRST
					: FREQUENCY_VALUE.DAILY;

		switch (reportType) {
			case REPORT_TYPES.REFERRALS_REPORT: {
				setReferralsReportFrequencyType(selection);
				setReferralsReportFrequencyValue(notificationFrequency);
				break;
			}

			case REPORT_TYPES.EMPLOYEE_REPORT: {
				setEmployeeReportFrequencyType(selection);
				setEmployeeReportFrequencyValue(notificationFrequency);
				break;
			}

			case REPORT_TYPES.BONUS_REPORT: {
				setBonusReportFrequencyType(selection);
				setBonusReportFrequencyValue(notificationFrequency);
				break;
			}

			default: {
				break;
			}
		}
	};

	const { currentUser, allMultiLingualData } = props;

	/** Report Builder UI */
	const tabs = [
		{
			title: 'Referrals Report',
			child: (
				<ReportBuilderForm
					type={REPORT_TYPES.REFERRALS_REPORT}
					config={referralsConfig}
					currentUser={currentUser}
					allMultiLingualData={allMultiLingualData}
					notificationFrequency={referralsReportFrequencyValue}
					notificationType={referralsReportFrequencyType}
					handleNotificationFrequencyChange={(value) =>
						setReferralsReportFrequencyValue(value)
					}
					handleNotificationTypeChange={(selection) => {
						handleFrequencyTypeChange(selection, REPORT_TYPES.REFERRALS_REPORT);
					}}
					reportDateRange={referralsReportDateRange}
					handleReportDateRangeChange={(value) => {
						setReferralsReportDateRange(value);
					}}
					deleteConfig={async () =>
						await deleteRemoteConfig(REPORT_TYPES.REFERRALS_REPORT)
					}
					exportConfig={async () =>
						await exportRemoteConfig(REPORT_TYPES.REFERRALS_REPORT)
					}
					updateRemoteConfig={updateRemoteConfig}
					disableBackup={!remoteConfigExists.referralsConfig}
					{...props}
				/>
			),
			ref: useRef(null),
		},
		{
			title: 'Employee Report',
			child: (
				<ReportBuilderForm
					type={REPORT_TYPES.EMPLOYEE_REPORT}
					config={employeeConfig}
					currentUser={currentUser}
					allMultiLingualData={allMultiLingualData}
					notificationFrequency={employeeReportFrequencyValue}
					notificationType={employeeReportFrequencyType}
					handleNotificationFrequencyChange={(value) =>
						setEmployeeReportFrequencyValue(value)
					}
					handleNotificationTypeChange={(selection) =>
						handleFrequencyTypeChange(selection, REPORT_TYPES.EMPLOYEE_REPORT)
					}
					deleteConfig={async () =>
						await deleteRemoteConfig(REPORT_TYPES.EMPLOYEE_REPORT)
					}
					exportConfig={async () =>
						await exportRemoteConfig(REPORT_TYPES.EMPLOYEE_REPORT)
					}
					updateRemoteConfig={updateRemoteConfig}
					disableBackup={!remoteConfigExists.employeeConfig}
					{...props}
				/>
			),
			ref: useRef(null),
		},
		{
			title: 'Bonus Report',
			child: (
				<ReportBuilderForm
					type={REPORT_TYPES.BONUS_REPORT}
					config={bonusConfig}
					currentUser={currentUser}
					allMultiLingualData={allMultiLingualData}
					notificationFrequency={bonusReportFrequencyValue}
					notificationType={bonusReportFrequencyType}
					handleNotificationFrequencyChange={(value) =>
						setBonusReportFrequencyValue(value)
					}
					handleNotificationTypeChange={(selection) =>
						handleFrequencyTypeChange(selection, REPORT_TYPES.BONUS_REPORT)
					}
					deleteConfig={async () =>
						await deleteRemoteConfig(REPORT_TYPES.BONUS_REPORT)
					}
					exportConfig={async () =>
						await exportRemoteConfig(REPORT_TYPES.BONUS_REPORT)
					}
					updateRemoteConfig={updateRemoteConfig}
					disableBackup={!remoteConfigExists.bonusConfig}
					{...props}
				/>
			),
			ref: useRef(null),
		},
	];

	return (
		<main>
			<div className="page-title">
				<h2 className="page-heading">Report Builder</h2>
			</div>
			{isLoading ? (
				<Spinner />
			) : (
				<TabList label="Report Builder" tabValues={tabs} />
			)}
		</main>
	);
}

import _, { get } from 'lodash';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import mixpanel from 'mixpanel-browser';
import { GetCompanyByPartnerClientId } from 'src/_shared/api/graphql/custom/company/';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { GetAccountClaimByEmployeeIdCompanyId } from 'src/graphql/custom/account-claims/';
import ErinLogo from '../_shared/assets/erinwhite.png';
import { GetCompanySettings } from '../settings-dashboard/custom/company';
import PoweredBy from '../_shared/assets/poweredbyERIN.png';
import {
	downloadFromS3Signed,
	findItemByNameAndKeywords,
	getUpdatedUserFromSAMLAttributes,
	graphql,
	parse,
} from '../_shared/services/utils.js';
import NewSamlUserForm from './new-user-items/NewSamlUserFormComponent.jsx';
import {
	Card,
	LandingPageContainer,
	Logo,
	NavBar,
	TitleStyles,
	UnauthTextStyles,
} from './newUserLandingStyles.js';

class NewSamlUserLandingPageComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			authorized: true,
			company: this.props?.partnerHostCompany,
			companyId: get(this.props, 'match.params.id'),
			departments: get(this.props, 'departments', []),
			token: get(this.props, 'location.state.token'),
			redirectURL: get(this.props, 'location.state.redirectURL'),
			loading: true,
		};
	}

	async componentDidMount() {
		const token = get(this.state, 'token');
		const roles = get(token, 'custom:role', '').split(',');
		const samlProvider = get(token, 'identities[0].providerName');
		let company;
		if (samlProvider === 'TalentReef') {
			const partnerClientId = get(token, 'custom:client_id', 'skip');
			try {
				const getCompany = await this.props.client.query({
					query: GetCompanyByPartnerClientId,
					variables: {
						partnerClientId,
					},
				});
				company = get(getCompany, 'data.getCompanyByPartnerClientId');
				if (company) {
					this.setCompany(company, token);
				}
			} catch (error) {
				console.log(error);
			}

			if (!company && this.hasPermissions(roles)) {
				// Create new Company if roles match
				company = await this.createPartnerCompany(token);
			} else if (!company && !this.hasPermissions(roles)) {
				this.setState({
					authorized: false,
				});
				if (get(this.props, 'partnerHostCompany'))
					this.setCompany(get(this.props, 'partnerHostCompany'));
			}
		} else if (get(this.state, 'companyId')) {
			try {
				const companySettings = await this.props.client.query({
					query: GetCompanySettings,
					variables: {
						id: company ? company.id : this.state.companyId,
					},
				});
				company = get(companySettings, 'data.getCompany');
				this.setCompany(company, token);
			} catch (error) {
				console.log(error);
			}
		}

		let logoSource;
		const logo = get(company, 'logo.key', false);
		if (logo) {
			logoSource = await downloadFromS3Signed(logo, 'erin-images');
		}

		const styles = await LandingPageContainer(company);
		const saml = await graphql({
			input: { companyId: company?.id },
			query: 'getSAMLAuthByCompanyId',
		});
		this.setState({ logoSrc: logoSource, saml, styles });
		if (saml?.autoCreateUsers) {
			this.autoSubmit();
		} else {
			this.setState({ loading: false });
		}
	}

	getAccountClaimByEmployeeCompanyId = async (employeeId, companyId) => {
		return new Promise(async (resolve, reject) => {
			try {
				const { client } = this.props;
				const { data } = await client.query({
					query: GetAccountClaimByEmployeeIdCompanyId,
					variables: { employeeId, companyId },
				});
				const claim = {
					...data.getAccountClaimByEmployeeIdCompanyId,
				};
				return resolve(claim);
			} catch (error) {
				console.log(error);
				return resolve(null);
			}
		});
	};

	setCompany(company, token) {
		let departments = get(company, 'departments');
		departments = departments.filter(
			(department) => department.active === true
		);
		departments = _.sortBy(departments, ['name']);
		const departmentName = get(token, 'custom:department', 'Other');
		let defaultDepartment = findItemByNameAndKeywords(
			departmentName,
			departments
		);
		defaultDepartment ||= departments.find((dep) => dep.name === 'Other');
		const userGroups = get(company, 'userGroups');
		const userGroupName = get(
			token,
			'custom:country',
			get(token, 'custom:userGroup', 'Default')
		);
		let defaultUserGroup = findItemByNameAndKeywords(userGroupName, userGroups);
		defaultUserGroup = defaultUserGroup
			? defaultUserGroup
			: findItemByNameAndKeywords('Default', userGroups);
		const defaultUserGroupId = defaultUserGroup ? defaultUserGroup.id : null;
		this.setState({
			company,
			departments,
			defaultDepartment,
			defaultUserGroupId,
			defaultUserGroup,
			userGroups,
		});
	}

	onAuthentication = async (authToken, currentUser) => {
		const cleanToken = {};
		for (const [key, value] of Object.entries(authToken)) {
			if (!key.includes('custom:')) cleanToken[key] = value;
		}

		const encodedToken = `#access_token=${encodeURIComponent(
			Buffer.from(JSON.stringify(cleanToken)).toString('base64')
		)}`;
		Cookies.set('jwt', encodedToken);
		if (currentUser) {
			this.props.setCurrentUser(currentUser);
		}
	};

	autoSubmit = async () => {
		const {
			onCreate,
			onCreateDepartment,
			onUpdateAccountClaim,
			setCurrentUser,
		} = this.props;
		const { companyId, defaultUserGroupId, saml } = this.state;
		const token = get(this.state, 'token');
		const employeeId =
			token?.['custom:employeeid'] || token?.['custom:userid'] || null;
		const departments = this.props?.departments || [];
		const departmentName = get(token, 'custom:department', '');
		let department = findItemByNameAndKeywords(departmentName, departments);
		if (!department) {
			const input = {
				active: true,
				companyId,
				name: departmentName,
			};
			await onCreateDepartment({
				input,
			}).then((res) => (department = get(res, 'data.createDepartment')));
		}

		const subCompanies = this.props?.subCompanies || [];
		const userGroups = this.props?.userGroups || [];
		let accountClaim = null;
		if (employeeId && companyId) {
			accountClaim = await this.getAccountClaimByEmployeeCompanyId(
				employeeId,
				companyId
			);
		}

		const cognitoId = get(token, 'cognito:username');
		const emailAddress = get(token, 'email', '').toLowerCase();
		const firstName = get(token, 'given_name', '');
		const lastName = get(token, 'family_name', '');
		const title = get(token, 'custom:jobtitle', '');

		const createUser = {
			cognitoId,
			companyId,
			emailAddress,
			role: 'employee',
			firstName,
			lastName,
			title,
			departmentId: department?.id,
			avatar: null,
			lastLogin: dayjs().toISOString(),
			active: true,
			createdById: this.props?.match?.params?.id,
			userGroupId: defaultUserGroupId,
			currency: this.state?.currencyAbbrev || 'USD',
			languageCode: 'US',
		};

		const { input } = getUpdatedUserFromSAMLAttributes({
			accountClaim,
			currentUser: { company: { departments, subCompanies, userGroups } },
			token,
			createUser,
		});

		if (emailAddress && firstName && lastName && title && department) {
			this.setState({ submitting: true });
			try {
				onCreate({ input }).then((user) => {
					const currentUser = get(user, 'data.createUser');
					const redirectURL = get(this.state, 'redirectURL');
					const userId = get(currentUser, 'id');
					if (accountClaim?.id) {
						onUpdateAccountClaim({
							input: {
								id: accountClaim.id,
								claimed: true,
								userId,
							},
						});
					}

					setCurrentUser(currentUser);
					mixpanel.identify(currentUser.id);
					mixpanel.register({
						'Company Name': currentUser?.company?.name,
						'Company ID': currentUser?.company?.id,
					});
					mixpanel.track('Account Created');
					mixpanel.track('Logged In');
					localStorage.setItem('mixpanel_user_identified', 'true');
					this.onAuthentication(token, currentUser);
					window.location.href = '/dashboard';
				});
			} catch (error) {
				this.setState({ serverError: true, submitting: false });
				console.error(error);
			}
		} else {
			this.setState({ loading: false });
		}
	};

	async createPartnerCompany(token) {
		return new Promise(async (resolve, reject) => {
			try {
				const { id, onCreateCompany, onCreateDepartment, onCreateUserGroup } =
					this.props;
				let partnerHostCompany = get(this.props, 'partnerHostCompany');
				if (!partnerHostCompany) {
					const partnerHostCompanyData = await this.props.client.query({
						query: GetCompanySettings,
						variables: {
							id,
						},
					});
					partnerHostCompany = get(partnerHostCompanyData, 'data.getCompany');
				}

				if (!partnerHostCompany) return;
				const name = get(token, 'custom:client_name');
				const partnerClientId = get(token, 'custom:client_id');
				const variables = {
					input: {
						name,
						partnerClientId,
						partnerHostId: partnerHostCompany.id,
						whiteLabel: true,
						accountType: 'partner',
						pointsSettings: JSON.stringify({
							enabled: true,
							isStoreEnabled: true,
						}),
					},
				};
				if (get(partnerHostCompany, 'logo'))
					variables.input.logo = partnerHostCompany.logo;
				if (get(partnerHostCompany, 'background'))
					variables.input.background = partnerHostCompany.background;
				if (get(partnerHostCompany, 'symbol'))
					variables.input.symbol = partnerHostCompany.symbol;
				if (get(partnerHostCompany, 'errorImage'))
					variables.input.errorImage = partnerHostCompany.errorImage;
				if (get(partnerHostCompany, 'theme'))
					variables.input.theme = partnerHostCompany.theme;
				if (get(partnerHostCompany, 'brandColor'))
					variables.input.brandColor = partnerHostCompany.brandColor;
				if (get(partnerHostCompany, 'giftCardStoreAPIKeys'))
					variables.input.giftCardStoreAPIKeys =
						partnerHostCompany.giftCardStoreAPIKeys;
				if (get(partnerHostCompany, 'senderEmailAddress')) {
					variables.input.senderEmailAddress =
						partnerHostCompany.senderEmailAddress;
				}

				const { atsIntegration } = await graphql(
					{
						input: { id: partnerHostCompany.id },
						query: 'getATSSettingsByCompany',
					},
					'cognito'
				);

				if (atsIntegration) {
					atsIntegration[0].ATSClientId = partnerClientId;
					await graphql(
						{
							input: {
								companyId: partnerHostCompany?.id,
								atsIntegration: JSON.stringify(atsIntegration),
							},
							update: 'updateATSIntegration',
						},
						'cognito'
					);
				}

				const companyData = await onCreateCompany(variables);
				let company = get(companyData, 'data.createCompany');
				await onCreateDepartment({
					input: {
						companyId: company.id,
						name: 'Other',
						active: true,
					},
				});
				await onCreateUserGroup({
					input: {
						companyId: company.id,
						name: 'Default',
						active: true,
						currency: 'USD',
					},
				});
				await onCreateUserGroup({
					input: {
						companyId: company.id,
						name: 'External',
						active: true,
					},
				});
				const getCompany = await this.props.client.query({
					query: GetCompanyByPartnerClientId,
					variables: {
						partnerClientId,
					},
				});
				if (get(getCompany, 'data.getCompanyByPartnerClientId')) {
					company = get(getCompany, 'data.getCompanyByPartnerClientId');
					this.setCompany(company);
				}

				resolve(company);
			} catch (error) {
				this.setState({ serverError: true });
				console.error(error);
			}
		});
	}

	hasPermissions(roles = []) {
		const result = Boolean(roles.find((role) => role === 'ATSEdit'));
		return result;
	}

	render() {
		const { allMultiLingualData, userInvite } = this.props;
		const {
			authorized,
			defaultDepartment,
			departments,
			companyId,
			defaultUserGroupId,
			defaultUserGroup,
			loading,
			logoSrc,
			styles,
		} = this.state;
		if (!companyId || !departments) {
			return (
				<div style={{ height: '100vh', paddingTop: '45%' }}>
					<Spinner forceDefault />
				</div>
			);
		}

		const whiteLabel = get(this.state, 'company.whiteLabel');
		const theme = get(this.state, 'company.theme')
			? parse(get(this.state, 'company.theme'))
			: null;
		const lblDepartment = get(this.state, 'company.labelDepartment')
			? get(this.state, 'company.labelDepartment')
			: 'Department';
		const lblCompany = get(this.state, 'company.subCompanyLabel')
			? get(this.state, 'company.subCompanyLabel')
			: 'Company';
		return loading ? (
			<div style={{ height: '100vh', paddingTop: '45%' }}>
				<Spinner forceDefault />
			</div>
		) : (
			<div className={styles}>
				<div style={{ textAlign: 'center' }}>
					{whiteLabel ? (
						<img className={Logo} src={logoSrc} alt="logo" width="350px" />
					) : !whiteLabel && logoSrc !== false ? (
						<div style={{ textAlign: 'center' }}>
							<img className={Logo} src={logoSrc} alt="logo" />
							<div>
								<img alt="logo" style={{ width: 200 }} src={PoweredBy} />
							</div>
						</div>
					) : (
						<img className={Logo} src={ErinLogo} alt="Erin Logo" />
					)}
				</div>

				{authorized ? (
					<div className={Card}>
						<NewSamlUserForm
							allMultiLingualData={allMultiLingualData}
							hasPermissions={this.hasPermissions}
							company={this.state.company}
							defaultUserGroupId={defaultUserGroupId}
							defaultUserGroup={defaultUserGroup}
							token={this.state.token}
							redirectURL={this.state.redirectURL}
							setCurrentUser={this.props.setCurrentUser}
							userInvite={userInvite}
							departments={departments}
							defaultDepartment={defaultDepartment}
							whiteLabel={whiteLabel}
							theme={theme}
							lblDepartment={lblDepartment}
							lblCompany={lblCompany}
							onCreate={this.props.onCreate}
							onUpdateInvite={this.props.onUpdateInvite}
							onUpdateAccountClaim={this.props.onUpdateAccountClaim}
						/>
					</div>
				) : (
					<div className={Card} style={{ flexDirection: 'column' }}>
						<h1 className={TitleStyles} style={{ marginBottom: 10 }}>
							We're Sorry!
						</h1>
						<h1 className={UnauthTextStyles}>
							This client has not been created yet.
						</h1>
						<h1 className={UnauthTextStyles}>Please check back later!</h1>
					</div>
				)}

				<span className={NavBar}>
					Need Assistance?{' '}
					<a
						href="https://erinapp.com/support"
						target="_blank"
						rel="noopener noreferrer"
					>
						Contact Erin Support
					</a>
				</span>
			</div>
		);
	}
}

export default withApollo(NewSamlUserLandingPageComponent);

import { Card, Tooltip } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import dayjs from 'dayjs';
import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { Link } from 'react-router-dom';
import totalReferral from '../../_shared/assets/icon-graph.svg';
import {
	calculateReferralBonus,
	mapReferralStatus,
	ml,
} from '../../_shared/services/utils.js';
import {
	generalContainer,
	generalText,
	jobCardMain,
	topContainerBorder,
} from './jobCardStyles.js';

class JobCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			matches: 0,
			loading: !get(props, 'job.jobType'),
			currencyRate: 1,
			currencySymbol: '$',
			translatedTitle: '',
			translatedDescription: '',
		};
	}

	async componentDidMount() {
		const job = get(this.props, 'job');
		const jobMatches = get(this.props, 'matches.jobMatches', []);
		if (job) {
			const matches = jobMatches.filter(
				(jobMatch) =>
					get(jobMatch, 'relevance') >= 30 &&
					get(jobMatch, 'matchStatus') !== false
			);
			this.setState({ matches });
		}

		if (get(this.props, 'job.jobType')) {
			this.setState({ loading: false });
		}

		const newTitle = get(job, 'title');
		this.setState({
			translatedTitle: newTitle,
		});
	}

	async componentDidUpdate(prevProps) {
		if (get(this.props, 'job.jobType') && this.state.loading) {
			this.setState({ loading: false });
		}

		if (
			prevProps.currentUser.languageCode !== this.props.currentUser.languageCode
		) {
			const newTitle = get(this.props, 'job.title');
			this.setState({
				translatedTitle: newTitle,
			});
		}
	}

	render() {
		const {
			allMultiLingualData,
			contactIncentiveBonus,
			contacts,
			currentUser,
			job,
			bonus,
		} = this.props;

		let isHotJob = get(job, 'isHotJob', false);
		const campaignId = get(job, 'campaignId');
		const campaign = get(job, 'campaign');
		let currentTieredBonus = '';
		let isActive = false;
		let archived;
		if (campaignId) {
			const today = dayjs();
			const startDate = get(campaign, 'startDate');
			const endDate = get(campaign, 'endDate');
			archived = get(campaign, 'archived', false);
			if (today.isBetween(startDate, endDate, 'day', '[]')) {
				isActive = true;
				isHotJob = true;
			} else {
				isActive = false;
				isHotJob = false;
			}
		}

		if (archived) {
			isActive = false;
			isHotJob = false;
		}

		if (get(job, 'isHotJob')) isHotJob = true;
		currentTieredBonus = isActive
			? get(job, 'campaign.tieredBonus')
			: get(this.props, 'job.tieredBonus');
		const id = get(this.props, 'job.id');
		const department = get(this.props, 'job.department');
		const shares = get(this.props, 'job.shares');

		let views = get(this.props, 'job.views');
		if (views === null) {
			views = '0';
		}

		const externalJobId = get(this.props, 'job.externalJobId');
		const referralsAmount = get(this.props, 'job.referralsAmount');

		let currencyCode = get(currentUser, 'userGroup.currency');
		if (currencyCode === null) currencyCode = 'USD';
		const symbol = getSymbolFromCurrency(currencyCode)
			? getSymbolFromCurrency(currencyCode)
			: '$';
		const { loading, matches } = this.state;

		const _referrals = get(this.props, 'job.referralsAmount', 0);
		const _accepted = get(this.props, 'job.accepted', 0);
		const referralBonus = get(this.props, 'job.referralBonus');
		const location = get(this.props, 'job.job.location');
		const shouldShowBonusAmount = get(referralBonus, 'hasBonus');
		const getBonusAmount = (
			contactIncentiveBonus,
			referralBonusAmount,
			contacts,
			currentTieredBonus,
			employee,
			defaultUserGroup
		) => {
			const bonusValue = calculateReferralBonus(
				contactIncentiveBonus,
				referralBonusAmount,
				contacts,
				currentTieredBonus,
				employee,
				defaultUserGroup
			);
			if (bonusValue > 0) {
				return `${symbol}${bonusValue}`.replaceAll(
					/\B(?=(\d{3})+(?!\d))/g,
					','
				);
			}

			return 'None';
		};

		const jobCardMainClass = isHotJob ? topContainerBorder : jobCardMain;
		const locations = get(this.props, 'job.job.locations', []);
		const isGeneralReferral = get(job, 'isGeneralReferral', false);
		const generalReferralJob = () => {
			return (
				<Tooltip
					arrowPointAtCenter
					placement="topRight"
					title={ml(
						'General Jobs represent multiple openings. A new referral and bonus (if applicable) will be created when the candidate is moved to a different job.',
						currentUser,
						allMultiLingualData
					)}
					style={{ fontWeight: 1000, fontStyle: 'italic' }}
				>
					<div className={generalContainer}>
						<span className={generalText}>General Job</span>
					</div>
				</Tooltip>
			);
		};

		return (
			<Card
				bordered={false}
				bodyStyle={{ padding: 0, height: '100%' }}
				className="job-card"
			>
				<div className="job-card-inner">
					{true && (
						<div className="job-card-header">
							<div className="job-title-wrap">
								<h4 className="job-title">
									<Link to={`/jobs/${id}`}>{this.state.translatedTitle}</Link>
								</h4>

								{isHotJob == true ? (
									<ul className="actions">
										<li>
											<i className="icon-fire text-orange hot-job-icon" />
										</li>
									</ul>
								) : null}
							</div>
							<div className="job-info-wrap">
								<ul className="job-info">
									<li>
										{location?.city ||
										location?.state ||
										location?.country ||
										location?.isRemote === true ? (
											<i className="icon-location" />
										) : null}
										<span>
											{locations?.length > 1
												? ml(
														'Multiple Locations',
														currentUser,
														allMultiLingualData
													)
												: location?.isRemote === true
													? ml('Remote', currentUser, allMultiLingualData)
													: [location?.city, location?.state]
															.filter(Boolean)
															.join(', ') ||
														location?.country ||
														''}
										</span>
									</li>
									{get(department, 'name') && (
										<li>
											<i className="icon-folder" />
											<span>{get(department, 'name', '')}</span>
										</li>
									)}
								</ul>
							</div>

							{isGeneralReferral ? (
								<div
									style={{
										display: 'flex',
										paddingLeft: 5,
									}}
								>
									{generalReferralJob()}{' '}
								</div>
							) : (
								shouldShowBonusAmount && (
									<div className="job-bonus">
										{bonus + ': '}
										{currentTieredBonus ? (
											<span className="job-bonus-amt">
												{get(currentTieredBonus, 'name')}
											</span>
										) : (
											<span className="job-bonus-amt">
												{getBonusAmount(
													contactIncentiveBonus,
													get(referralBonus, 'amount'),
													contacts,
													currentTieredBonus,
													'employee',
													get(currentUser, 'userGroup.id')
												)}
											</span>
										)}
									</div>
								)
							)}
							{externalJobId &&
								externalJobId !== true &&
								externalJobId !== null &&
								externalJobId !== '' && (
									<div className="job-id">
										{'ID#: '}
										{externalJobId}
									</div>
								)}
						</div>
					)}
					<div className="job-card-footer">
						<ul className="job-status">
							<li>
								<h4 className="job-data"> {_accepted ? _accepted : 0} </h4>
								<span className="job-data-type">
									{mapReferralStatus('accepted', currentUser.company)}
								</span>
							</li>
							<li>
								<h4 className="job-data"> {_referrals ? _referrals : 0} </h4>
								<span className="job-data-type"> Referrals </span>
							</li>
							<li>
								<h4 className="job-data"> {views} </h4>
								<span className="job-data-type">Job Views</span>
							</li>
						</ul>

						<div className="job-card-bottom">
							<div className="card-bottom">
								<i className="icon-share" />
								<span className="name">Job Shares: {shares}</span>
							</div>
							<div className="card-bottom">
								<img
									src={totalReferral}
									style={{ fill: '#fff', width: 14, height: 'auto' }}
								/>
								<span className="name">Total Referrals: {referralsAmount}</span>
							</div>
						</div>
					</div>
				</div>
			</Card>
		);
	}
}

export default withApollo(JobCard);

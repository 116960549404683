import { LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import get from 'lodash/get';
import queryString from 'query-string';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import {
	GetCompanyByHost,
	GetCompanyBySSOGoogleDomain,
} from 'src/_shared/api/graphql/custom/company/';
import {
	GetUserByCognitoId,
	updateUser,
} from 'src/_shared/api/graphql/custom/users/';
import {
	downloadFromS3Signed,
	getEnvironment,
	getUpdatedUserFromSAMLAttributes,
	graphql,
	parse,
} from 'src/_shared/services/utils.js';
import { GetAccountClaimByEmployeeIdCompanyId } from 'src/graphql/custom/account-claims/';
import LoginBackground from '../_shared/assets/Erin_login_background.jpg';
import { slackNotificationAuth } from '../_shared/services';

class SAMLMobileLoginComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			backgroundImage: null,
			user: null,
			page: 1,
			visible: !get(props, 'error'),
			forgotPasswordVisible: false,
			navigate: false,
			userEmail: null,
			token: queryString.parse(this.props.location.hash),
			code: queryString.parse(this.props.location.search),
			theme: parse(get(props, 'currentUser.company.theme', '{}')),
			company: get(props, 'currentUser.company'),
			backgroundImageURL: '',
		};
		this.onAuthentication = this.onAuthentication.bind(this);
	}

	async componentDidMount() {
		const { setCurrentUser } = this.props;
		let { host } = window.location;
		host = host.replace('www.', '');
		if (getEnvironment() !== 'prod') {
			try {
				const { data } = await this.props.client.query({
					query: GetCompanyByHost,
					variables: { host },
				});
				const company = {
					...data.getCompanyByHost,
				};
				const theme = parse(get(company, 'theme', '{}'));
				this.setState({ company, theme });
				// Set background image for whitelabel comapnies
				const key = get(company, 'background.key', false);
				const bucket = get(company, 'background.bucket', false);
				if (key) {
					const url = await downloadFromS3Signed(key, bucket);
					this.setState({ backgroundImageURL: url }, () =>
						this.setBackgroundImage(company)
					);
				} else {
					this.setBackgroundImage(company);
				}
			} catch (error) {
				console.log(error);
			}
		}
		// Redirect back to mobile app for SAML authentication

		const operatingSystem = this.getMobileOperatingSystem();
		if (
			operatingSystem &&
			operatingSystem !== 'Tablet' &&
			get(this.state, 'token.id_token')
		) {
			window.location.replace(`erinapp://token=${this.state.token.id_token}`);
			return;
		}

		const redirectURL = this.props.location.hash;
		const token = this.parseJwt();
		if (token === undefined) {
			this.setState({ visible: true });
		} else {
			const cognitoId = Object.getOwnPropertyDescriptor(
				token,
				'cognito:username'
			).value;
			const email = get(token, 'email', '');
			const emailDomain = email.split('@')[1];
			const employeeId =
				token?.['custom:employeeid'] || token?.['custom:userid'] || null;

			if (cognitoId) {
				try {
					const { data } = await this.props.client.query({
						query: GetUserByCognitoId,
						variables: {
							cognitoId,
						},
					});
					let currentUser = {
						...data.getUserByCognitoId,
						authMethod: 'saml',
						lastLogin: dayjs().toISOString(),
					};

					let accountClaim = null;
					const companyId = get(currentUser, 'companyId');
					if (employeeId && companyId) {
						accountClaim = await this.getAccountClaimByEmployeeCompanyId(
							employeeId,
							companyId
						);
					}

					const { input, updatedUser } = getUpdatedUserFromSAMLAttributes({
						accessToken: this.props.location.hash, // only used for token saml auth
						accountClaim,
						currentUser,
						token,
					});
					currentUser = updatedUser;

					if (data && currentUser && !currentUser.active) {
						const error = new Error('User Disabled');
						error.code = 'UserDisabledException';
						throw error;
					} else {
						setCurrentUser(currentUser);
						this.onAuthentication(this.props.location.hash, currentUser, token);

						this.props.client
							.mutate({
								mutation: gql(updateUser),
								variables: {
									input,
								},
							})
							.then(() => {
								const redirectURL =
									localStorage.getItem('redirectURL') ?? '/dashboard';
								localStorage.removeItem('redirectURL');
								window.location.href = redirectURL;
							});
					}
				} catch (error) {
					const errorMessage = `GraphQL error: Error invoking method 'get(java.lang.Integer)' in java.util.ArrayList at velocity[line 2, column 31]`;
					// Create a new user if one does not exist already.
					const provider = get(token, 'identities[0].providerName');
					let companyId = await this.getCompanyIdFromProvider(provider);
					if (!companyId && provider === 'Google') {
						const company =
							await this.getCompanyIdBySSOGoogleDomain(emailDomain);
						companyId = get(company, 'id', '');
					}

					if (!companyId) {
						const error_ =
							'The provided google account does not match a company on record. Please sign in using your company email address.';
						this.props.history.push({
							pathname: '/login',
							state: {
								err: error_,
							},
						});
					} else if (companyId && error.message === errorMessage) {
						this.props.history.push({
							pathname: `/newsamluser/${companyId}`,
							state: {
								token,
								redirectURL,
							},
						});
					}
				}
			}
		}
	}

	onAuthentication = async (authToken, currentUser, token) => {
		Cookies.set('jwt', authToken);
		this.setState({
			auth: true,
		});
		if (token) {
			try {
				let message = '';
				for (const [key, value] of Object.entries(token)) {
					message += `${key}: ${value} \n`;
				}

				await slackNotificationAuth({
					username: 'errors',
					title: `SAML Mobile Login User: ${get(
						currentUser,
						'firstName'
					)} ${get(currentUser, 'lastName')}: ${get(currentUser, 'id')}`,
					message,
				});
			} catch (error) {
				console.log(error);
			}
		}
	};

	getAccountClaimByEmployeeCompanyId = async (employeeId, companyId) => {
		return new Promise(async (resolve, reject) => {
			try {
				const { client } = this.props;
				const { data } = await client.query({
					query: GetAccountClaimByEmployeeIdCompanyId,
					variables: { employeeId, companyId },
				});
				const claim = {
					...data.getAccountClaimByEmployeeIdCompanyId,
				};
				return resolve(claim);
			} catch {
				return resolve(null);
			}
		});
	};

	getCompanyIdBySSOGoogleDomain = async (ssoGoogleDomain) => {
		const { client } = this.props;
		try {
			const { data } = await client.query({
				query: GetCompanyBySSOGoogleDomain,
				variables: { ssoGoogleDomain },
			});
			const company = {
				...data.getCompanyBySSOGoogleDomain,
			};
			return company;
		} catch {
			return '';
		}
	};

	getCompanyIdFromProvider = async (provider) => {
		const samlProvider = await graphql({
			input: { provider },
			query: 'getSAMLAuthByProvider',
		});
		return samlProvider?.companyId;
	};

	getMobileOperatingSystem = () => {
		const userAgent = navigator.userAgent || navigator.vendor || window.opera;
		if (/android/i.test(userAgent) && !window.MSStream) {
			const width = window.innerWidth || document.body.clientWidth;
			return width > 1000 ? 'Tablet' : 'Android';
		}

		// IOS detection from: http://stackoverflow.com/a/9039885/177710
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			const width = window.innerWidth || document.body.clientWidth;
			return width > 1000 ? 'Tablet' : 'iOS';
		}
	};

	setBackgroundImage = (company) => {
		const env = getEnvironment();
		const { backgroundImageURL } = this.state;
		const whiteLabel = get(company, 'whiteLabel');
		let backgroundImage = null;
		if (env !== 'prod' && whiteLabel && backgroundImageURL) {
			const key = get(company, 'background.key', false);
			backgroundImage = `url(${backgroundImageURL})`;
			this.setState({ backgroundImage });
		} else {
			backgroundImage = `url(${LoginBackground})`;
			this.setState({ backgroundImage });
		}
	};

	parseJwt = () => {
		const token = get(this.state, 'token.id_token');
		if (!token) {
			return;
		}

		const base64Url = token.split('.')[1];
		const base64 = base64Url.replaceAll('-', '+').replaceAll('_', '/');
		const jsonPayload = decodeURIComponent(
			atob(base64)
				.split('')
				.map(function (c) {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join('')
		);

		return parse(jsonPayload);
	};

	render() {
		const { company } = this.state;
		return (
			<div className="custom-loader">
				<LoadingOutlined width={60} height={60} />
			</div>
		);
	}
}

export default withApollo(SAMLMobileLoginComponent);

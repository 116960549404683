import { LoadingOutlined } from '@ant-design/icons';
import { Auth, Cache } from 'aws-amplify';
import axios from 'axios';
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import get from 'lodash/get';
import queryString from 'query-string';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import {
	GetUserByCognitoId,
	updateUser,
} from 'src/_shared/api/graphql/custom/users/';
import {
	auth,
	downloadFromS3Signed,
	getEnvironment,
	getUpdatedUserFromSAMLAttributes,
	graphql,
	parse,
	parseJwt,
} from 'src/_shared/services/utils.js';
import { GetAccountClaimByEmployeeIdCompanyId } from 'src/graphql/custom/account-claims/';
import LoginBackground from '../_shared/assets/Erin_login_background.jpg';
import { slackNotificationAuth } from '../_shared/services';

class SAMLWebLoginComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			company: get(props, 'currentUser.company'),
			backgroundImageURL: '',
		};
		this.onAuthentication = this.onAuthentication.bind(this);
	}

	async componentDidMount() {
		const { setCurrentUser } = this.props;
		let { host } = window.location;
		host = host.replace('www.', '');
		if (getEnvironment() !== 'prod') {
			try {
				const company = await graphql({
					input: { host },
					query: 'getCompanyByHost',
				});
				const theme = parse(get(company, 'theme', '{}'));
				this.setState({ company, theme });
				// Set background image for whitelabel comapnies
				const key = get(company, 'background.key', false);
				const bucket = get(company, 'background.bucket', false);
				if (key) {
					const url = await downloadFromS3Signed(key, bucket);
					this.setState({ backgroundImageURL: url }, () =>
						this.setBackgroundImage(company)
					);
				} else {
					this.setBackgroundImage(company);
				}
			} catch (error) {
				console.log(error);
			}
		}

		const federatedToken = await this.requestJwtToken();

		Cache.setItem('federatedToken', federatedToken);
		const redirectURL = this.props.location.hash;
		const token = parseJwt(get(federatedToken, 'id_token'));
		if (token === undefined) {
			this.setState({ visible: true });
		} else {
			const cognitoId = get(token, 'cognito:username');
			const issuer = token.iss;
			const domain = issuer.slice(8, issuer.length);
			const provider = get(token, 'identities[0].providerName');
			const saml = await auth(provider, { provider: true });
			const identityPoolId = get(saml, 'identityPool');
			Auth.configure({
				identityPoolId,
				region: 'us-east-2',
			});
			Auth.federatedSignIn(
				domain,
				{
					token: get(federatedToken, 'id_token'),
					expires_at: token.exp * 1000 + Date.now(),
				},
				{ user: cognitoId }
			)
				.then(async (cred) => {
					return Auth.currentAuthenticatedUser();
				})
				.then(async (user) => {
					const email = get(token, 'email', '');
					const emailDomain = email.split('@')[1];
					const employeeId =
						token?.['custom:employeeid'] || token?.['custom:userid'] || null;

					if (cognitoId) {
						try {
							const { data } = await this.props.client.query({
								query: GetUserByCognitoId,
								variables: {
									cognitoId,
								},
							});
							let currentUser = {
								...data.getUserByCognitoId,
								authMethod: 'saml',
								lastLogin: dayjs().toISOString(),
							};
							let accountClaim = null;
							const companyId = get(currentUser, 'companyId');
							if (employeeId && companyId) {
								accountClaim = await this.getAccountClaimByEmployeeCompanyId(
									employeeId,
									companyId
								);
							}

							const { input, updatedUser } = getUpdatedUserFromSAMLAttributes({
								token,
								accountClaim,
								currentUser,
							});
							currentUser = updatedUser;

							if (data && currentUser && !currentUser.active) {
								const error = new Error('User Disabled');
								error.code = 'UserDisabledException';
								throw error;
							} else {
								setCurrentUser(currentUser);
								await this.onAuthentication(
									federatedToken.id_token,
									currentUser,
									token
								);
								let loginValue = Boolean(currentUser.lastLoginValue);
								if (currentUser.company.resetPopup) {
									loginValue = false;
								}

								window.localStorage.setItem('lastLoginValue', loginValue);
								window.localStorage.setItem('firstLoginValue', loginValue);

								this.props.client
									.mutate({
										mutation: gql(updateUser),
										variables: {
											input,
										},
									})
									.then(() => {
										const redirectURL =
											localStorage.getItem('redirectURL') ?? '/dashboard';
										localStorage.removeItem('redirectURL');
										window.location.href = redirectURL;
									});
							}
						} catch (error) {
							const errorMessage = `GraphQL error: Error invoking method 'get(java.lang.Integer)' in java.util.ArrayList at velocity[line 2, column 31]`;
							// Create a new user if one does not exist already.
							const provider = get(token, 'identities[0].providerName');
							const saml = await auth(provider, { provider: true });
							let companyId = get(saml, 'companyId');
							if (!companyId && provider === 'Google') {
								const company =
									await this.getCompanyIdBySSOGoogleDomain(emailDomain);
								companyId = get(company, 'id', '');
							}

							if (!companyId) {
								const error_ =
									'The provided google account does not match a company on record. Please sign in using your company email address.';
								this.props.history.push({
									pathname: '/login',
									state: {
										err: error_,
									},
								});
							} else if (companyId && error.message === errorMessage) {
								this.props.history.push({
									pathname: `/newsamluser/${companyId}`,
									state: {
										token,
										redirectURL,
									},
								});
							}
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}

	onAuthentication = async (authToken, currentUser, token) => {
		Cookies.set('jwt', this.reduceJwt(authToken));
		this.setState({
			auth: true,
		});
		if (token) {
			try {
				let message = '';
				for (const [key, value] of Object.entries(token)) {
					message += `${key}: ${value} \n`;
				}

				await slackNotificationAuth({
					username: 'errors',
					title: `SAML Web Login User: ${get(currentUser, 'firstName')} ${get(
						currentUser,
						'lastName'
					)}: ${get(currentUser, 'id')}`,
					message,
				});
			} catch (error) {
				console.log(error);
			}
		}
	};

	getAccountClaimByEmployeeCompanyId = async (employeeId, companyId) => {
		return new Promise(async (resolve, reject) => {
			try {
				const { client } = this.props;
				const { data } = await client.query({
					query: GetAccountClaimByEmployeeIdCompanyId,
					variables: { employeeId, companyId },
				});
				const claim = {
					...data.getAccountClaimByEmployeeIdCompanyId,
				};
				return resolve(claim);
			} catch {
				return resolve(null);
			}
		});
	};

	getCompanyIdBySSOGoogleDomain = async (ssoGoogleDomain) => {
		try {
			const company = await graphql({
				input: { ssoGoogleDomain },
				query: 'getCompanyBySSOGoogleDomain',
			});
			return company;
		} catch {
			return '';
		}
	};

	getMobileOperatingSystem = () => {
		const userAgent = navigator.userAgent || navigator.vendor || window.opera;
		if (/android/i.test(userAgent) && !window.MSStream) {
			const width = window.innerWidth || document.body.clientWidth;
			return width > 1000 ? 'Tablet' : 'Android';
		}

		// IOS detection from: http://stackoverflow.com/a/9039885/177710
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			const width = window.innerWidth || document.body.clientWidth;
			return width > 1000 ? 'Tablet' : 'iOS';
		}
	};

	setBackgroundImage = (company) => {
		const env = getEnvironment();
		const { backgroundImageURL } = this.state;
		const whiteLabel = get(company, 'whiteLabel');
		let backgroundImage = null;
		if (env !== 'prod' && whiteLabel && backgroundImageURL) {
			const key = get(company, 'background.key', false);
			backgroundImage = `url(${backgroundImageURL})`;
			this.setState({ backgroundImage });
		} else {
			backgroundImage = `url(${LoginBackground})`;
			this.setState({ backgroundImage });
		}
	};

	reduceJwt = (token) => {
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replaceAll('-', '+').replaceAll('_', '/');
		const jsonPayload = decodeURIComponent(
			atob(base64)
				.split('')
				.map(function (c) {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join('')
		);
		const encoded = `#access_token=${encodeURIComponent(
			Buffer.from(jsonPayload).toString('base64')
		)}`;
		return encoded;
	};

	async requestJwtToken() {
		try {
			const accessCode = get(this.props, 'location.search', '').split('?code=');
			const code = get(accessCode, `[1]`);
			const federatedOrgCode = Cache.getItem('federatedOrgCode');
			const federatedOrgData = await auth(federatedOrgCode);
			const clientId = get(federatedOrgData, 'clientId');
			const clientSecret = get(federatedOrgData, 'clientSecret');
			const domain = get(federatedOrgData, 'domain');
			const region = get(federatedOrgData, 'region', 'us-east-2');
			Cache.setItem('federatedDomain', domain);
			Cache.setItem('federatedRegion', region);
			Cache.setItem('federatedClientId', clientId);
			Cache.setItem('federatedClientSecret', clientSecret);
			const { origin } = window.location;
			const scope = 'aws.cognito.signin.user.admin+email+openid+phone+profile';
			const body = `client_id=${clientId}&client_secret=${clientSecret}&grant_type=authorization_code&code=${code}&scope=${scope}&redirect_uri=${origin}/saml-auth/login`;
			const url = `https://${domain}.auth.${region}.amazoncognito.com/oauth2/token`;
			const response = await axios.post(url, body, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					Authorization:
						'Basic ' +
						Buffer.from(clientId + ':' + clientSecret).toString('base64'),
				},
			});
			const federatedToken = response.data;
			Cache.setItem('federatedToken', federatedToken);
			return federatedToken;
		} catch {
			return undefined;
		}
	}

	render() {
		const { company } = this.state;
		return (
			<div className="custom-loader">
				<LoadingOutlined width={60} height={60} />
			</div>
		);
	}
}

export default withApollo(SAMLWebLoginComponent);

import { css } from 'emotion';
import get from 'lodash/get';
import { COLORS } from 'src/_shared/styles/colors';

export const progressButton = (theme) => {
	let buttonColor = COLORS.blue;
	if (get(theme, 'enabled')) {
		buttonColor = get(theme, 'buttonColor', buttonColor);
	}

	return css({
		'& .pb-container': {
			background: buttonColor,
			display: 'inline-block',
			textAlign: 'center',
			width: '100%',
		},
		'& .pb-button': {
			fontFamily: '"Open Sans", sans-serif',
			background: buttonColor,
			border: `2px solid ${buttonColor}`,
			borderRadius: '27px',
			color: '#ffffff',
			cursor: 'pointer',
			padding: '0.4em 1em',
			textDecoration: 'none',
			textAlign: 'center',
			height: '54px',
			width: '100%',
			'-webkit-tap-highlight-color': 'transparent',
			outline: 'none',
			transition:
				'background-color 0.3s, width 0.3s, border-width 0.3s, border-color 0.3s, border-radius 0.3s',
		},
		'& .pb-container .pb-button span': {
			background: buttonColor,
			display: 'inherit',
			transition: 'opacity 0.3s 0.1s',
			fontSize: '20px',
			fontWeight: 100,
		},
		'& .pb-container .pb-button svg': {
			height: '54px',
			width: '54px',
			position: 'absolute',
			transform: 'translate(-50%, -50%)',
			pointerEvents: 'none',
		},
		'& .pb-container .pb-button svg path': {
			opacity: 0,
			fill: 'none',
		},
		'& .pb-container .pb-button svg.pb-progress-circle': {
			animation: 'spin 0.9s infinite cubic-bezier(0.085, 0.260, 0.935, 0.710)',
		},
		'& .pb-container .pb-button svg.pb-progress-circle path': {
			stroke: '#018dd3',
			strokeWidth: 5,
		},
		'& .pb-container .pb-button svg.pb-checkmark path': {
			'& .pb-container .pb-button svg.pb-cross path': {
				stroke: '#ffffff',
				strokeLinecap: 'round',
				strokeWidth: 4,
			},
			'& .pb-container.disabled .pb-button': {
				cursor: 'not-allowed',
			},
			'& .pb-container.loading .pb-button': {
				width: '54px',
				borderWidth: '6.5px',
				borderColor: '#ddd',
				cursor: 'wait',
				backgroundColor: 'transparent',
				padding: 0,
			},
			'& .pb-container.loading .pb-button span': {
				transition: 'all 0.15s',
				opacity: 0,
				display: 'none',
			},
			'& .pb-container.loading .pb-button .pb-progress-circle > path': {
				transition: 'opacity 0.15s 0.3s',
				opacity: 1,
			},
			'& .pb-container.success .pb-button': {
				borderColor: '#A0D468',
				backgroundColor: '#A0D468',
			},
			'& .pb-container.success .pb-button span': {
				transition: 'all 0.15s',
				opacity: 0,
				display: 'none',
			},
			'& .pb-container.success .pb-button .pb-checkmark > path': {
				opacity: 1,
			},
			'& .pb-container.error .pb-button': {
				borderColor: '#ED5565',
				backgroundColor: '#ED5565',
			},
			'& .pb-container.error .pb-button span': {
				transition: 'all 0.15s',
				opacity: 0,
				display: 'none',
			},
			'& .pb-container.error .pb-button .pb-cross > path': {
				opacity: 1,
			},
			'@keyframes spin': {
				from: {
					transform: 'translate(-50%, -50%) rotate(0deg)',
					transformOrigin: 'center center',
				},
				to: {
					transform: 'translate(-50%, -50%) rotate(360deg)',
					transformOrigin: 'center center',
				},
			},
		},
	});
};

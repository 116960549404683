import { CopyOutlined, MailOutlined, RetweetOutlined } from '@ant-design/icons';
import {
	Alert,
	Card,
	Modal,
	Popconfirm,
	Tooltip,
	Typography,
	message,
} from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import get from 'lodash/get';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import OwnershipPeriodTooltip from 'src/_shared/components/referral-card/OwnershipPeriodTooltip.jsx';
import { ProgressIndicator } from 'src/_shared/components/referral-progress-indicator/';
import note from '../../_shared/assets/note.png';
import MyReferralsBonusDetailsModal from '../../_shared/components/MyReferralsBonusDetailsModalComponent.jsx';
import {
	calculateReferralBonus,
	formatDate,
	lambda,
	mapReferralStatus,
	ml,
} from '../../_shared/services/utils.js';
import { linkStyles, successStyles } from './myReferralCardStyles.js';

const { Paragraph } = Typography;

class ReferralCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false,
			resendSuccess: false,
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
			translatedTitle: '',
		};
	}

	async componentDidMount() {
		const title = get(this.props, 'referral.job.title');
		const newTitle = title;
		this.setState({
			translatedTitle: newTitle,
		});
	}

	async componentDidUpdate(prevProps) {
		if (
			prevProps.currentUser.languageCode !== this.props.currentUser.languageCode
		) {
			const title = get(this.props, 'referral.job.title');
			const newTitle = title;
			this.setState({
				translatedTitle: newTitle,
			});
		}
	}

	handleCancel = () => {
		this.setState({
			visible: false,
		});
	};

	resendReferral = (referral) => {
		return new Promise((resolve, reject) => {
			try {
				const { id, name, brandColor, logo, whiteLabel } = get(
					this.props.currentUser,
					'company'
				);
				const company = {
					id,
					name,
					brandColor,
					logo,
					whiteLabel,
				};
				let endpoint = '';
				const host = window.location.hostname;
				// If referral type is "text"
				if (get(referral, 'referralType') === 'text') {
					switch (host) {
						case 'localhost':
						case 'erinapp-alpha.netlify.app':
						case 'erinapp-dev.netlify.app':
						case 'testing.erinapp.com':
						case 'qa.referafriend.seaworldentertainment.com': {
							endpoint = 'dev-resend-referral';

							break;
						}

						case 'referralstest.aus.com': {
							endpoint = 'allied-dev-resend-referral';

							break;
						}

						case 'app.erinapp.com': {
							endpoint = 'resend-referral';

							break;
						}

						case 'referrals.aus.com': {
							endpoint = 'allied-resend-referral';

							break;
						}
						// No default
					}
				}
				// If referral type is "email"
				else if (
					host === 'localhost' ||
					host === 'erinapp-alpha.netlify.app' ||
					host === 'erinapp-dev.netlify.app' ||
					host === 'testing.erinapp.com' ||
					host === 'qa.referafriend.seaworldentertainment.com'
				) {
					endpoint = 'base-white-resend-referral-dev-app';
				} else {
					endpoint = 'base-white-resend-referral-prod-app';
				}

				lambda({ endpoint, variables: { referral, company } });
				resolve();
			} catch (error) {
				console.error(error);
				reject(error);
			}
		});
	};

	showModal = () => {
		this.setState({
			visible: true,
		});
	};

	render() {
		const {
			allMultiLingualData,
			currentUser,
			referral,
			contactIncentiveBonus,
			currentCurrencyRate,
			currentCurrencySymbol,
			ownershipSettings,
		} = this.props;
		const { translatedTitle } = this.state;
		const { contact, job, user, referralType, adminNote } = referral;

		const status = get(this.props, 'referral.status');
		const bonusStatus = get(referral, 'bonusStatus');
		const bonusArray = get(this.props, ['referral', 'bonuses']);
		const isGDPR = this.props.currentUser.company.confirmCompliance;
		const hideCopyReferral = get(
			this.props,
			'currentUser.company.hideCopyReferral',
			false
		);
		const dateFormat = get(this.props, 'currentUser.dateFormat');
		const languageCode = get(this.props, 'currentUser.languageCode');
		const referralDate =
			formatDate(
				get(this.props, 'referral.dateCreated'),
				languageCode,
				dateFormat
			) ||
			formatDate(
				get(this.props, 'referral.referralDate'),
				languageCode,
				dateFormat
			);

		const referralDetail =
			referral.contact && get(referral, 'contact.emailAddress', '') !== null
				? get(referral, 'contact.emailAddress')
				: referral.contact && get(referral, 'contact.phoneNumber', '') !== null
					? get(referral, 'contact.phoneNumber')
					: '';

		const { resendSuccess } = this.state;
		let userGroupCurrency = get(currentUser, 'userGroup.currency');
		if (userGroupCurrency === null) userGroupCurrency = 'USD';

		if (ownershipSettings) {
			ownershipSettings.expiryDate = formatDate(
				get(referral, 'ownershipEndDate'),
				languageCode
			);
		}

		const handleResendReferral = (referral) => {
			this.resendReferral(referral)
				.then(() => {
					this.setState({ resendSuccess: true });
					setTimeout(() => {
						this.setState({ resendSuccess: false });
					}, 2000);
				})
				.catch((error) => console.error(error));
		};

		let formattedBonus = '';
		let currencySymbol = '';
		let bonusAmount = 0;
		const refStatus = get(referral, 'status');
		if (referral.referralType !== 'self') {
			currencySymbol = getSymbolFromCurrency(userGroupCurrency);

			// When a candidate is "Hired" we should be showing the total bonus amount based on the bonuses that are associated with that referral, not the bonus settings for the job.

			// START: Adding new block of code to calculate total bonus amount based on the bonuses that are associated with that referral
			if (refStatus === 'hired') {
				const bonuses = get(referral, 'bonuses', []).sort(function (a, b) {
					return a.earnedDate < b.earnedDate
						? -1
						: a.earnedDate > b.earnedDate
							? 1
							: 0;
				});

				let startDate = '';
				bonuses.map((bonus) => {
					const recipientType = get(bonus, 'recipientType');
					if (recipientType === 'employee') {
						if (startDate === '') {
							startDate = formatDate(get(bonus, 'startDate'), languageCode);
						}

						bonusAmount += Number.parseInt(get(bonus, 'amountDue', 0), 10);
					}
				});

				if (bonusAmount > 0) {
					formattedBonus = `${bonusAmount}`.replaceAll(
						/\B(?=(\d{3})+(?!\d))/g,
						','
					);
				}
			} else {
				if (job && job.referralBonus) {
					bonusAmount = calculateReferralBonus(
						contactIncentiveBonus,
						job.referralBonus.amount,
						user.incentiveEligible,
						this.props.currentTieredBonus,
						'employee',
						get(currentUser, 'userGroupId')
					);
				}

				if (bonusAmount > 0) {
					formattedBonus = `${bonusAmount}`.replaceAll(
						/\B(?=(\d{3})+(?!\d))/g,
						','
					);
				}
			}
			// END: New block
		}

		const customStatus = get(referral, 'customStatus');

		return (
			<>
				{resendSuccess && (
					<Alert
						className={successStyles}
						type="success"
						message="The referral was successfully sent"
					/>
				)}
				<Card className="my-progressbar-card">
					<div className="my-progressbar-top">
						<div className="mp-left">
							{contact ? (
								<h4 className="my-progressbar-name">
									<Link to={`/mycontacts/${contact.id}`}>
										{`${get(contact, 'firstName', '')} ${get(
											contact,
											'lastName',
											''
										)}`}
									</Link>
								</h4>
							) : isGDPR ? (
								<Tooltip
									arrowPointAtCenter
									placement="top"
									title={ml(
										'For compliance, your referrals information is not stored until the referral is accepted. You can copy and send the referral link below to your contact at anytime.',
										currentUser,
										allMultiLingualData
									)}
								>
									<span className="my-progressbar-pending-acceptance">
										{ml('Pending Acceptance', currentUser, allMultiLingualData)}
									</span>
								</Tooltip>
							) : null}
						</div>
						<div className="mp-right">
							{job && (
								<Link
									style={{ width: '100%', marginBottom: 0 }}
									to={`/browsejobs/${get(job, 'id', '')}`}
								>
									<Paragraph
										ellipsis={{
											rows: 2,
											expandable: false,
											tooltip: {
												title: translatedTitle,
												placement: 'topRight',
											},
										}}
										component="span"
										style={{ width: '100%', marginBottom: 0, color: 'inherit' }}
									>
										{translatedTitle}
									</Paragraph>
								</Link>
							)}
							{job && job.referralBonus ? (
								<>
									<div
										style={{
											display: 'flex',
											verticalAlign: 'middle',
											marginBottom: 6,
										}}
										className="rdc-referred-date"
									>
										<OwnershipPeriodTooltip
											ownershipSettings={ownershipSettings}
										/>
										<Tooltip
											placement="top"
											title={`Referred on ${referralDate} by ${
												referralType ? referralType : ''
											}`}
										>
											<span
												style={{ marginBottom: 0 }}
												className="my-progressbar-date"
											>
												{referralType == 'email' && (
													<i
														className="icon-envelope-outline"
														style={{ fontSize: 18 }}
													/>
												)}
												{referralType == 'text' && (
													<i
														className="icon-message-outline"
														style={{ fontSize: 18 }}
													/>
												)}
												{referral &&
													referral.referralType &&
													get(referral, 'referralType', '').includes(
														'share'
													) && <i className="icon-share" />}
												{referralDate}
											</span>
										</Tooltip>
										{adminNote ? (
											<Tooltip
												arrowPointAtCenter
												placement="topRight"
												title={adminNote}
											>
												<img alt="erin-note" src={note} />
											</Tooltip>
										) : null}
									</div>
									<span
										className="my-progressbar-bonus-price"
										style={{ display: 'flex', alignItems: 'center' }}
									>
										{job.isHotJob && (
											<i
												className="icon-fire text-orange"
												style={{ marginRight: 8 }}
											/>
										)}
										{referral.referralType === 'self' ? (
											<div style={{ color: '#ffb461' }}>
												{ml('Self Referred', currentUser, allMultiLingualData)}
											</div>
										) : (
											<>
												{bonusAmount > 0
													? `${currencySymbol}${formattedBonus}`
													: ''}
											</>
										)}
									</span>
								</>
							) : (
								<>
									<div
										style={{
											display: 'flex',
											verticalAlign: 'middle',
											marginBottom: 6,
										}}
										className="rdc-referred-date"
									>
										<OwnershipPeriodTooltip
											ownershipSettings={ownershipSettings}
										/>
										<Tooltip
											placement="top"
											title={`Referred on ${referralDate} by ${
												referralType ? referralType : ''
											}`}
										>
											<span
												style={{ marginBottom: 0 }}
												className="my-progressbar-date"
											>
												{referralType == 'email' && (
													<i
														className="icon-envelope-outline"
														style={{ fontSize: 18 }}
													/>
												)}
												{referralType == 'text' && (
													<i
														className="icon-message-outline"
														style={{ fontSize: 18 }}
													/>
												)}
												{referralType == '' ||
													(!referralType && (
														<i
															className="icon-telephone-outline"
															style={{ fontSize: 18 }}
														/>
													))}
												{referralDate}
											</span>
										</Tooltip>
									</div>
									{adminNote ? (
										<Tooltip
											arrowPointAtCenter
											placement="topRight"
											title={adminNote}
										>
											<img alt="erin-note" src={note} />
										</Tooltip>
									) : null}
								</>
							)}
						</div>
					</div>

					{/** BEGIN */}

					<div className="my-progressbar-status">
						<div className="mps-left">
							<p>
								{' '}
								{ml('Status', currentUser, allMultiLingualData)}:{' '}
								<span className="status-name">
									{ml(
										status === 'interviewing' &&
											customStatus !== null &&
											customStatus !== undefined
											? mapReferralStatus(customStatus, currentUser.company)
											: mapReferralStatus(status, currentUser.company),
										currentUser,
										allMultiLingualData
									)}
								</span>
								{status === 'referred' && !isGDPR ? (
									<Popconfirm
										title="Resend referral？"
										placement="left"
										okText="Yes"
										cancelText="No"
										icon={<MailOutlined />}
										onConfirm={() => handleResendReferral(referral)}
									>
										<Tooltip
											arrowPointAtCenter
											placement="topRight"
											title={`Resend referral to ${referralDetail}`}
											style={{ fontWeight: 1000 }}
										>
											<RetweetOutlined className="status-icon" />
										</Tooltip>
									</Popconfirm>
								) : status === 'referred' && isGDPR && !hideCopyReferral ? (
									<Tooltip
										arrowPointAtCenter
										placement="topRight"
										title="Copy Referral Link"
									>
										<CopyOutlined
											className="status-icon left-icon"
											onClick={() => {
												let lang = languageCode;
												if (
													languageCode === 'US' ||
													languageCode === null ||
													languageCode === undefined
												) {
													lang = 'EN';
												}

												const { host } = window.location;
												const https =
													host === 'localhost:3000' ? '' : 'https://';
												const link =
													referral.type == 'gdprReferralCreated'
														? `${https}${host}/newreferral/${referral.id}/${lang}`
														: `${https}${host}/newreferral/${referral.id}`;
												navigator.clipboard.writeText(link);
												message.success('Link Copied Successfully!');
											}}
										/>
									</Tooltip>
								) : null}
							</p>
						</div>
						<div className="mps-right">
							{status === 'hired' && bonusStatus && bonusArray.length > 0 ? (
								<a className={linkStyles} onClick={this.showModal}>
									{ml('View Bonus Details', currentUser, allMultiLingualData)}
								</a>
							) : null}
						</div>
					</div>

					{/** END */}

					<div className="my-progressbar-wrap">
						<ProgressIndicator
							type="referral"
							points={5}
							status={status}
							currentUser={get(this.props, 'currentUser')}
							referral={referral}
							onClick={this.handleUpdateStatus}
						/>
					</div>
				</Card>
				<Modal
					title="Bonus Details"
					open={this.state.visible}
					footer={null}
					onCancel={this.handleCancel}
				>
					<MyReferralsBonusDetailsModal
						visible={this.state.visible}
						currentTieredBonus={this.props.currentTieredBonus}
						status={status}
						userGroup={get(referral, 'user.userGroupId')}
						currentCurrencyRate={currentCurrencyRate}
						currentCurrencySymbol={currentCurrencySymbol}
						currentUser={currentUser}
						data={referral}
						handleCancel={this.handleCancel}
						bonusDetailsText={ml(
							'Bonus Details',
							currentUser,
							allMultiLingualData
						)}
						jobText={ml('Job', currentUser, allMultiLingualData)}
						referredCandidateText={ml(
							'Referred Candidate',
							currentUser,
							allMultiLingualData
						)}
						totalBonusText={ml('Total Bonus', currentUser, allMultiLingualData)}
						hiredDateText={ml('Hired Date', currentUser, allMultiLingualData)}
						startDateText={ml('Start Date', currentUser, allMultiLingualData)}
						bonusPaymentsText={ml(
							'Bonus Payments',
							currentUser,
							allMultiLingualData
						)}
						bonusNotesText={ml('Bonus Notes', currentUser, allMultiLingualData)}
						closeText={ml('Close', currentUser, allMultiLingualData)}
						noResponseText={ml('No Response', currentUser, allMultiLingualData)}
						inActiveText={ml('Inactive', currentUser, allMultiLingualData)}
						translatedTitle={this.state.translatedTitle}
						allMultiLingualData={allMultiLingualData}
						onCancel={this.handleCancel}
					/>
				</Modal>
			</>
		);
	}
}

export default ReferralCard;

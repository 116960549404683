import dayjs from 'dayjs';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { withJobMatchesById } from 'src/_shared/api/components/jobs/';
import { compose } from '../../_shared/services/utils.js';
import JobCardComponent from './JobCardComponent.jsx';

const mapStateToProperties = (state, ownProperties) => {
	const { currentUser } = state.user;
	const bonus =
		typeof get(ownProperties, 'job.referralBonus') === 'string'
			? JSON.parse(get(ownProperties, 'job.referralBonus', '{}'))
			: get(ownProperties, 'job.referralBonus');
	let id = '';
	let isActive = false;
	let archived = false;
	const campaignId = get(ownProperties, 'job..campaignId');
	const campaign = get(ownProperties, 'job.campaign');

	if (campaignId) {
		const today = dayjs();
		const startDate = get(campaign, 'startDate');
		const endDate = get(campaign, 'endDate');
		archived = get(campaign, 'archived', false);
		isActive = Boolean(today.isBetween(startDate, endDate, 'day', '[]'));
	}

	if (archived) {
		isActive = false;
	}

	id = isActive
		? get(ownProperties, 'job.campaign.tieredBonusId', 'skip')
		: get(bonus, 'tieredBonusId', 'skip');

	return {
		currentUser,
		currentReferral: state.referral.currentReferral,
		referrals: state.referral.referrals,
		companyId: currentUser.companyId,
	};
};

const mapDispatchToProperties = () => {
	return {};
};

export default connect(
	mapStateToProperties,
	mapDispatchToProperties
)(compose(withJobMatchesById)(JobCardComponent));

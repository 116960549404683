import { CognitoIdentityProvider } from '@aws-sdk/client-cognito-identity-provider';
import { Alert, Button, Form, Input } from 'antd';
import { Auth } from 'aws-amplify';
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import get from 'lodash/get';
import mixpanel from 'mixpanel-browser';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { configMode } from 'src/_shared/api/';
import {
	GetUserByCognitoId,
	updateUser,
} from 'src/_shared/api/graphql/custom/users/';
import { decryptUsingAES256 } from 'src/_shared/api/settings';
import { handleAuthType, parse } from 'src/_shared/services/utils.js';
import ClaimAccountModalForm from '../claim-account-modal/ClaimAccountModalComponent.jsx';
import ResetPasswordModal from '../reset-password-modal/ResetPasswordModal.jsx';

class LoginCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			invalidCredentials: false,
			serverError: false,
			inactiveError: false,
			loading: false,
			visible: this.props.visible,
			resetModalVisible: false,
			inactiveLicense: false,
		};
	}

	async componentDidMount() {
		if (this.props.locationState) {
			const { currentUser, redirectURL } = this.props.locationState;
			const { setCurrentUser, onAuthentication } = this.props;
			if (currentUser) {
				try {
					const { data } = await this.props.client.query({
						query: GetUserByCognitoId,
						variables: { cognitoId: get(currentUser, 'cognitoId') },
					});
					const newUser = {
						...data.getUserByCognitoId,
						lastLogin: dayjs().toISOString(),
					};
					setCurrentUser(newUser);
					onAuthentication(redirectURL, newUser);

					this.props.client
						.mutate({
							mutation: gql(updateUser),
							variables: {
								input: {
									id: currentUser.id,
									lastLogin: dayjs().toISOString(),
								},
							},
						})
						.then(() => this.props.history.push(redirectURL));
				} catch (error) {
					console.error(error);
				}
			}
		}
	}

	closeModal = () => {
		this.setState({ visible: false });
	};

	closeResetModal = () => {
		this.setState({ resetModalVisible: false, loading: false });
	};

	handleCompanyUserAccount = () => {
		const { company } = this.props;
		this.props.history.push(`/new-user-signup/${get(company, 'id')}`);
	};

	handleExternalUserAccount = () => {
		this.props.history.push({
			pathname: '/external-user',
			state: { company: this.props.company },
		});
	};

	handleLoginWithCompanyAccount = () => {
		const { openCompanyAccountModal, saml } = this.props;
		const samlPath = get(saml, 'path');
		if (samlPath) {
			this.props.history.push(samlPath);
		} else {
			openCompanyAccountModal();
		}
	};

	handleSubmit = async (values) => {
		this.setState({
			serverError: false,
			invalidCredentials: false,
			loading: true,
		});
		const { onAuthentication, setCurrentUser, setFailedLoginAttempts } =
			this.props;
		let currentUsr = '';
		try {
			const user = await Auth.signIn(
				values.email.toLowerCase().trim(),
				values.password.trim()
			);
			if (get(user, 'challengeName') === 'NEW_PASSWORD_REQUIRED') {
				this.setState({ resetModalVisible: true, authUser: user });
				return;
			}

			const { data } = await this.props.client.query({
				query: GetUserByCognitoId,
				variables: { cognitoId: user.username },
			});
			const lastLoginValue = get(data.getUserByCognitoId, 'lastLogin');
			let value = Boolean(lastLoginValue);
			const resetPopup = get(data.getUserByCognitoId, 'company.resetPopup');
			if (resetPopup) {
				value = false;
			}

			window.localStorage.setItem('lastLoginValue', value);
			window.localStorage.setItem('firstLoginValue', value);
			const currentUser = {
				...data.getUserByCognitoId,
				authMethod: 'credentials',
				lastLogin: dayjs().toISOString(),
			};
			currentUsr = currentUser;

			const supportAdmin = parse(get(currentUser, 'admin'));
			const supportAdminPermissions = get(supportAdmin, 'permissions') === '*';
			const disableSite = get(currentUser, 'company.disableSite', false);
			if (!supportAdminPermissions && disableSite === true) {
				const error = new Error('License Invalid');
				error.code = 'UserLicenseInvalid';
				throw error;
			}

			if (currentUser && !currentUser.active) {
				const error = new Error('User Disabled');
				error.code = 'UserDisabledException';
				throw error;
			} else {
				setCurrentUser(currentUser);
				if (currentUser?.isOptoutAnalytics) {
					mixpanel.opt_out_tracking();
				} else {
					mixpanel.identify(currentUser.id);
					mixpanel.register({
						'Company Name': currentUser.company.name,
						'Company ID': currentUser.company.id,
					});
					mixpanel.track('Logged In');
					localStorage.setItem('mixpanel_user_identified', 'true');
				}

				await this.props.client.mutate({
					mutation: gql(updateUser),
					variables: {
						input: {
							id: data.getUserByCognitoId.id,
							authMethod: 'credentials',
							lastLogin: dayjs().toISOString(),
							accessToken: user.signInUserSession.accessToken.jwtToken,
							expires: false,
						},
					},
				});
				onAuthentication(
					user.signInUserSession.accessToken.jwtToken,
					currentUser
				);
			}
		} catch (error) {
			if (
				error.message ===
				'Network error: Response not successful: Received status code 401'
			)
				window.location.reload();
			switch (error.code) {
				case 'NotAuthorizedException':
				case 'UserNotFoundException': {
					this.setState({ invalidCredentials: true });
					setFailedLoginAttempts(currentUsr);

					break;
				}

				case 'UserDisabledException': {
					this.setState({ inactiveError: true });

					break;
				}

				case 'NetworkError': {
					this.setState({ serverError: true });

					break;
				}

				case 'UserLicenseInvalid': {
					this.setState({ inactiveLicense: true });

					break;
				}
				// No default
			}

			this.setState({ loading: false });
		}
	};

	updateNewPassword = (user, newPassword) => {
		const parameters = {
			ChallengeName: 'NEW_PASSWORD_REQUIRED',
			ClientId: get(user, 'pool.clientId'),
			ChallengeResponses: {
				USERNAME: get(user, 'username'),
				NEW_PASSWORD: newPassword,
			},
			Session: get(user, 'Session'),
		};
		const config = {
			region: 'us-east-2',
			accessKeyId:
				process.env[`REACT_APP_${configMode}_PASSWORDRESETACCESSKEYID`],
			secretAccessKey: decryptUsingAES256(
				process.env[`REACT_APP_${configMode}_PASSWORDRESETSECRETACCESSKEY`]
			),
		};
		const { closeResetModal } = this;
		const cognitoidentityserviceprovider = new CognitoIdentityProvider(config);
		cognitoidentityserviceprovider.respondToAuthChallenge(
			parameters,
			function (error, data) {
				if (error) console.log(error, error.stack);
				else {
					closeResetModal();
				}
			}
		);
	};

	renderError = (error, message) => {
		return error ? <Alert type="error" message={message} /> : null;
	};

	renderSuccess = (error, message) => {
		return error ? <Alert type="success" message={message} /> : null;
	};

	renderWarning = (error, message) => {
		return error ? <Alert type="warning" message={message} /> : null;
	};

	render() {
		const { serverError, invalidCredentials, inactiveError, inactiveLicense } =
			this.state;
		const {
			theme,
			accountClaimError,
			accountClaimSuccess,
			companyAdminSuccess,
			successMessage,
			warningMessage,
			externalUserSuccess,
			company,
			whiteLabel,
			openForgotPasswordModal,
			setUserEmail,
			labelEmployeeID,
			userSignupSettings,
		} = this.props;
		const loginFormSettings = parse(get(company, 'loginFormSettings'));

		return (
			<>
				<h2 className="auth-title text-center">
					Log in to {company && whiteLabel ? company?.name : 'ERIN'}
				</h2>
				<Form onFinish={this.handleSubmit}>
					<div className="custom-form-group">
						<label className="custom-label" data-testid="login-email">
							Email Address
						</label>
						<Form.Item
							name="email"
							rules={[
								{
									type: 'email',
									message: 'Please enter a valid email address.',
								},
								{
									required: true,
									message: 'Email Required',
								},
							]}
							validateTrigger="onSubmit"
						>
							<Input
								autoComplete="off"
								className="custom-input"
								onChange={(event) => setUserEmail(event.target.value.trim())}
								onFocus={() => handleAuthType('default')}
							/>
						</Form.Item>
					</div>
					<div className="custom-form-group">
						<label className="custom-label">Password</label>
						<Form.Item
							name="password"
							rules={[
								{
									required: true,
									message: 'Password Required',
								},
							]}
						>
							<Input.Password
								className="custom-input"
								autoComplete="off"
								onFocus={() => handleAuthType('default')}
							/>
						</Form.Item>
					</div>
					<div className="forgot-link">
						<Button type="link" onClick={openForgotPasswordModal}>
							Forgot Your Password?
						</Button>
					</div>

					{this.renderError(
						serverError,
						'There was a problem with the server, please try again.'
					)}
					{this.renderError(
						invalidCredentials,
						'User/password combination not found. Please try again.'
					)}
					{this.renderError(
						inactiveError,
						'Your account has been disabled.  Please contact an adminstrator to reactivate your account.'
					)}
					{this.renderError(
						inactiveLicense,
						'Your license of ERIN is no longer valid. Please contact your company admin for more information.'
					)}
					{this.renderError(accountClaimError, accountClaimError)}
					{this.renderSuccess(accountClaimSuccess, accountClaimSuccess)}
					{this.renderSuccess(companyAdminSuccess, companyAdminSuccess)}
					{this.renderSuccess(successMessage, successMessage)}
					{this.renderWarning(warningMessage, warningMessage)}
					{this.renderSuccess(externalUserSuccess, externalUserSuccess)}
					<Button
						block
						loading={this.state.loading}
						htmlType="submit"
						type="primary"
						size="large"
						onClick={this.handleSubmit}
					>
						Log In
					</Button>
					{!get(company, 'disableSAMLLogin', false) && (
						<>
							<div className="or-style">or</div>
							<Button
								ghost
								block
								type="primary"
								size="large"
								onClick={this.handleLoginWithCompanyAccount}
							>
								Log in with a Company Account
							</Button>
						</>
					)}
					{get(company, 'whiteLabel') &&
						get(company, 'externalUserSignUp') &&
						!get(loginFormSettings, 'companyUserSignup.enabled') && (
							<>
								<div className="or-style">or</div>
								<Button
									ghost
									block
									type="primary"
									size="large"
									onClick={this.handleExternalUserAccount}
								>
									Create An Account
								</Button>
							</>
						)}
					{get(company, 'whiteLabel') &&
						get(loginFormSettings, 'companyUserSignup.enabled') && (
							<>
								<div className="or-style">or</div>
								<Button
									ghost
									block
									type="primary"
									size="large"
									onClick={this.handleCompanyUserAccount}
								>
									Create An Account
								</Button>
							</>
						)}
					{!get(company, 'disableClaimYourAccountLogin') && (
						<div className="claim-your-account">
							<Button type="link" onClick={this.props.handleClaimAccount}>
								Claim Your Account
							</Button>
						</div>
					)}
				</Form>

				<p className="by-continuing-links">
					*By continuing you agree to the{' '}
					<a
						href="https://erinapp.com/terms-of-use/"
						target="_blank"
						rel="noopener noreferrer"
					>
						User Agreement
					</a>
					{', '}
					<a
						href="https://erinapp.com/privacy-policy/"
						target="_blank"
						rel="noopener noreferrer"
					>
						Privacy Policy
					</a>
					, and{' '}
					<a
						href="https://erinapp.com/cookie-policy/"
						target="_blank"
						rel="noopener noreferrer"
					>
						Cookie Policy
					</a>{' '}
					for this application.
				</p>

				<ClaimAccountModalForm
					props={this.props}
					theme={theme}
					visible={this.props.visible}
					page={1}
					setPageOne={this.setPageOne}
					closeModal={this.props.closeModal}
					labelEmployeeID={labelEmployeeID}
					userSignupSettings={userSignupSettings}
				/>
				<ResetPasswordModal
					theme={theme}
					authUser={this.state.authUser}
					visible={this.state.resetModalVisible}
					closeModal={this.closeResetModal}
					updateNewPassword={this.updateNewPassword}
				/>
			</>
		);
	}
}

export default withApollo(withRouter(LoginCard));

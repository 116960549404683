import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
	Alert,
	AutoComplete,
	Button,
	Input,
	Popconfirm,
	Row,
	Select,
} from 'antd';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { configMode } from 'src/_shared/api/';
import { getATSSettingsByCompany } from 'src/_shared/api/graphql/custom/company/';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { graphql, lambda, ml } from 'src/_shared/services/utils.js';
import { colorRegex, urlRegex } from '../_shared/constants/regex';
import { requiredFields } from './errorHandlers.js';
import {
	InputStyleSettings,
	SelectContainer,
	cancelContainer,
	cancelModalButton,
	disableExternalJobs,
	disableExternalJobsNonUS,
	greenLabel,
	keepModalOpenButton,
	loadExternalJobs,
	loadExternalJobsNonUS,
	titleStyle,
} from './settingsStyles.js';

class SettingsFormAts extends Component {
	constructor(props) {
		super(props);

		this.state = {
			company: get(props, 'currentUser.company'),
			atsIntegration: null,
			hiringManager: '',
			loading: false,
			notificationType: 'NONE',
			enableGreenhouseSyncJobs: true,
			enableClearCompanySyncJobs: true,
			enableLeverSyncJobs: true,
			enableiCIMSSyncJobs: true,
			bonusBuilder: '',
			isiCIMSATSExists: false,
			isNoniCIMSATSExists: false,
			atsIntegrationNameText: '',
			count: 0,
			glbKey: '',
			enableUltiproSyncJobs: true,
			enableCrelateSyncJobs: true,
			enableReferVetsSyncJobs: true,
			enableSeaWorldSyncJobs: false,
			enableSilkroadSyncJobs: false,
			enableTalentReefSyncJobs: false,
		};
	}

	async componentDidMount() {
		await this.getATSSettings();
		const { atsIntegration } = await graphql(
			{
				input: { id: this.props?.currentUser?.company?.id },
				query: 'getATSSettingsByCompany',
			},
			'cognito'
		);

		if (atsIntegration && atsIntegration.length > 2) {
			const greenhouseExistsinATSSettings = atsIntegration.filter(
				(e) => e.ATSName === 'Greenhouse'
			);
			if (
				greenhouseExistsinATSSettings !== undefined &&
				greenhouseExistsinATSSettings.length > 0
			) {
				this.setState({
					enableGreenhouseSyncJobs: true,
				});
			} else {
				this.setState({
					enableGreenhouseSyncJobs: false,
				});
			}

			const clearCompanyExistsinATSSettings = atsIntegration.filter(
				(e) => e.ATSName === 'ClearCompany'
			);
			if (
				clearCompanyExistsinATSSettings !== undefined &&
				clearCompanyExistsinATSSettings.length > 0
			) {
				this.setState({
					enableClearCompanySyncJobs: true,
				});
			} else {
				this.setState({
					enableClearCompanySyncJobs: false,
				});
			}

			const leverExistsinATSSettings = atsIntegration.filter(
				(e) => e.ATSName === 'Lever'
			);
			if (
				leverExistsinATSSettings !== undefined &&
				leverExistsinATSSettings.length > 0
			) {
				this.setState({
					enableLeverSyncJobs: true,
				});
			} else {
				this.setState({
					enableLeverSyncJobs: false,
				});
			}

			const ultiproExistsinATSSettings = atsIntegration.filter(
				(e) => e.ATSName === 'Ultipro'
			);
			if (
				ultiproExistsinATSSettings !== undefined &&
				ultiproExistsinATSSettings.length > 0
			) {
				this.setState({
					enableUltiproSyncJobs: true,
				});
			} else {
				this.setState({
					enableUltiproSyncJobs: false,
				});
			}

			const iCIMSExistsinATSSettings = atsIntegration.filter(
				(e) => e.ATSName === 'iCIMS'
			);
			if (
				iCIMSExistsinATSSettings !== undefined &&
				iCIMSExistsinATSSettings.length > 0
			) {
				this.setState({
					enableiCIMSSyncJobs: true,
				});
			} else {
				this.setState({
					enableiCIMSSyncJobs: false,
				});
			}

			const crelateExistsinATSSettings = atsIntegration.filter(
				(e) => e.ATSName === 'Crelate'
			);
			if (
				crelateExistsinATSSettings !== undefined &&
				crelateExistsinATSSettings.length > 0
			) {
				this.setState({
					enableCrelateSyncJobs: true,
				});
			} else {
				this.setState({
					enableCrelateSyncJobs: false,
				});
			}

			const refervetsExistsinATSSettings = atsIntegration.filter(
				(e) => e.ATSName === 'ReferVets'
			);
			if (
				refervetsExistsinATSSettings !== undefined &&
				refervetsExistsinATSSettings.length > 0
			) {
				this.setState({
					enableReferVetsSyncJobs: true,
				});
			} else {
				this.setState({
					enableReferVetsSyncJobs: false,
				});
			}

			const seaworldExistsinATSSettings = atsIntegration.filter(
				(e) => e.ATSName === 'SeaWorld'
			);
			if (
				seaworldExistsinATSSettings !== undefined &&
				seaworldExistsinATSSettings.length > 0
			) {
				this.setState({
					enableSeaWorldSyncJobs: true,
				});
			} else {
				this.setState({
					enableSeaWorldSyncJobs: false,
				});
			}

			const silkroadExistsingATSSettings = atsIntegration.filter(
				(e) => e.ATSName === 'Silkroad'
			);
			if (
				silkroadExistsingATSSettings !== undefined &&
				silkroadExistsingATSSettings.length > 0
			) {
				this.setState({
					enableSilkroadSyncJobs: true,
				});
			} else {
				this.setState({
					enableSilkroadSyncJobs: false,
				});
			}

			const talentreefExistsingATSSettings = atsIntegration.filter(
				(e) => e.ATSName === 'TalentReef'
			);
			if (
				talentreefExistsingATSSettings !== undefined &&
				talentreefExistsingATSSettings.length > 0
			) {
				this.setState({
					enableTalentReefSyncJobs: true,
				});
			} else {
				this.setState({
					enableTalentReefSyncJobs: false,
				});
			}
		}
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.jobs !== this.props.jobs &&
			prevProps.jobs.length < this.props.jobs.length
		) {
			this.setState({
				jobs: this.props.jobs,
			});
		}
	}

	onChangeBonusTier = (value) => {
		this.setState({
			bonusBuilder: {
				tieredBonusId: value.key,
				name: value.label,
			},
		});
	};

	onChangeHiringManager = (value) => {
		this.setState({
			hiringManager: {
				id: value.key,
				name: value.label.join(''),
			},
		});
	};

	onChangeNotificationType = (notificationType) => {
		this.setState({
			notificationType,
		});
	};

	onChangeRTE = (value) => {
		this.setState({
			dashboardReferralPolicyText: value,
			description: value.toString('html'),
		});
		if (this.state.description === '<p><br></p>') {
			this.setState({ description: null });
		}
	};

	onIntegrationSelectChange = (value) => {
		this.setState({ atsIntegrationNameText: value });
	};

	async getATSSettings() {
		const { currentUser } = this.props;
		try {
			const { atsIntegration } = await graphql(
				{
					input: { id: currentUser?.companyId },
					query: 'getATSSettingsByCompany',
				},
				'cognito'
			);
			if (atsIntegration) this.setState({ atsIntegration });
		} catch (error) {
			console.log(error);
		}
	}

	handleAllowSelfReferralsChange = (value) => {
		this.setState({
			allowSelfReferrals: value.target.checked,
		});
	};

	handleAllowSelfReferralsInternalLinkChange = (value) => {
		this.setState({
			allowSelfReferralsInternalLink: value.target.checked,
		});
	};

	handleBonusEarnedNoteChange = (e) => {
		this.setState({
			bonusEarnedNote: e.target.value,
		});
	};

	handleCancel = () => {
		this.setState({ count: 0 });
	};

	handleClearCompanyExternalJobs = () => {
		this.setState({
			enableClearCompanySyncJobs: false,
		});

		this.syncClearCompanyJobs();
		this.props.syncClearCompanyJobsSuccess();
	};

	handleClick = (e) => {
		e.preventDefault();
		this.setState({
			enableSyncJobs: true,
		});
		const { company, onUpdateCompany } = this.props;
		const { atsIntegration } = this.state;
		const existingATSIntegration =
			atsIntegration && atsIntegration.length > 0 ? atsIntegration : [];
		const { currentUser } = this.props;
		let objectItem = {};
		this.props.form.validateFields((error, values) => {
			if (
				this.state.hiringManager != '' &&
				this.state.atsIntegrationNameText != 'select'
			) {
				if (this.state.atsIntegrationNameText === 'iCIMS') {
					const isiCIMSExists = existingATSIntegration.filter(
						(item) =>
							item.ATSName === this.state.atsIntegrationNameText &&
							item.ATSCustomerId === values.atsIntegrationCustomerID &&
							item.ATSPortalId === values.atsIntegrationPortalID
					);
					if (isiCIMSExists.length > 0) {
						this.setState({ isiCIMSATSExists: true });
						setTimeout(() => {
							this.setState({ isiCIMSATSExists: false });
						}, 4000);
						return;
					}

					objectItem = {
						ATSName: this.state.atsIntegrationNameText,
						ATSCustomerId: values.atsIntegrationCustomerID,
						ATSPortalId: values.atsIntegrationPortalID,
						ATSAPIUserName: values.atsIntegrationUserName,
						ATSAPIPassword: values.atsIntegrationPassword,
						ATSHiringManager: this.state.hiringManager,
						ATSBonusBuilder: this.state.bonusBuilder,
						ATSNotificationType: this.state.notificationType,
						ATSCreatedById: currentUser.id,
					};
				} else if (this.state.atsIntegrationNameText === 'TalentReef') {
					const isTalentReefExists = existingATSIntegration.filter(
						(item) =>
							item.ATSName === this.state.atsIntegrationNameText &&
							item.ATSAPIKey === values.atsIntegrationTalentReefAPIKeyOrUrl &&
							item.ATSPortalId === values.atsIntegrationTalentReefPortalId
					);
					if (isTalentReefExists.length > 0) {
						this.setState({ isNoniCIMSATSExists: true });
						setTimeout(() => {
							this.setState({ isNoniCIMSATSExists: false });
						}, 4000);
						return;
					}

					objectItem = {
						ATSName: this.state.atsIntegrationNameText,
						ATSHiringManager: this.state.hiringManager,
						ATSBonusBuilder: this.state.bonusBuilder,
						ATSNotificationType: this.state.notificationType,
						ATSCreatedById: currentUser.id,
					};
					objectItem.ATSAPIKey = values.atsIntegrationTalentReefAPIKeyOrUrl;
					objectItem.ATSPortalId = values.atsIntegrationTalentReefPortalId;
					this.setState({
						enableTalentReefSyncJobs: true,
					});
				} else {
					const isNoniCIMSExists = existingATSIntegration.filter(
						(item) =>
							item.ATSName === this.state.atsIntegrationNameText &&
							item.ATSAPIKey === values.atsIntegrationKeyorUrl
					);
					if (isNoniCIMSExists.length > 0) {
						this.setState({ isNoniCIMSATSExists: true });
						setTimeout(() => {
							this.setState({ isNoniCIMSATSExists: false });
						}, 4000);
						return;
					}

					objectItem = {
						ATSName: this.state.atsIntegrationNameText,
						ATSHiringManager: this.state.hiringManager,
						ATSBonusBuilder: this.state.bonusBuilder,
						ATSNotificationType: this.state.notificationType,
						ATSCreatedById: currentUser.id,
					};
					if (this.state.atsIntegrationNameText === 'Other') {
						objectItem.ATSAPIKey = values.atsIntegrationKeyorUrl;
					}

					if (this.state.atsIntegrationNameText === 'Greenhouse') {
						objectItem.ATSAPIKey = values.atsIntegrationKeyorUrl;
						objectItem.HarvestAPIkey = values.atsIntegrationHarvestKey;
						objectItem.sourceCode = values.atsIntegrationSourceCode;
						objectItem.sourceId = values.atsIntegrationSourceId;
						objectItem.apiUserId = values.atsIntegrationAPIUserId;
					}

					if (this.state.atsIntegrationNameText === 'ClearCompany') {
						objectItem.ATSAPIKey = values.atsIntegrationKeyorUrl;
						objectItem.userName = values.atsIntegrationClearCompanyUserName;
						objectItem.password = values.atsIntegrationClearCompanyPassword;
					}

					if (this.state.atsIntegrationNameText === 'Lever') {
						objectItem.ATSAPIKey = values.atsIntegrationKeyorUrl;
					}

					if (this.state.atsIntegrationNameText === 'Ultipro') {
						objectItem.ClientAccessKey =
							values.atsIntegrationUltiproClientAccessKey;
						objectItem.UserName = values.atsIntegrationUltiproUserName;
						objectItem.Password = values.atsIntegrationUltiproPassword;
						objectItem.UserAccessKey =
							values.atsIntegrationUltiproUserAccessKey;
						objectItem.SourceId = values.atsIntegrationUltiproSourceId;
						objectItem.JobReportPath =
							values.atsIntegrationUltiproJobReportPath;
						objectItem.CandidateReportPath =
							values.atsIntegrationUltiproCandidateReportPath;
					}

					if (this.state.atsIntegrationNameText === 'Crelate') {
						objectItem.ATSAPIKey = values.atsIntegrationCrelateKey;
						objectItem.CustomerName = values.atsIntegrationCrelateCustomerName;
					}

					if (this.state.atsIntegrationNameText === 'SeaWorld') {
						this.setState({
							enableSeaWorldSyncJobs: true,
						});
						objectItem.ClientId = values.atsIntegrationSeaWorldClientId;
						objectItem.ClientSecret = values.atsIntegrationSeaWorldClientSecret;
						objectItem.ClientScope = values.atsIntegrationSeaWorldClientScope;
					}

					if (this.state.atsIntegrationNameText === 'ReferVets') {
						objectItem.UserName = values.atsIntegrationReferVetsUserName;
						objectItem.Token = values.atsIntegrationReferVetsToken;
					}

					if (this.state.atsIntegrationNameText === 'Silkroad') {
						this.setState({
							enableSilkroadSyncJobs: true,
						});
					}
				}

				existingATSIntegration.push(objectItem);

				this.setState(
					{
						atsIntegration: JSON.stringify(existingATSIntegration),
					},
					() => {
						graphql(
							{
								input: {
									companyId: this.props?.currentUser?.company?.id,
									atsIntegration: JSON.stringify(existingATSIntegration),
								},
								update: 'updateATSIntegration',
							},
							'cognito'
						);
					}
				);
				this.props.handleIntegrationSuccess();

				this.props.form.resetFields();
			} else {
				this.props.handleIntegrationError();
			}
		});
	};

	handleColorChange = (e) => {
		const { value } = e.target;
		const color = new RegExp(colorRegex, 'i');
		if (value === '' || color.test(value)) {
			this.setState({ colorError: false });
		} else {
			this.setState({ colorError: true });
		}

		if (value === '') {
			this.setState({ brandColor: null });
		} else {
			this.setState({ brandColor: value });
		}
	};

	handleCrelateExternalJobs = () => {
		this.setState({
			enableCrelateSyncJobs: false,
		});
		this.syncCrelateJobs();
		this.props.syncCrelateJobsSuccess();
	};

	handleExternalJobs = () => {
		this.setState({
			enableGreenhouseSyncJobs: false,
		});
		this.syncGreenhouseJobs();
		this.props.syncGreenhouseJobsSuccess();
	};

	handleGreenhouseDepartments = () => {
		const { company, onCreateDepartment, departments, handleDuplicateError } =
			this.props;
		const { atsIntegration } = this.state;
		let count = 0;
		let apiBoardToken = '';
		let externalDepartments = '';
		const incomingAtsIntegration =
			atsIntegration != null && atsIntegration.length > 0 ? atsIntegration : [];
		if (
			incomingAtsIntegration.length > 0 &&
			incomingAtsIntegration[0].ATSName === 'Greenhouse'
		) {
			apiBoardToken = incomingAtsIntegration[0].ATSAPIKey;
		}

		fetch(
			'https://boards-api.greenhouse.io/v1/boards/' +
				apiBoardToken +
				'/departments'
		)
			.then((res) => res.json())
			.then((result) => {
				externalDepartments = result.departments;
				count = externalDepartments.length;
				if (externalDepartments.length > 0) {
					externalDepartments.map((element) => {
						const isDuplicateDept = departments.some((department) => {
							return department.name == element.name;
						});
						if (!isDuplicateDept) {
							onCreateDepartment({
								input: {
									companyId: company.id,
									name: element.name,
									active: true,
									externalDepartmentId: element.id,
								},
							});
							count--;
						} else if (isDuplicateDept) {
							handleDuplicateError();
						}
					});
				}
			})
			.then(() => {
				if (count === 0) this.props.importGreenhouseDepartmentsSuccess();
			});
	};

	handleLeverExternalJobs = () => {
		this.setState({
			enableLeverSyncJobs: false,
		});
		this.syncLeverJobs();
		this.props.syncLeverJobsSuccess();
	};

	handleOk = (ats) => {
		if (ats === ' ') {
			return;
		}

		const { onUpdateCompany, updateCurrentUserCompany } = this.props;
		const { atsIntegration, company } = this.state;
		if (atsIntegration) {
			this.setState({
				count: 0,
				glbKey: '',
			});
			let newIntegration = atsIntegration;

			newIntegration = newIntegration.filter(
				(element) =>
					element.ATSName +
						element.ATSAPIKey +
						element.ATSCustomerId +
						element.ATSPortalId !==
					ats.ATSName + ats.ATSAPIKey + ats.ATSCustomerId + ats.ATSPortalId
			);

			graphql(
				{
					input: {
						companyId: company?.id,
						atsIntegration: JSON.stringify(newIntegration),
					},
					update: 'updateATSIntegration',
				},
				'cognito'
			);
			const incomingAtsIntegration = newIntegration;
			this.setState({
				atsIntegration: JSON.stringify(newIntegration),
			});
		}
	};

	handleReferVetsExternalJobs = () => {
		this.setState({
			enableReferVetsSyncJobs: false,
		});
		this.syncReferVetsJobs();
		this.props.syncReferVetsJobsSuccess();
	};

	handleReferralBonusWaitingPeriodChange = (value) => {
		const values = ['0', '30', '60', '90', '120'];
		if (values.includes(value)) {
			values.splice(this.state.daysLookupData.indexOf(value), 1);
			values[0] = value;
		} else if (value.length > 0) {
			values[0] = value;
		} else if (value.length === 0) {
			values[0] = '0';
		}

		const children = values.map((days) => (
			<AutoComplete.Option key={days}>{days}</AutoComplete.Option>
		));

		this.setState({
			referralBonusWaitingPeriod: value,
			daysLookupChildren: children,
		});
	};

	handleSeaWorldExternalJobs = () => {
		this.setState({
			enableSeaWorldSyncJobs: false,
		});
		this.syncSeaWorldJobs();
		this.props.syncSeaWorldJobsSuccess();
	};

	handleSendAdminNotificationsOnReferralChange = (value) => {
		this.setState({
			sendAdminNotificationsOnReferral: value.target.checked,
		});
	};

	handleSilkroadExternalJobs = () => {
		this.setState({
			enableSilkroadSyncJobs: false,
		});
		this.syncSilkroadJobs();
		this.props.syncSilkroadJobsSuccess();
	};

	handleTalentReefExternalJobs = () => {
		this.setState({
			enableTalentReefSyncJobs: false,
		});
		this.syncTalentReefJobs();
		this.props.syncTalentReefJobsSuccess();
	};

	handleUltiproExternalJobs = () => {
		this.setState({
			enableUltiproSyncJobs: false,
		});
		this.syncUltiproJobs();
		this.props.syncUltiproJobsSuccess();
	};

	handleUrlChange = (e) => {
		const { value } = e.target;
		const url = new RegExp(urlRegex, 'i');
		if (value === '' || url.test(value)) {
			this.setState({ urlError: false });
		} else {
			this.setState({ urlError: true });
		}

		if (value === '') {
			this.setState({ websiteUrl: null });
		} else {
			this.setState({ websiteUrl: value });
		}
	};

	handleUrlError = () => {
		this.setState({ urlError: true });
	};

	handleiCIMSExternalJobs = () => {
		this.setState({
			enableiCIMSSyncJobs: false,
		});

		this.syncICIMSJobs();
		this.props.syncICIMSJobsSuccess();
	};

	pageIsValid = (currentSetting) => {
		let isValid = true;
		const errors = {};
		for (const { key, message } of requiredFields) {
			if (!currentSetting[key]) {
				errors[key] = message;
				isValid = false;
			}
		}

		this.setState({
			errors,
		});

		return isValid;
	};

	removeIntegration = (key) => {
		if (this.state.count === 0) {
			this.setState({ count: 1 });
		}

		if (this.state.glbKey === '') {
			this.setState({
				glbKey: key,
			});
		}
	};

	syncClearCompanyJobs() {
		const endpoint =
			configMode === 'DEV'
				? 'clearcompany-scheduler-api-trigger-dev'
				: 'clearcompany-scheduler-api-trigger-prod';
		lambda({
			endpoint,
			variables: { companyId: this.props.currentUser.companyId },
		});
	}

	syncCrelateJobs() {
		const endpoint =
			configMode === 'DEV'
				? 'crelate-scheduler-api-trigger-dev'
				: 'crelate-scheduler-api-trigger-prod';
		lambda({
			endpoint,
			variables: { companyId: this.props.currentUser.companyId },
		});
	}

	syncGreenhouseJobs() {
		const endpoint =
			configMode === 'DEV'
				? 'greenhouse-scheduler-api-trigger-dev'
				: 'greenhouse-scheduler-api-trigger-prod';
		lambda({
			endpoint,
			variables: { companyId: this.props.currentUser.companyId },
		});
	}

	syncICIMSJobs() {
		const endpoint =
			configMode === 'DEV'
				? 'icims-scheduler-api-trigger'
				: 'icims-scheduler-api-trigger-prod';
		lambda({
			endpoint,
			variables: { companyId: this.props.currentUser.companyId },
		});
	}

	syncLeverJobs() {
		const endpoint =
			configMode === 'DEV'
				? 'lever-scheduler-api-trigger-dev'
				: 'lever-scheduler-api-trigger-prod';
		lambda({
			endpoint,
			variables: { companyId: this.props.currentUser.companyId },
		});
	}

	syncReferVetsJobs() {
		const endpoint = configMode === 'DEV' ? '' : '';
		lambda({
			endpoint,
			variables: { companyId: this.props.currentUser.companyId },
		});
	}

	syncSeaWorldJobs() {
		const endpoint =
			configMode === 'DEV'
				? 'seaworld-scheduler-api-trigger-dev'
				: 'seaworld-scheduler-api-trigger-prod';
		lambda({
			endpoint,
			variables: { companyId: this.props.currentUser.companyId },
		});
	}

	syncSilkroadJobs() {
		const endpoint =
			configMode === 'DEV' ? '' : 'silkroad-scheduler-api-trigger-prod';
		lambda({
			endpoint,
			variables: { companyId: this.props.currentUser.companyId },
		});
	}

	syncTalentReefJobs() {
		const endpoint =
			configMode === 'DEV' ? '' : 'talentreef-scheduler-api-trigger-prod';
		lambda({
			endpoint,
			variables: { companyId: this.props.currentUser.companyId },
		});
	}

	syncUltiproJobs() {
		const endpoint =
			configMode === 'DEV'
				? 'ultipro-scheduler-api-trigger-dev'
				: 'ultipro-scheduler-api-trigger-prod';
		lambda({
			endpoint,
			variables: { companyId: this.props.currentUser.companyId },
		});
	}

	uploadFile = (files) => {
		const { file } = files;
		this.setState({ file });
	};

	renderATSWithBonusAmount = (ats) => {
		return (
			<>
				{get(ats, 'ATSName', '') +
					' : ' +
					get(ats, 'ATSAPIKey', '') +
					' : ' +
					get(ats, 'ATSHiringManager.name', '') +
					' : ' +
					get(ats, 'ATSReferralAmount', '') +
					' : ' +
					get(ats, 'ATSNotificationType', '')}
			</>
		);
	};

	renderBonusBuilder = () => {
		const { Option } = Select;
		const { allMultiLingualData, currentUser, tieredBonuses } = this.props;

		let options;
		if (tieredBonuses) {
			options = tieredBonuses.map((bonus) => (
				<Option key={bonus.id}>{bonus.name}</Option>
			));
		}

		return (
			<div className="custom-form-group">
				<label className="custom-label">
					{ml('Referral Bonus', currentUser, allMultiLingualData)}
				</label>
				<Select
					showSearch
					labelInValue
					className="custom-input"
					optionFilterProp="children"
					placeholder="Choose a Bonus Build"
					onSelect={(value) => this.onChangeBonusTier(value)}
				>
					{options}
				</Select>
			</div>
		);
	};

	renderClearCompany = () => {
		const { getFieldDecorator } = this.props.form;
		const FormItem = Form.Item;
		const { allMultiLingualData, currentUser } = this.props;
		return (
			<>
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('API Key or URL', currentUser, allMultiLingualData)}
					</label>
					<FormItem>
						{getFieldDecorator('atsIntegrationKeyorUrl')(
							<Input
								className="custom-input"
								placeholder={ml(
									'Enter API key value or URL',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}
					</FormItem>
				</div>
				<div className="custom-form-group">
					<label className="custom-label">User Name</label>
					<FormItem>
						{getFieldDecorator('atsIntegrationClearCompanyUserName')(
							<Input className="custom-input" placeholder="Enter User Name" />
						)}
					</FormItem>
				</div>
				<div className="custom-form-group">
					<label className="custom-label">Password</label>
					<FormItem>
						{getFieldDecorator('atsIntegrationClearCompanyPassword')(
							<Input.Password
								className="custom-input"
								placeholder="Enter Password"
							/>
						)}
					</FormItem>
				</div>
			</>
		);
	};

	renderClearCompanyATS = (ats) => {
		return (
			<>
				{get(ats, 'ATSName') +
					' : ' +
					get(ats, 'ATSAPIKey') +
					' : ' +
					get(ats, 'ATSHiringManager.name') +
					' : ' +
					get(ats, 'ATSBonusBuilder.name') +
					' : ' +
					get(ats, 'ATSNotificationType')}
			</>
		);
	};

	renderCrelate = () => {
		const { getFieldDecorator } = this.props.form;
		const FormItem = Form.Item;
		const { allMultiLingualData, currentUser } = this.props;
		return (
			<>
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('API Key', currentUser, allMultiLingualData)}
					</label>
					<FormItem>
						{getFieldDecorator('atsIntegrationCrelateKey')(
							<Input
								className="custom-input"
								placeholder={ml(
									'Enter API key value',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}
					</FormItem>
				</div>
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('Customer Name', currentUser, allMultiLingualData)}
					</label>
					<FormItem>
						{getFieldDecorator('atsIntegrationCrelateCustomerName')(
							<Input
								className="custom-input"
								placeholder={ml(
									'Enter Customer Name',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}
					</FormItem>
				</div>
			</>
		);
	};

	renderCrelateATS = (ats) => {
		return (
			<>
				{get(ats, 'ATSName') +
					' : ' +
					get(ats, 'ATSAPIKey') +
					' : ' +
					get(ats, 'ATSHiringManager.name') +
					' : ' +
					get(ats, 'ATSBonusBuilder.name') +
					' : ' +
					get(ats, 'ATSNotificationType')}
			</>
		);
	};

	renderGreenhouse = () => {
		const { getFieldDecorator } = this.props.form;
		const FormItem = Form.Item;
		const { allMultiLingualData, currentUser } = this.props;
		return (
			<>
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('API Key or URL', currentUser, allMultiLingualData)}
					</label>
					<FormItem>
						{getFieldDecorator('atsIntegrationKeyorUrl')(
							<Input
								className="custom-input"
								placeholder={ml(
									'Enter API key value or URL',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}
					</FormItem>
				</div>
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('Harvest API Key', currentUser, allMultiLingualData)}
					</label>
					<FormItem>
						{getFieldDecorator('atsIntegrationHarvestKey')(
							<Input
								className="custom-input"
								placeholder={ml(
									'Enter Harvest API key',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}
					</FormItem>
				</div>
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('Source Code', currentUser, allMultiLingualData)}
					</label>
					<FormItem>
						{getFieldDecorator('atsIntegrationSourceCode')(
							<Input
								className="custom-input"
								placeholder={ml(
									'Enter Source Code',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}
					</FormItem>
				</div>
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('Source ID', currentUser, allMultiLingualData)}
					</label>
					<FormItem>
						{getFieldDecorator('atsIntegrationSourceId')(
							<Input
								className="custom-input"
								placeholder={ml(
									'Enter Source ID',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}
					</FormItem>
				</div>
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('API User ID', currentUser, allMultiLingualData)}
					</label>
					<FormItem>
						{getFieldDecorator('atsIntegrationAPIUserId')(
							<Input
								className="custom-input"
								placeholder={ml(
									'Enter API User ID',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}
					</FormItem>
				</div>
			</>
		);
	};

	renderGreenhouseATS = (ats) => {
		return (
			<>
				{get(ats, 'ATSName') +
					' : ' +
					get(ats, 'ATSAPIKey') +
					' : ' +
					get(ats, 'ATSHiringManager.name') +
					' : ' +
					get(ats, 'ATSBonusBuilder.name') +
					' : ' +
					get(ats, 'ATSNotificationType')}
			</>
		);
	};

	renderHiringManager = () => {
		const { allMultiLingualData, currentUser, managers } = this.props;
		const { hiringManager } = this.state;
		const { Option } = Select;
		const options = managers
			.filter((manager) => manager.role === 'admin')
			.sort(function (a, b) {
				const nameA = a.firstName.toUpperCase(); // ignore upper and lowercase
				const nameB = b.firstName.toUpperCase(); // ignore upper and lowercase
				if (nameA < nameB) {
					return -1;
				}

				if (nameA > nameB) {
					return 1;
				}

				// Names must be equal
				return 0;
			})
			.map((manager) => (
				<Option key={manager.id}>
					{manager.firstName} {manager.lastName}
				</Option>
			));
		return (
			<div className="custom-form-group">
				<label className="custom-label">
					{ml('Hiring Contact', currentUser, allMultiLingualData)}
				</label>
				{hiringManager ? (
					<Select
						showSearch
						labelInValue
						className="custom-input"
						optionFilterProp="children"
						value={{ key: hiringManager.id, label: hiringManager.name }}
						placeholder="Ex. James Smith"
						filterOption={(input, option) =>
							option.props.children
								.join('')
								.toLowerCase()
								.includes(input.toLowerCase())
						}
						onSelect={(value) => this.onChangeHiringManager(value)}
					>
						{options}
					</Select>
				) : (
					<Select
						showSearch
						labelInValue
						className="custom-input"
						optionFilterProp="children"
						placeholder="Ex. James Smith"
						filterOption={(input, option) =>
							option.props.children
								.join('')
								.toLowerCase()
								.includes(input.toLowerCase())
						}
						onChange={(value) => this.onChangeHiringManager(value)}
					>
						{options}
					</Select>
				)}
			</div>
		);
	};

	renderLever = () => {
		const { getFieldDecorator } = this.props.form;
		const FormItem = Form.Item;
		const { allMultiLingualData, currentUser } = this.props;
		return (
			<div className="custom-form-group">
				<label className="custom-label">
					{ml('API Key or URL', currentUser, allMultiLingualData)}
				</label>
				<FormItem>
					{getFieldDecorator('atsIntegrationKeyorUrl')(
						<Input
							className="custom-input"
							placeholder={ml(
								'Enter API key value or URL',
								currentUser,
								allMultiLingualData
							)}
						/>
					)}
				</FormItem>
			</div>
		);
	};

	renderLeverATS = (ats) => {
		return (
			<>
				{get(ats, 'ATSName') +
					' : ' +
					get(ats, 'ATSAPIKey') +
					' : ' +
					get(ats, 'ATSHiringManager.name') +
					' : ' +
					get(ats, 'ATSBonusBuilder.name') +
					' : ' +
					get(ats, 'ATSNotificationType')}
			</>
		);
	};

	renderNotification = () => {
		const { notificationType } = this.state;
		const { allMultiLingualData, currentUser } = this.props;
		const notificationTypes = {
			ALL: ml('Notify All Employees', currentUser, allMultiLingualData),
			DEPARTMENT: ml(
				'Notify Department Only',
				currentUser,
				allMultiLingualData
			),
			NONE: ml('No Notifications', currentUser, allMultiLingualData),
		};
		return (
			<>
				<h4 className="setting-card-title">
					{ml('Notification', currentUser, allMultiLingualData)}
				</h4>
				<div className="custom-btn-group">
					{Object.keys(notificationTypes).map((key) => {
						return (
							<Button
								key={key}
								className={key === notificationType ? 'active' : ''}
								onClick={this.onChangeNotificationType.bind(this, key)}
							>
								{notificationTypes[key]}
							</Button>
						);
					})}
				</div>
			</>
		);
	};

	renderOther = () => {
		const { getFieldDecorator } = this.props.form;
		const FormItem = Form.Item;
		const { allMultiLingualData, currentUser } = this.props;
		return (
			<div className="custom-form-group">
				<label className="custom-input">
					{ml('API Key or URL', currentUser, allMultiLingualData)}
				</label>
				<FormItem>
					{getFieldDecorator('atsIntegrationKeyorUrl')(
						<Input
							className="custom-input"
							placeholder={ml(
								'Enter API key value or URL',
								currentUser,
								allMultiLingualData
							)}
						/>
					)}
				</FormItem>
			</div>
		);
	};

	renderOtherATS = (ats) => {
		return (
			<>
				{get(ats, 'ATSName') +
					' : ' +
					get(ats, 'ATSAPIKey') +
					' : ' +
					get(ats, 'ATSHiringManager.name') +
					' : ' +
					get(ats, 'ATSBonusBuilder.name') +
					' : ' +
					get(ats, 'ATSNotificationType')}
			</>
		);
	};

	renderReferVets = () => {
		const { getFieldDecorator } = this.props.form;
		const FormItem = Form.Item;
		const { allMultiLingualData, currentUser } = this.props;
		return (
			<>
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('User Name', currentUser, allMultiLingualData)}
					</label>
					<FormItem>
						{getFieldDecorator('atsIntegrationReferVetsUserName')(
							<Input
								className="custom-input"
								placeholder={ml(
									'Enter User Name',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}
					</FormItem>
				</div>
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('Token', currentUser, allMultiLingualData)}
					</label>
					<FormItem>
						{getFieldDecorator('atsIntegrationReferVetsToken')(
							<Input
								className="custom-input"
								placeholder={ml(
									'Enter Token',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}
					</FormItem>
				</div>
			</>
		);
	};

	renderReferVetsATS = (ats) => {
		return (
			<>
				{get(ats, 'ATSName') +
					' : ' +
					get(ats, 'ATSHiringManager.name') +
					' : ' +
					get(ats, 'ATSBonusBuilder.name') +
					' : ' +
					get(ats, 'ATSNotificationType')}
			</>
		);
	};

	renderSeaWorld = () => {
		const { getFieldDecorator } = this.props.form;
		const FormItem = Form.Item;
		const { allMultiLingualData, currentUser } = this.props;
		return (
			<>
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('Client ID', currentUser, allMultiLingualData)}
					</label>
					<FormItem>
						{getFieldDecorator('atsIntegrationSeaWorldClientId')(
							<Input
								className="custom-input"
								placeholder={ml(
									'Enter Client ID',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}
					</FormItem>
				</div>
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('Client Secret', currentUser, allMultiLingualData)}
					</label>
					<FormItem>
						{getFieldDecorator('atsIntegrationSeaWorldClientSecret')(
							<Input
								className="custom-input"
								placeholder={ml(
									'Enter Client Secret',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}
					</FormItem>
				</div>
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('Client Scope', currentUser, allMultiLingualData)}
					</label>
					<FormItem>
						{getFieldDecorator('atsIntegrationSeaWorldClientScope')(
							<Input
								className="custom-input"
								placeholder={ml(
									'Enter Client Scope',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}
					</FormItem>
				</div>
			</>
		);
	};

	renderSeaWorldATS = (ats) => {
		return (
			<>
				{get(ats, 'ATSName') +
					' : ' +
					get(ats, 'ATSHiringManager.name') +
					' : ' +
					get(ats, 'ATSBonusBuilder.name') +
					' : ' +
					get(ats, 'ATSNotificationType')}
			</>
		);
	};

	renderSilkroadATS = (ats) => {
		return (
			<>
				{get(ats, 'ATSName') +
					' : ' +
					get(ats, 'ATSHiringManager.name') +
					' : ' +
					get(ats, 'ATSBonusBuilder.name') +
					' : ' +
					get(ats, 'ATSNotificationType')}
			</>
		);
	};

	renderSmartRecruiters = () => {
		const { getFieldDecorator } = this.props.form;
		const FormItem = Form.Item;
		const { allMultiLingualData, currentUser } = this.props;
		return (
			<div>
				<div className={SelectContainer}>
					<label className={greenLabel}>
						{ml('API Key or URL', currentUser, allMultiLingualData)}
					</label>
					<FormItem style={{ width: 300 }}>
						{getFieldDecorator('atsIntegrationKeyorUrl')(
							<Input
								className={InputStyleSettings}
								placeholder={ml(
									'Enter API key value or URL',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}
					</FormItem>
				</div>
			</div>
		);
	};

	renderTalentReef = () => {
		const { getFieldDecorator } = this.props.form;
		const FormItem = Form.Item;
		const { allMultiLingualData, currentUser } = this.props;
		return (
			<>
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('API Key or URL', currentUser, allMultiLingualData)}
					</label>
					<FormItem>
						{getFieldDecorator('atsIntegrationTalentReefAPIKeyOrUrl')(
							<Input
								className="custom-input"
								placeholder={ml(
									'Enter API Key or URL',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}
					</FormItem>
				</div>
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('Portal Code', currentUser, allMultiLingualData)}
					</label>
					<FormItem>
						{getFieldDecorator('atsIntegrationTalentReefPortalId')(
							<Input
								className="custom-input"
								placeholder={ml(
									'Enter Portal Code',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}
					</FormItem>
				</div>
			</>
		);
	};

	renderTalentReefATS = (ats) => {
		return (
			<div key={get(ats, 'ATSPortalId')}>
				{get(ats, 'ATSName') +
					' : ' +
					get(ats, 'ATSPortalId') +
					' : ' +
					get(ats, 'ATSHiringManager.name') +
					' : ' +
					get(ats, 'ATSBonusBuilder.name') +
					' : ' +
					get(ats, 'ATSNotificationType')}
			</div>
		);
	};

	renderTaleoBusiness = () => {
		const { getFieldDecorator } = this.props.form;
		const FormItem = Form.Item;
		const { allMultiLingualData, currentUser } = this.props;
		return (
			<div>
				<div className={SelectContainer}>
					<label className={greenLabel}>
						{ml('API Key or URL', currentUser, allMultiLingualData)}
					</label>
					<FormItem style={{ width: 300 }}>
						{getFieldDecorator('atsIntegrationKeyorUrl')(
							<Input
								className={InputStyleSettings}
								placeholder="Enter API key value or URL"
							/>
						)}
					</FormItem>
				</div>
			</div>
		);
	};

	renderTaleoEnterprise = () => {
		const { getFieldDecorator } = this.props.form;
		const FormItem = Form.Item;
		const { allMultiLingualData, currentUser } = this.props;
		return (
			<div>
				<div className={SelectContainer}>
					<label className={greenLabel}>
						{ml('API Key or URL', currentUser, allMultiLingualData)}
					</label>
					<FormItem style={{ width: 300 }}>
						{getFieldDecorator('atsIntegrationKeyorUrl')(
							<Input
								className={InputStyleSettings}
								placeholder={ml(
									'Enter API key value or URL',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}
					</FormItem>
				</div>
			</div>
		);
	};

	renderUltipro = () => {
		const { getFieldDecorator } = this.props.form;
		const FormItem = Form.Item;
		const { allMultiLingualData, currentUser } = this.props;
		return (
			<>
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('Client Access Key', currentUser, allMultiLingualData)}
					</label>
					<FormItem>
						{getFieldDecorator('atsIntegrationUltiproClientAccessKey')(
							<Input
								className="custom-input"
								placeholder={ml(
									'Enter Client Access Key',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}
					</FormItem>
				</div>
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('User Name', currentUser, allMultiLingualData)}
					</label>
					<FormItem>
						{getFieldDecorator('atsIntegrationUltiproUserName')(
							<Input
								className="custom-input"
								placeholder={ml(
									'Enter User Name',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}
					</FormItem>
				</div>
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('Password', currentUser, allMultiLingualData)}
					</label>
					<FormItem>
						{getFieldDecorator('atsIntegrationUltiproPassword')(
							<Input.Password
								className="custom-input"
								placeholder={ml(
									'Enter Password',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}
					</FormItem>
				</div>

				<div className="custom-form-group">
					<label className="custom-label">
						{ml('User Access Key', currentUser, allMultiLingualData)}
					</label>
					<FormItem>
						{getFieldDecorator('atsIntegrationUltiproUserAccessKey')(
							<Input
								className="custom-input"
								placeholder={ml(
									'Enter User Access Key',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}
					</FormItem>
				</div>
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('Source ID', currentUser, allMultiLingualData)}
					</label>
					<FormItem>
						{getFieldDecorator('atsIntegrationUltiproSourceId')(
							<Input
								className="custom-input"
								placeholder={ml(
									'Enter Source ID',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}
					</FormItem>
				</div>
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('Job Report Path', currentUser, allMultiLingualData)}
					</label>
					<FormItem>
						{getFieldDecorator('atsIntegrationUltiproJobReportPath', {
							initialValue: null, // replace 'yourDefaultValue' with the actual default value
						})(
							<Input
								className="custom-input"
								placeholder={ml(
									'Enter Job Report Path',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}
					</FormItem>
				</div>
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('Candidate Report Path', currentUser, allMultiLingualData)}
					</label>
					<FormItem>
						{getFieldDecorator('atsIntegrationUltiproCandidateReportPath')(
							<Input
								className="custom-input"
								placeholder={ml(
									'Enter Candidate Report Path',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}
					</FormItem>
				</div>
			</>
		);
	};

	renderUltiproATS = (ats) => {
		return (
			<>
				{get(ats, 'ATSName') +
					' : ' +
					get(ats, 'ClientAccessKey') +
					' : ' +
					get(ats, 'ATSHiringManager.name') +
					' : ' +
					get(ats, 'ATSBonusBuilder.name') +
					' : ' +
					get(ats, 'ATSNotificationType')}
			</>
		);
	};

	renderWorkday = () => {
		const { getFieldDecorator } = this.props.form;
		const FormItem = Form.Item;
		const { allMultiLingualData, currentUser } = this.props;
		return (
			<div>
				<div className={SelectContainer}>
					<label className={greenLabel}>
						{ml('API Key or URL', currentUser, allMultiLingualData)}
					</label>
					<FormItem style={{ width: 300 }}>
						{getFieldDecorator('atsIntegrationKeyorUrl')(
							<Input
								className={InputStyleSettings}
								placeholder={ml(
									'Enter API key value or URL',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}
					</FormItem>
				</div>
			</div>
		);
	};

	renderiCIMS = () => {
		const { getFieldDecorator } = this.props.form;
		const { allMultiLingualData, currentUser } = this.props;
		const FormItem = Form.Item;
		return (
			<>
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('Customer ID', currentUser, allMultiLingualData)}
					</label>
					<FormItem>
						{getFieldDecorator('atsIntegrationCustomerID')(
							<Input
								className="custom-input"
								placeholder={ml(
									'Enter Customer ID',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}
					</FormItem>
				</div>
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('Portal ID', currentUser, allMultiLingualData)}
					</label>
					<FormItem>
						{getFieldDecorator('atsIntegrationPortalID')(
							<Input
								className="custom-input"
								placeholder={ml(
									'Enter Portal ID',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}
					</FormItem>
				</div>
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('User Name', currentUser, allMultiLingualData)}
					</label>
					<FormItem>
						{getFieldDecorator('atsIntegrationUserName')(
							<Input
								className="custom-input"
								placeholder={ml(
									'Enter User Name',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}
					</FormItem>
				</div>
				<div className="custom-form-group">
					<label className="custom-label">
						{ml('Password', currentUser, allMultiLingualData)}
					</label>
					<FormItem>
						{getFieldDecorator('atsIntegrationPassword')(
							<Input.Password
								className="custom-input"
								placeholder={ml(
									'Enter Password',
									currentUser,
									allMultiLingualData
								)}
							/>
						)}
					</FormItem>
				</div>
			</>
		);
	};

	renderiCIMSATS = (ats) => {
		return (
			<>
				{'ATS Name' +
					' : ' +
					get(ats, 'ATSName', '') +
					', ' +
					'Customer ID ' +
					' : ' +
					get(ats, 'ATSCustomerId', '') +
					', ' +
					'Portal ID ' +
					' : ' +
					get(ats, 'ATSPortalId', '') +
					', ' +
					'Hiring Manager' +
					' : ' +
					get(ats, 'ATSHiringManager.name', '') +
					', ' +
					'Referral Bonus' +
					' : ' +
					get(ats, 'ATSBonusBuilder.name', '') +
					', ' +
					'Notification Type' +
					' : ' +
					get(ats, 'ATSNotificationType', '')}
			</>
		);
	};

	render() {
		const { company, atsIntegration } = this.state;
		const { languageCode } = this.props;
		const button_class_greenhouse = this.state.enableGreenhouseSyncJobs
			? languageCode === 'US'
				? loadExternalJobs
				: loadExternalJobsNonUS
			: languageCode === 'US'
				? disableExternalJobs
				: disableExternalJobsNonUS;
		const button_class_clearcompany = this.state.enableClearCompanySyncJobs
			? languageCode === 'US'
				? loadExternalJobs
				: loadExternalJobsNonUS
			: languageCode === 'US'
				? disableExternalJobs
				: disableExternalJobsNonUS;
		const button_class_lever = this.state.enableLeverSyncJobs
			? languageCode === 'US'
				? loadExternalJobs
				: loadExternalJobsNonUS
			: languageCode === 'US'
				? disableExternalJobs
				: disableExternalJobsNonUS;
		const button_class_icims = this.state.enableiCIMSSyncJobs
			? languageCode === 'US'
				? loadExternalJobs
				: loadExternalJobsNonUS
			: languageCode === 'US'
				? disableExternalJobs
				: disableExternalJobsNonUS;
		const button_class_ultipro = this.state.enableUltiproSyncJobs
			? languageCode === 'US'
				? loadExternalJobs
				: loadExternalJobsNonUS
			: languageCode === 'US'
				? disableExternalJobs
				: disableExternalJobsNonUS;
		const button_class_crelate = this.state.enableCrelateSyncJobs
			? languageCode === 'US'
				? loadExternalJobs
				: loadExternalJobsNonUS
			: languageCode === 'US'
				? disableExternalJobs
				: disableExternalJobsNonUS;
		const button_class_seaworld = this.state.enableSeaWorldSyncJobs
			? loadExternalJobs
			: disableExternalJobs;
		const button_class_refervets = this.state.enableReferVetsSyncJobs
			? loadExternalJobs
			: disableExternalJobs;
		const button_class_silkroad = this.state.enableSilkroadSyncJobs
			? loadExternalJobs
			: disableExternalJobs;
		const button_class_talentreef = this.state.enableTalentReefSyncJobs
			? loadExternalJobs
			: disableExternalJobs;
		const { getFieldDecorator } = this.props.form;
		const FormItem = Form.Item;

		const {
			allMultiLingualData,
			currentUser,
			integrationSuccess,
			syncGreenhouseJobs,
			importGreenhouseDepartments,
			integrationError,
			syncClearCompanyJobs,
			integrationATSSettingError,
			syncLeverJobs,
			syncICIMSJobs,
		} = this.props;
		const incomingAtsIntegration =
			atsIntegration !== null &&
			atsIntegration !== undefined &&
			atsIntegration.length > 2
				? atsIntegration
				: [];

		return this.state.loading ? (
			<Spinner company={company} />
		) : (
			<Form>
				<div className="setting-card-inner">
					<h4 className="setting-card-title">
						{ml('ATS Integration', currentUser, allMultiLingualData)}
					</h4>
					<div className="custom-form-group">
						<label className="custom-label">
							{ml('ATS Name', currentUser, allMultiLingualData)}
						</label>
						<FormItem>
							{getFieldDecorator('atsIntegrationName', {
								initialValue: 'Select',
							})(
								<Select
									className="custom-input"
									onChange={this.onIntegrationSelectChange}
								>
									<Select.Option key={0} value="Other">
										Other
									</Select.Option>
									<Select.Option key={1} value="Greenhouse">
										Greenhouse
									</Select.Option>
									<Select.Option key={2} value="ClearCompany">
										ClearCompany
									</Select.Option>
									<Select.Option key={3} value="iCIMS">
										iCIMS
									</Select.Option>
									<Select.Option key={4} value="Lever">
										Lever
									</Select.Option>
									<Select.Option key={5} value="Ultipro">
										Ultipro
									</Select.Option>
									<Select.Option key={6} value="Crelate">
										Crelate
									</Select.Option>
									<Select.Option key={7} value="Silkroad">
										Silkroad
									</Select.Option>
									<Select.Option key={8} value="SeaWorld">
										SeaWorld
									</Select.Option>
									<Select.Option key={9} value="ReferVets">
										ReferVets
									</Select.Option>
									<Select.Option key={10} value="TalentReef">
										TalentReef
									</Select.Option>
								</Select>
							)}
						</FormItem>
					</div>
					{this.state.atsIntegrationNameText === 'Other'
						? this.renderOther()
						: null}
					{this.state.atsIntegrationNameText === 'iCIMS'
						? this.renderiCIMS()
						: null}
					{this.state.atsIntegrationNameText === 'Greenhouse'
						? this.renderGreenhouse()
						: null}
					{this.state.atsIntegrationNameText === 'ClearCompany'
						? this.renderClearCompany()
						: null}
					{this.state.atsIntegrationNameText === 'Lever'
						? this.renderLever()
						: null}
					{this.state.atsIntegrationNameText === 'Ultipro'
						? this.renderUltipro()
						: null}
					{this.state.atsIntegrationNameText === 'Crelate'
						? this.renderCrelate()
						: null}
					{this.state.atsIntegrationNameText === 'SeaWorld'
						? this.renderSeaWorld()
						: null}
					{this.state.atsIntegrationNameText === 'ReferVets'
						? this.renderReferVets()
						: null}
					{this.state.atsIntegrationNameText === 'TalentReef'
						? this.renderTalentReef()
						: null}
					{this.renderHiringManager()}
					{this.renderBonusBuilder()}
					{this.renderNotification()}
					<Button
						type="primary"
						size="large"
						className="btn-min-width"
						onClick={this.handleClick}
					>
						{ml('Save', currentUser, allMultiLingualData)}
					</Button>
					{integrationSuccess ? (
						<Alert
							message="Your updates were successfully submitted."
							type="success"
						/>
					) : null}
					{this.state.isiCIMSATSExists ? (
						<Alert
							closable
							message="ATS Name, Customer ID, Portal ID should be unique."
							type="error"
						/>
					) : null}
					{this.state.isNoniCIMSATSExists ? (
						<Alert
							closable
							message="ATS Name, API Key or URL, Portal Code should be unique."
							type="error"
						/>
					) : null}
					{integrationError ? (
						<Alert message="All fields are mandatory." type="error" />
					) : null}
				</div>
				<ul className="tag-wrap mb-3 mt-3">
					{incomingAtsIntegration != null && incomingAtsIntegration.length > 0
						? incomingAtsIntegration.map((ats) => {
								const key =
									get(ats, 'ATSName') +
									get(ats, 'ATSAPIKey') +
									get(ats, 'ATSCustomerId') +
									get(ats, 'ATSPortalId');
								return (
									<li
										key={key}
										className="tag green"
										onClick={() => this.removeIntegration(key)}
									>
										{this.state.count > 0 && this.state.glbKey === key ? (
											<div className={cancelContainer}>
												<span className={titleStyle}>
													Are you sure you want to remove this settings?
												</span>
												<Row
													style={{ justifyContent: 'center', marginTop: 15 }}
												>
													<Button
														className={cancelModalButton}
														onClick={this.handleCancel}
													>
														No
													</Button>
													<Button
														className={keepModalOpenButton}
														onClick={() => this.handleOk(ats)}
													>
														Yes
													</Button>
												</Row>
											</div>
										) : null}
										<span className="tag-name">
											{ats.ATSName === 'Other'
												? this.renderOtherATS(ats)
												: null}
											{ats.ATSName === 'iCIMS'
												? this.renderiCIMSATS(ats)
												: null}
											{get(ats, 'ATSReferralAmount')
												? this.renderATSWithBonusAmount(ats)
												: null}
											{ats.ATSName === 'Greenhouse'
												? this.renderGreenhouseATS(ats)
												: null}
											{ats.ATSName === 'ClearCompany'
												? this.renderClearCompanyATS(ats)
												: null}
											{ats.ATSName === 'Lever'
												? this.renderLeverATS(ats)
												: null}
											{ats.ATSName === 'Ultipro'
												? this.renderUltiproATS(ats)
												: null}
											{ats.ATSName === 'Crelate'
												? this.renderCrelateATS(ats)
												: null}
											{ats.ATSName === 'Silkroad'
												? this.renderSilkroadATS(ats)
												: null}
											{ats.ATSName === 'SeaWorld'
												? this.renderSeaWorldATS(ats)
												: null}
											{ats.ATSName === 'ReferVets'
												? this.renderReferVetsATS(ats)
												: null}
											{ats.ATSName === 'TalentReef'
												? this.renderTalentReefATS(ats)
												: null}
										</span>
										<i className="tag-close icon-cross" />
									</li>
								);
							})
						: null}
				</ul>
				{importGreenhouseDepartments ? (
					<Alert
						message="Department Sync started. Please Allow 5 minutes for sync to complete."
						type="success"
					/>
				) : null}
				<div className="sync-btn-grp">
					<Button
						disabled={!this.state.enableGreenhouseSyncJobs}
						className={
							atsIntegration != null &&
							atsIntegration.length > 2 &&
							atsIntegration.some((e) => e.ATSName === 'Greenhouse')
								? 'ant-btn-success'
								: 'd-none'
						}
						onClick={this.handleExternalJobs}
					>
						{/* Sync Greenhouse Jobs */}
						{ml('Sync', currentUser, allMultiLingualData)} Greenhouse{' '}
						{ml('Jobs', currentUser, allMultiLingualData)}
					</Button>
					<Button
						ref="btn"
						className={
							atsIntegration != null &&
							atsIntegration.length > 2 &&
							atsIntegration.some((e) => e.ATSName === 'ClearCompany')
								? 'ant-btn-success'
								: 'd-none'
						}
						disabled={!this.state.enableClearCompanySyncJobs}
						onClick={this.handleClearCompanyExternalJobs}
					>
						{/* Sync ClearCompany Jobs */}
						{ml('Sync', currentUser, allMultiLingualData)} ClearCompany{' '}
						{ml('Jobs', currentUser, allMultiLingualData)}
					</Button>
					<Button
						disabled={!this.state.enableLeverSyncJobs}
						className={
							atsIntegration != null &&
							atsIntegration.length > 2 &&
							atsIntegration.some((e) => e.ATSName === 'Lever')
								? 'ant-btn-success'
								: 'd-none'
						}
						onClick={this.handleLeverExternalJobs}
					>
						{/* Sync Lever Jobs */}
						{ml('Sync', currentUser, allMultiLingualData)} Lever{' '}
						{ml('Jobs', currentUser, allMultiLingualData)}
					</Button>
					<Button
						disabled={!this.state.enableUltiproSyncJobs}
						className={
							atsIntegration != null &&
							atsIntegration.length > 2 &&
							atsIntegration.some((e) => e.ATSName === 'Ultipro')
								? 'ant-btn-success'
								: 'd-none'
						}
						onClick={this.handleUltiproExternalJobs}
					>
						{/* Sync Lever Jobs */}
						{ml('Sync', currentUser, allMultiLingualData)} Ultipro{' '}
						{ml('Jobs', currentUser, allMultiLingualData)}
					</Button>
					<Button
						disabled={!this.state.enableCrelateSyncJobs}
						className={
							atsIntegration != null &&
							atsIntegration.length > 2 &&
							atsIntegration.some((e) => e.ATSName === 'Crelate')
								? 'ant-btn-success'
								: 'd-none'
						}
						onClick={this.handleCrelateExternalJobs}
					>
						{/* Sync Lever Jobs */}
						{ml('Sync', currentUser, allMultiLingualData)} Crelate{' '}
						{ml('Jobs', currentUser, allMultiLingualData)}
					</Button>
					<Button
						disabled={!this.state.enableReferVetsSyncJobs}
						className={
							atsIntegration != null &&
							atsIntegration.length > 2 &&
							atsIntegration.some((e) => e.ATSName === 'ReferVets')
								? 'ant-btn-success'
								: 'd-none'
						}
						onClick={this.handleReferVetsExternalJobs}
					>
						{/* Sync ReferVets Jobs */}
						{ml('Sync', currentUser, allMultiLingualData)} ReferVets{' '}
						{ml('Jobs', currentUser, allMultiLingualData)}
					</Button>
					<Button
						disabled={!this.state.enableSeaWorldSyncJobs}
						className={
							atsIntegration != null &&
							atsIntegration.length > 2 &&
							atsIntegration.some((e) => e.ATSName === 'SeaWorld')
								? 'ant-btn-success'
								: 'd-none'
						}
						onClick={this.handleSeaWorldExternalJobs}
					>
						{/* Sync SeaWorld Jobs */}
						{ml('Sync', currentUser, allMultiLingualData)} SeaWorld{' '}
						{ml('Jobs', currentUser, allMultiLingualData)}
					</Button>
					<Button
						disabled={!this.state.enableTalentReefSyncJobs}
						className={
							atsIntegration != null &&
							atsIntegration.length > 2 &&
							atsIntegration.some((e) => e.ATSName === 'TalentReef')
								? 'ant-btn-success'
								: 'd-none'
						}
						onClick={this.handleTalentReefExternalJobs}
					>
						{/* Sync TalentReef Jobs */}
						{ml('Sync', currentUser, allMultiLingualData)} TalentReef{' '}
						{ml('Jobs', currentUser, allMultiLingualData)}
					</Button>
					<Button
						disabled={!this.state.enableSilkroadSyncJobs}
						className={
							atsIntegration != null &&
							atsIntegration.length > 2 &&
							atsIntegration.some((e) => e.ATSName === 'Silkroad')
								? 'ant-btn-success'
								: 'd-none'
						}
						onClick={this.handleSilkroadExternalJobs}
					>
						{/* Sync Lever Jobs */}
						{ml('Sync', currentUser, allMultiLingualData)} Silkroad{' '}
						{ml('Jobs', currentUser, allMultiLingualData)}
					</Button>
					{atsIntegration != null &&
					atsIntegration.length > 2 &&
					atsIntegration.some((e) => e.ATSName === 'iCIMS') ? (
						<Popconfirm
							title={ml('Are you sure?', currentUser, allMultiLingualData)}
							placement="left"
							okText={ml('Yes', currentUser, allMultiLingualData)}
							cancelText={ml('No', currentUser, allMultiLingualData)}
							onConfirm={() => this.handleiCIMSExternalJobs()}
						>
							<Button
								className={
									atsIntegration != null &&
									atsIntegration.length > 2 &&
									atsIntegration.some((e) => e.ATSName === 'iCIMS')
										? 'ant-btn-success'
										: 'd-none'
								}
								disabled={!this.state.enableiCIMSSyncJobs}
							>
								{/* Sync iCIMS Jobs */}
								{ml('Sync', currentUser, allMultiLingualData)} iCIMS{' '}
								{ml('Jobs', currentUser, allMultiLingualData)}
							</Button>
						</Popconfirm>
					) : null}
				</div>
				{syncGreenhouseJobs ? (
					<Alert
						message="Job Sync started. Please Allow 15 minutes for sync to complete."
						type="success"
					/>
				) : null}
				{syncClearCompanyJobs ? (
					<Alert
						message="Job Sync started. Please Allow 15 minutes for sync to complete."
						type="success"
					/>
				) : null}
				{syncLeverJobs ? (
					<Alert
						message="Job Sync started. Please Allow 15 minutes for sync to complete."
						type="success"
					/>
				) : null}
				{syncICIMSJobs ? (
					<Alert
						message="Job Sync started. Please Allow 15 minutes for sync to complete."
						type="success"
					/>
				) : null}
				{integrationATSSettingError ? (
					<Alert message="Please check your ats settings." type="error" />
				) : null}
			</Form>
		);
	}
}

export default Form.create()(withApollo(SettingsFormAts));

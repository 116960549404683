import get from 'lodash/get';
import { mapReferralStatus } from 'src/_shared/services/utils.js';
import { COLORS } from 'src/_shared/styles/colors';
import { REFERRAL_STATUS } from '../../constants';

export function ProgressIndicator(props) {
	const { status, currentUser, referral } = props;
	const newStatus =
		typeof status === 'string'
			? status
			: get(status, 'referralData.status', 'referred');
	let color = COLORS.dashboardLightOrange;

	const referralSubtitles = Object.keys(REFERRAL_STATUS).map((key) => {
		return REFERRAL_STATUS[key];
	});
	const referralStatuses = Object.keys(REFERRAL_STATUS).map((key) => key);
	const progressBarOptions = [
		{
			title: referralSubtitles[0],
			value: referralStatuses[0],
			color: COLORS.yellow,
			previousStates: [0],
		},
		{
			title: referralSubtitles[1],
			value: referralStatuses[1],
			color: COLORS.yellow,
			previousStates: [0, 1],
		},
		{
			title: referralSubtitles[2],
			value: referralStatuses[2],
			color: COLORS.yellow,
			previousStates: [0, 1, 2],
		},
		{
			title: referralSubtitles[3],
			value: referralStatuses[3],
			color: COLORS.red,
			previousStates: [0, 1, 2, 3],
		},
		{
			title: referralSubtitles[4],
			value: referralStatuses[4],
			color: COLORS.red,
			previousStates: [0, 1, 2, 3],
		},
		{
			title: referralSubtitles[5],
			value: referralStatuses[5],
			color: COLORS.red,
			previousStates: [0, 1, 2, 3],
		},
		{
			title: referralSubtitles[6],
			value: referralStatuses[6],
			color: COLORS.red,
			previousStates: [0, 1, 2, 3],
		},
		{
			title: referralSubtitles[7],
			value: referralStatuses[7],
			color: COLORS.gray,
			previousStates: [0, 1, 2, 3],
		},
		{
			title: referralSubtitles[8],
			value: referralStatuses[8],
			color: COLORS.red,
			previousStates: [0, 1, 2, 3],
		},
		{
			title: referralSubtitles[9],
			value: referralStatuses[9],
			color: COLORS.red,
			previousStates: [0, 1, 2, 3],
		},
	];
	if (newStatus === progressBarOptions[3].value) {
		color = COLORS.green;
	}

	if (newStatus === progressBarOptions[4].value) {
		color = COLORS.red;
	}

	if (
		newStatus === progressBarOptions[5].value ||
		newStatus === 'declined' ||
		newStatus === 'ineligible' ||
		newStatus === 'notHired'
	) {
		color = COLORS.red;
	}

	if (newStatus === 'inactive' || newStatus === 'noresponse') {
		color = COLORS.gray;
	}

	if (newStatus === progressBarOptions[7].value) {
		color = COLORS.gray;
	}

	const hasCheck = (index) => {
		let checkStatus = status;
		if (
			status === 'noresponse' ||
			status === 'inactive' ||
			status === 'ineligible'
		) {
			checkStatus = 'notHired';
		}

		const indexForCurrentStatus = referralStatuses.indexOf(checkStatus);
		return get(
			progressBarOptions,
			`[${indexForCurrentStatus}].previousStates`,
			[]
		).includes(index);
	};

	const hasFillColor = (index) => {
		const indexForCurrentStatus =
			status === 'declined' ||
			status === 'noresponse' ||
			status == 'inactive' ||
			status === 'ineligible'
				? referralStatuses.indexOf('notHired')
				: referralStatuses.indexOf(status);
		return get(
			progressBarOptions,
			`[${indexForCurrentStatus}].previousStates`,
			[]
		).includes(index);
	};

	let companyReferralStatus = get(
		currentUser,
		'company.referralStatus',
		'Interviewing'
	);
	const customStatus = get(referral, 'customStatus');
	if (
		customStatus !== null &&
		customStatus !== undefined &&
		status === 'interviewing'
	) {
		companyReferralStatus = customStatus;
	}

	const isActive =
		status !== 'declined' && status !== 'noresponse' && status !== 'inactive';
	return (
		<>
			<ul className="my-progressbar-inner">
				{Array.apply(null, { length: 4 })
					.map(Function.call, Number)
					.map((i) => {
						const isNotInactive =
							(status !== 'declined' &&
								status !== 'noresponse' &&
								status !== 'inactive') ||
							(status === 'declined' && i === 0) ||
							(status === 'declined' && i === 3) ||
							(status === 'noresponse' && i === 0) ||
							(status === 'noresponse' && i === 3) ||
							(status === 'inactive' && i === 0) ||
							(status === 'inactive' && i === 3);
						const dotFillColor =
							(hasFillColor(i) || hasFillColor(i) == 0) && hasCheck(i)
								? color
								: COLORS.gray;
						const lineFillColor =
							(hasFillColor(i) || hasFillColor(i) == 0) &&
							hasCheck(i) &&
							hasCheck(i + 1)
								? color
								: COLORS.gray;
						return i === 3 ? (
							<li key={i}>
								{i !== 3 && (
									<div
										className={`progress-line ${
											hasFillColor(i) && hasCheck(i) && hasCheck(i + 1)
												? color
												: 'gray'
										}`}
										style={{
											borderColor: hasCheck(i) && hasCheck(i + 1) ? color : '',
										}}
									/>
								)}
								<div
									className={`progress-circle ${
										hasFillColor(i) ? color : 'gray'
									}`}
									style={{
										borderColor: hasFillColor(i) ? color : '',
										background: hasFillColor(i) ? color : '',
									}}
								>
									{hasCheck(i) && <i className="icon-check progress-icon" />}
								</div>
							</li>
						) : (
							<li key={i}>
								{i !== 3 && (
									<div
										className={`progress-line ${
											hasFillColor(i) && hasCheck(i) && hasCheck(i + 1)
												? color
												: 'gray'
										}`}
										style={{
											borderColor: hasCheck(i) && hasCheck(i + 1) ? color : '',
										}}
									/>
								)}
								{isNotInactive && (
									<div
										className={`progress-circle ${
											hasFillColor(i) ? color : 'gray'
										}`}
										style={{
											borderColor: hasFillColor(i) ? color : '',
											background: hasFillColor(i) ? color : '',
										}}
									>
										{hasCheck(i) && <i className="icon-check progress-icon" />}
									</div>
								)}
							</li>
						);
					})}
			</ul>

			<ul className="my-progressbar-text">
				<li>
					{mapReferralStatus(progressBarOptions[0].title, currentUser.company)}
				</li>
				<li>
					{isActive && (
						<>
							{mapReferralStatus(
								progressBarOptions[1].title,
								currentUser.company
							)}
						</>
					)}
				</li>
				<li>
					{isActive && (
						<>
							{companyReferralStatus !== null && companyReferralStatus !== ''
								? companyReferralStatus
								: 'Interviewing'}
						</>
					)}
				</li>
				<li>
					{[
						'declined',
						'ineligible',
						'noresponse',
						'inactive',
						'notHired',
						'transferred',
					].includes(status)
						? mapReferralStatus(status, currentUser.company)
						: mapReferralStatus('hired', currentUser.company)}
				</li>
			</ul>
		</>
	);
}

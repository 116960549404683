import { Modal } from 'antd';

import ManageAddressPresentationModal from '../../presentation/manageAddressPresentationModal';

import useManageAddress from '../../hooks/useManageAddress';

const ManageAddressContainer = ({ showModal, setShowModal, handleConfirm }) => {
	const handleCancel = () => {
		setShowModal && setShowModal(false);
	};

	const {
		manageAddressLoader,
		employeeAddress,
		addressLoader,
		manageAddressByEmpId,
	} = useManageAddress(handleCancel);

	const onSubmit = async (values) => {
		await manageAddressByEmpId(values);
		handleConfirm();
	};

	return (
		<Modal
			open={showModal}
			title={'Confirm Address Details'}
			closable={true}
			onCancel={handleCancel}
			footer={null}
			className="manage-nominated-reward-modal"
		>
			<ManageAddressPresentationModal
				onSubmit={onSubmit}
				addressLoader={addressLoader}
				employeeAddress={employeeAddress}
				manageAddressLoader={manageAddressLoader}
			/>
		</Modal>
	);
};

export default ManageAddressContainer;

import { List, Modal } from 'antd';
import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCreateReferralAddReferral } from 'src/_shared/api/components/contacts/';
import { withJobById } from 'src/_shared/api/components/jobs/';
import { withGetTieredBonus } from 'src/_shared/api/components/tiered-bonuses';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { ToggleOpenClosedNew } from 'src/_shared/components/toggleOpenClosedNew/';
import {
	compose,
	getSetErrorImageURL,
	ml,
	searchOpenSearchNetwork,
} from 'src/_shared/services/utils.js';
import fileIcon from '../../_shared/assets/erin_lightgray.png';
import { SearchComponent } from '../../_shared/components/search';
import ReferralModalWrapper from './ReferralModalWrapper.jsx';

const filterOpenKey = 'open';
const filterClosedKey = 'closed';

class AddReferralJobModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			company: get(props, 'currentUser.company'),
			autoCompleteResult: [],
			isSubmitting: false,
			newContact: true,
			error: false,
			errorImage: props.currentUser?.company?.errorImage?.key ?? false,
			errorImageURL: '',
			jobs: props.jobs,
			loaded: true,
			loading: true,
			searchQuery: '',
			theme: get(props, 'theme', {}),
			filterOpenStatus: filterOpenKey,
			text: 'Open Jobs',
			whiteLabel: props.currentUser?.company?.whiteLabel ?? false,
		};
	}

	async componentDidMount() {
		if (this.props.matchFound) {
			this.setState({ autoCompleteResult: [this.props.matchFound] });
		}

		if (this.props.contact) {
			this.setState({ autoCompleteResult: [this.props.contact] });
		}

		await this.setQueryToState(get(this.state, 'searchQuery', ''));
		const errorImageURL = await getSetErrorImageURL(
			this.state?.errorImage?.key
		);

		if (errorImageURL) {
			this.setState({ errorImageURL });
		}
	}

	setQueryToState = async (searchQuery = '') => {
		const {
			company,
			filteredDepartments = [],
			filteredDistance = 0,
			filterOpenStatus = 'open',
			lat,
			lng,
		} = this.state;

		clearTimeout(this.timer);
		this.setState({ searchQuery });
		this.timer = setTimeout(async () => {
			this.setState({ loading: true });
			const parameters = {
				query: searchQuery,
				size: 300,
				coordinates: [{ lat, lng }],
				role: get(this.props, 'currentUser.role'),
				currentUser: get(this.props, 'currentUser.id'),
				filters: {
					companies: [get(company, 'id')],
					departments: filteredDepartments,
					distance: filteredDistance,
					status: filterOpenStatus,
				},
			};
			const response = await searchOpenSearchNetwork(parameters, 'erin-jobs');
			if (get(response, 'query') === get(this.state, 'searchQuery')) {
				const searchedJobs = get(response, 'data', []);
				this.setState({ loading: false, searchedJobs });
			}
		}, 700);
	};

	handleError = () => {
		this.setState({ error: true });
	};

	render() {
		const {
			data,
			visible,
			handleCancel,
			selectJobCreateReferralText,
			searchJobsText,
			referralInformationText,
			createReferralForText,
			positiontext,
			selectEmployeeText,
			enterReferralInformationText,
			firstNameText,
			lastNameText,
			emailText,
			clickHereText,
			attachResumeText,
			noteText,
			referralAutomaticallyAcceptedText,
			submitReferralText,
			bonusText,
			client,
		} = this.props;
		const {
			company,
			isSubmitting,
			searchQuery,
			theme,
			filterOpenStatus,
			searchedJobs = [],
		} = this.state;

		if (this.state.nextToken !== null && !this.state.loaded) {
			return (
				<div style={{ height: 589 }}>
					<Spinner translateY="25%" company={company} />
				</div>
			);
		}

		if (this.state.nextToken === null && !this.state.loaded) {
			setTimeout(() => {
				this.setState({ loaded: true });
			}, 1000);
		}

		const sortedJobData = searchedJobs;

		return (
			<Modal
				centered
				open={visible}
				wrapClassName="ant-modal-lg"
				grid={{ column: 5 }}
				footer={null}
				title={`${selectJobCreateReferralText}`}
				onCancel={handleCancel}
			>
				{isSubmitting ? (
					<div />
				) : (
					<div className="select-job-filter">
						{}
						<ToggleOpenClosedNew
							currentUser={this.props.currentUser}
							allMultiLingualData={this.props.allMultiLingualData}
							filterOpenStatus={filterOpenStatus}
							openJobsKey={filterOpenKey}
							closedJobsKey={filterClosedKey}
							text={this.state.text}
							openJobs={ml(
								'Open Jobs',
								this.props.currentUser,
								this.props.allMultiLingualData
							)}
							closedJobs={ml(
								'Closed Jobs',
								this.props.currentUser,
								this.props.allMultiLingualData
							)}
							onClick={(filterOpenStatus) => {
								this.setState({ loading: true });
								this.setState(
									{
										filterOpenStatus,
										text:
											this.state.text === 'Open Jobs'
												? 'Closed Jobs'
												: 'Open Jobs',
									},
									async () => await this.setQueryToState(searchQuery)
								);
							}}
						/>
						<SearchComponent
							searchQuery={searchQuery}
							setQueryToState={this.setQueryToState}
							placeholder={searchJobsText}
						/>
					</div>
				)}
				{this.state.loaded && !this.state.loading ? (
					<>
						{sortedJobData && sortedJobData.length > 0 ? (
							<List
								className="select-job-card-wrap"
								grid={{ gutter: 20, xs: 1, sm: 2, md: 2, lg: 3, xl: 3, xxl: 3 }}
								pagination={{ pageSize: 12, showSizeChanger: false }}
								dataSource={sortedJobData}
								renderItem={(job) => {
									const status = get(job, 'status', 'open');
									return status === filterOpenStatus && get(job, 'id') ? (
										<List.Item>
											<ReferralModalWrapper
												toggleLoading={this.props.toggleLoading}
												history={this.props.history}
												client={client}
												currentUser={this.props.currentUser}
												handleCancel={handleCancel}
												updateReferrals={this.props.updateReferrals}
												theme={theme}
												data={data}
												jobData={job}
												job={job}
												jobId={get(job, 'id')}
												referrals={job.referrals}
												referralInformationText={referralInformationText}
												createReferralForText={createReferralForText}
												positiontext={positiontext}
												selectEmployeeText={selectEmployeeText}
												enterReferralInformationText={
													enterReferralInformationText
												}
												firstNameText={firstNameText}
												lastNameText={lastNameText}
												emailText={emailText}
												clickHereText={clickHereText}
												attachResumeText={attachResumeText}
												noteText={noteText}
												referralAutomaticallyAcceptedText={
													referralAutomaticallyAcceptedText
												}
												submitReferralText={submitReferralText}
												bonusText={bonusText}
												referralLogs={this.props.referralLogs}
												currentTieredBonus={this.props.currentTieredBonus}
												currentReferralCount={this.props.currentReferralCount}
												contacts={this.props.contacts}
												onCreateContact={this.props.ImportedCreateContact}
												onCreateReferral={this.props.onCreateReferral}
												onUpdateContact={this.props.onUpdateContact}
											/>
										</List.Item>
									) : (
										<div style={{ height: 589 }}>
											<Spinner translateY="25%" company={company} />
										</div>
									);
								}}
							/>
						) : (!sortedJobData || sortedJobData.length === 0) &&
						  !this.state.loading ? (
							<div className="no-content">
								{this.state.whiteLabel ? (
									<img
										src={this.state.errorImageURL}
										alt="error image"
										className="no-content-icon"
									/>
								) : (
									<img
										alt="erin-logo"
										className="no-content-icon"
										src={fileIcon}
									/>
								)}

								<p className="no-content-text">No jobs found </p>
							</div>
						) : (
							<div style={{ height: 589 }}>
								<Spinner translateY="25%" company={company} />
							</div>
						)}
					</>
				) : (
					<div style={{ height: 589 }}>
						<Spinner translateY="25%" company={company} />
					</div>
				)}
			</Modal>
		);
	}
}

const mapStateToProperties = (state, ownProperties) => {
	const { currentUser } = state.user;
	return {
		currentUser,
	};
};

export default compose(
	connect(mapStateToProperties),
	withCreateReferralAddReferral,
	withGetTieredBonus,
	withJobById,
	withApollo,
	withRouter
)(AddReferralJobModal);
